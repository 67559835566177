import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import EditOffIcon from "@mui/icons-material/EditOff";

import DataTable from "../../../../components/datatable/DataTable";
import {
  deleteAddress,
  getEnterpriseAddress,
  putAddress,
} from "../../../../api/admin/address";
import {
  decodeDataToken,
  getToken,
} from "../../../../helpers/token/refreshToken";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { modalStyle } from "./AddressStyle";
import { Formik } from "formik";
import {
  addressInitial,
  addressSchema,
} from "../../../../components/form/address/AddressValues";
import * as yup from "yup";
import { tokens } from "../../../../theme";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { getEmployeesFromEnterprise } from "../../../../api/employee/employee";
import {
  addAddress,
  deletedAddress,
  updatedAddress,
} from "../../../../features/address/addressSlice";

const AddressList = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState([]);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const dispatch = useDispatch();
  let flag = true;
  const addressState = useSelector((state) => state.address);
  //update
  useEffect(() => {
    const getEmployees = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getEmployeesFromEnterprise(token, enterprise_id);
        setEmployees(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);
  //todos las direcciones
  useEffect(() => {
    const getAddressEnterprise = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getEnterpriseAddress(token, enterprise_id);
        console.log(response.data);
        setAddress([]);
        if (flag) {
          flag = false;
          Object.values(response?.data).map((_address, index) => {
            _address.index = index + 1;
            dispatch(addAddress(_address));
            //setAddress((oldData) => [...oldData, _address]);
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAddressEnterprise();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [radio, setRadio] = useState("global");
  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);
  const checkoutSchema = yup.object().shape(addressSchema(yup));

  const [updateAddress, setUpdateAddress] = useState([]);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleFormSubmit = async (values) => {
    try {
      //console.log(updateAddress);
      setIsLoading(true);
      const token = getToken("etknre");
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const objAddress = {
        address_id: UID,
        global: false,
        global_enterprise: true,
        url: values.url,
        name: values.name.toUpperCase(),
        latitude: 0,
        longitude: 0,
        enterprises_id: enterprise_id,
        employees_id: null,
      };
      if (radio === "employee") {
        objAddress.global_enterprise = false;
        objAddress.employees_id = employee.id;
      }
      const response = await putAddress(token, objAddress);
      const data = response.data;
      console.log(response.data);
      dispatch(updatedAddress(data));
      snackBarDialog("DIRECCION ACTUALIZADA");
      setIsLoading(false);
      setUpdateOpen(false);
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR DE ACTUALIZACION", "error");
      setIsLoading(false);
      setUpdateOpen(false);
    }
  };

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "URL",
      field: "url",
      align: "left",
      width: 120,
      renderCell: ({ row: { url } }) => {
        return (
          <Stack direction="row" spacing={0}>
            <Button
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                window.open(url);
              }}
              target="_blank"
            >
              VER UBICACION
            </Button>
          </Stack>
        );
      },
    },
    {
      headerName: "REFERENCIA",
      field: "url_address",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: "DIRECCION",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 300,
      wrap: true,
    },
    {
      headerName: "NAME",
      field: "fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      headerName: "ACTUALIZADO",
      field: "updated_at",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "ROLE",
      field: "subrole",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 120,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id, global } }) => {
        return (
          <Stack direction="row" spacing={1}>
            {global != 1 && (
              <>
                <IconButton
                  color="success"
                  aria-label="add"
                  component="label"
                  sx={{ background: "#ffff" }}
                  onClick={() => {
                    setUID(id);
                    setUpdateOpen(true);
                    addressState.map((el) => {
                      if (el.id === id) {
                        setUpdateAddress(el);
                      }
                    });
                  }}
                >
                  <BorderColorOutlinedIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  component="label"
                  sx={{ background: "#AA151B" }}
                  onClick={() => {
                    setUID(id);
                    setOpen(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            )}
          </Stack>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const [UID, setUID] = useState("");
  const handleClose = () => setOpen(false);
  const handleUpdateClose = () => setUpdateOpen(false);

  const handleAddressDelete = async () => {
    setIsLoading(true);
    try {
      const token = getToken("etknre");
      await deleteAddress(token, UID);
      setUID("");
      snackBarDialog("DIRECCION ELIMINADA");
      setIsLoading(false);
      setOpen(false);
      setAddress(address.filter((el) => el.id !== UID));
    } catch (error) {
      snackBarDialog("ERROR AL ELIMINAR LA DIRECCION", "error");
      setIsLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <main className="content">
        <Box>
          <Box display="grid" gridAutoRows="120px">
            <Box gridColumn="span 12" gridRow="span 2">
              <DataTable data={addressState} columns={columns} />
            </Box>
          </Box>
        </Box>
        <Modal open={open} onClose={handleClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography variant="h6" component="h2">
              ELIMINAR DIRECCION?
            </Typography>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={handleAddressDelete}
                  disabled={isLoading}
                >
                  ELIMINAR
                </Button>
              </Grid>
              <Grid item xs={6} md={6} textAlign="center">
                <Button
                  color="error"
                  variant="contained"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  CANCELAR
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal open={updateOpen} onClose={handleUpdateClose}>
          <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
            <Typography variant="h6" component="h2">
              ACTUALIZAR
            </Typography>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={updateAddress}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box backgroundColor={colors.primary[400]}>
                    <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="outlined">
                          <OutlinedInput
                            value={values.url}
                            id="address-url"
                            name="url"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="des"
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  <LinkOutlinedIcon />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.name}
                          fullWidth
                          type="text"
                          label={t("address").toUpperCase()}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          inputProps={{
                            maxLength: 50,
                            style: { textTransform: "uppercase" },
                          }}
                          name="name"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} textAlign="center">
                        <FormControl>
                          <FormLabel id="label-placement" color="secondary">
                            DIRECCION
                          </FormLabel>
                          <RadioGroup row name="position" defaultValue="global">
                            <FormControlLabel
                              value="global"
                              control={
                                <Radio
                                  color="success"
                                  onClick={(e) => {
                                    console.log(e.target.value);
                                    setRadio(e.target.value);
                                  }}
                                />
                              }
                              label="GLOBAL"
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="employee"
                              control={
                                <Radio
                                  color="success"
                                  onClick={(e) => {
                                    console.log(e.target.value);
                                    setRadio(e.target.value);
                                  }}
                                />
                              }
                              label="INDIVIDUAL"
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {radio === "employee" ? (
                        <Grid item xs={12} sm={12}>
                          <Autocomplete
                            value={employee}
                            disablePortal
                            name="employee"
                            id="combo-box-employee"
                            onChange={(e, newValue) => {
                              setEmployee(newValue);
                            }}
                            options={employees}
                            getOptionLabel={(option) =>
                              `${option.id}: ${option.name} ${option.lastname} - ${option.dni}`
                            }
                            renderInput={(params) => (
                              <TextField {...params} label={t("employee")} />
                            )}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}

                      <Grid item xs={12} sm={12} textAlign={"center"}>
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ height: 45 }}
                          disabled={isLoading}
                        >
                          {t("actualizar")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Modal>
        <SnackBar
          stateSnk={stateSnk}
          vertical={vertical}
          horizontal={horizontal}
          duration={5000}
          handleClose={handleSnkClose}
          severity={severity}
          messageSnk={messageSnk}
        />
      </main>
    </>
  );
};

export default AddressList;
