import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
  Grid,
} from "@mui/material";

import * as yup from "yup";
import { Formik } from "formik";

import { Copyright } from "../../../components/copyright/Copyright";
import AlertNotification from "../../../components/notificarions/AlertNotification";
import {
  getResetPassword,
  postResetPassword,
} from "../../../api/resetPassword";

const theme = createTheme();

const ResetPassword = () => {
  const { id, token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  const checkoutSchema = yup.object().shape({
    password: yup.string().required("Password Required"),
  });

  const initialValues = {
    password: "",
    cpassword: "",
  };

  useEffect(() => {
    const resetPassword = async (id, token) => {
      try {
        const response = await getResetPassword(id, token);
        setEmail(response.data.email);
      } catch (error) {
        setMessage(error.response.data.message);
      }
    };
    resetPassword(id, token);
  }, []);

  const handleFormSubmit = async (values) => {
    try {
      const response = await postResetPassword(id, token, values.password);
      console.log(response?.data?.message);
      if (response?.data?.message === "success") {
        setMessageSuccess(response?.data?.message);
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={`../../assets/logo.png`}
            alt="Logo"
            style={{ width: 152, height: 152 }}
          />
          <Typography component="h1" variant="h5">
            TaxiMundo.com
          </Typography>
          <Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  {message ? (
                    <AlertNotification
                      description={message}
                      alertType="error"
                    />
                  ) : (
                    <div>
                      <h2>{email}</h2>
                      {messageSuccess ? (
                        <AlertNotification
                          description={t("changeSuccessfull")}
                          alertType="success"
                        />
                      ) : (
                        <div>
                          <Box sx={{ margin: "4px" }}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  margin="normal"
                                  type="password"
                                  required
                                  fullWidth
                                  id="password"
                                  label={t("newPassword")}
                                  name="password"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.password}
                                  error={
                                    !!touched.password && !!errors.password
                                  }
                                  helperText={
                                    touched.password && errors.password
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  margin="normal"
                                  type="password"
                                  required
                                  fullWidth
                                  id="cpassword"
                                  label={t("confirmNewPassword")}
                                  name="cpassword"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.cpassword}
                                  error={
                                    !!touched.password && !!errors.password
                                  }
                                  helperText={
                                    touched.password && errors.password
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Button
                                  type="submit"
                                  fullWidth
                                  variant="contained"
                                  sx={{ mt: 2, mb: 2 }}
                                >
                                  {t("changePassword")}
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      )}
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
