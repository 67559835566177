import { Box, useTheme } from "@mui/material";
import { DataGrid, esES, enUS } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../theme";
import { TableProps } from "../../../../helpers/custom/TableProps";

const HistoryTable = ({ data, columns, setDataSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t, i18n } = useTranslation();
  return (
    <Box>
      <Box m="0px 0 0 0" height="100vh" sx={TableProps(colors)}>
        <DataGrid
          getRowId={(row) => row.id}
          rows={data}
          columns={columns}
          localeText={
            i18n.language.includes("es")
              ? esES.components.MuiDataGrid.defaultProps.localeText
              : enUS.components.MuiDataGrid.defaultProps.localeText
          }
          checkboxSelection
          onSelectionModelChange={(ids) => {
            setDataSelected([]);
            ids.map((id) => {
              const select = data.find((row) => row.id === id);
              setDataSelected((oldData) => [...oldData, select]);
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default HistoryTable;
