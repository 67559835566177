import { baseBackend } from "../base/baseUrl";
import { URL_BOOKING_BUSINESS } from "../../helpers/endpoint";
import { URL_REPORT_BOOKING } from "../../helpers/endpoint";

export const historyEmployeeById = async (employee_id) => {
  return await baseBackend.get(
    `${URL_REPORT_BOOKING}/history-order/${employee_id}`,
    { headers: { "Content-Type": "application/json" } }
  );
};

//booking business por rol
export const searchBookingEnterprisePerRole = async (
  enterprise_id,
  employee_role,
  from,
  to
) => {
  return await baseBackend.get(
    `${URL_REPORT_BOOKING}/enterprise/${enterprise_id}/role/${employee_role}/from/${from}/to/${to}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

//booking business por empleado
export const searchBookingEnterprisePerEmployee = async (
  employee_id,
  from,
  to
) => {
  return await baseBackend.get(
    `${URL_REPORT_BOOKING}/employee/${employee_id}/from/${from}/to/${to}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

//booking business por empleado
export const searchBookingPerEnterprise = async (enterprise_id, from, to) => {
  return await baseBackend.get(
    `${URL_REPORT_BOOKING}/enterprise/${enterprise_id}/from/${from}/to/${to}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const createdEnterpriseAndEmployee = async (enterprise, employee) => {
  return await baseBackend.post(`${URL_BOOKING_BUSINESS}/business`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const createdEmployee = async (token, employee) => {
  return await baseBackend.post(
    URL_BOOKING_BUSINESS,
    JSON.stringify({ employee }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

//axios.delete(url, { data: { hello: "world" }, headers: { "Authorization": "Bearer_token_here" } });
export const deletedEmployee = async (token, employee_id, status) => {
  return await baseBackend.delete(`${URL_BOOKING_BUSINESS}/${employee_id}`, {
    data: { status },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
