import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography, styled } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import { checkStatusPayment } from "../../../../api/openpay/openpay";

import { onValue, ref, update } from "firebase/database";

// import Voucher from "./voucher/Voucher";
import logo from "../../../assets/contratologo.jpg";
import { checkStatusPayment } from "../../../api/openpay/openpay";
import {
  decodeRefreshToken,
  getToken,
} from "../../../helpers/token/refreshToken";
import { db } from "../../../firebase";
import { createAdvanceCash } from "../../../api/booking/advance";

const TransferPay = () => {
  const navigate = useNavigate();
  const { currency } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dataPay, setDataPay] = useState(null);

  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: "100%",
    margin: "auto",
    minHeight: "40vh",
    display: "flex",
    gap: 2,
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(5, 0),
  }));

  useEffect(() => {
    const paymentId = searchParams.get("id");
    if (searchParams.get("id") != null && currency != undefined) {
      checkPayment(currency, paymentId);
    } else {
      console.log("no query");
    }
  }, []);

  const dateOpenpayFormat = (dateOp) => {
    //2024-03-06T14:21:11-05:00
    const [date, time] = dateOp.split("T");
    const data = date.split("-");
    const timeFormat = time.split(":");
    const hour =
      +timeFormat[0] > 12
        ? +timeFormat[0] % 12 < 10
          ? "0" + (+timeFormat[0] % 12)
          : +timeFormat[0] % 12
        : +timeFormat[0] > 9
        ? +timeFormat[0]
        : "0" + +timeFormat[0];
    const minute = +timeFormat[1] < 10 ? `0${+timeFormat[1]}` : +timeFormat[1];
    const amPM = +timeFormat[0] > 11 ? "PM" : "AM";
    return `${data[2]}/${data[1]}/${data[0]} ${hour}:${minute} ${amPM}`;
  };

  const checkPayment = async (payCurrency, paymentId) => {
    try {
      const response = await checkStatusPayment(paymentId, payCurrency);

      setDataPay(response.data.data);
      const data = response.data.data;
      const status = data.status;
      const orderId = data.order_id;
      const transactionId = data.id;
      const operationDate = dateOpenpayFormat(data.operation_date);
      const currencyOP = data.currency;
      const amount = data.amount;

      if (status == "completed") {
        const token = getToken("etknre");
        const id = decodeRefreshToken(token);

        const openpayData = {
          advance: 0,
          openpay: {
            order_id: "",
            transaction_id: "",
            operation_date: "",
            status: "",
            btnPay: false,
            currency: "",
          },
        };

        const dbRef = ref(db, `BookingBusiness/`);
        onValue(dbRef, (snapshot) => {
          const data = snapshot.val();
          Object.values(data).map((item) => {
            if (Number(item.client) === id) {
              if (item?.openpay?.transaction_id === transactionId) {
                update(ref(db, `BookingBusiness/${item.uuid}`), openpayData);
              }
            }
          });
        });

        const advance = {
          orderNumber: orderId.split("-")[0],
          transactionId: transactionId,
          currency: currencyOP,
          amount: amount,
          operationDate: operationDate,
          status: status,
          createdBy: "BUSINESS",
        };
        const resp = await createAdvanceCash(advance);
        console.log("advanceCreate", resp);
      } else {
        console.log("algun fallo hay en la transaccion");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          <Box
            display={"flex"}
            sx={{
              alignContent: "center",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: 5,
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              Servicios Generales Trans Mundo E.I.R.L.
            </p>
            <img
              src={logo}
              style={{
                width: 130,
                height: 50,
                my: { xs: 5, sm: 5 },
              }}
            />
          </Box>

          {dataPay != null && (
            <div style={{ textAlign: "left" }}>
              <Typography
                variant="h3"
                paragraph
                margin={0}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                TM-{dataPay.order_id.split("-")[0]}
              </Typography>

              <Typography variant="h3" paragraph margin={0}>
                <span style={{ fontWeight: "bold" }}>Cliente:</span>{" "}
                {dataPay.customer.name} {dataPay.customer.last_name}
              </Typography>

              <Typography variant="h3" paragraph margin={0}>
                <span style={{ fontWeight: "bold" }}>Monto:</span>{" "}
                {dataPay.currency} {dataPay.amount}
              </Typography>

              <Typography variant="h3" paragraph margin={0}>
                <span style={{ fontWeight: "bold" }}>Fecha:</span>{" "}
                {dataPay.operation_date}
              </Typography>

              <Typography variant="h3" paragraph margin={0}>
                <span style={{ fontWeight: "bold" }}>Descripcion:</span>{" "}
                {dataPay.description}
              </Typography>

              <Typography variant="h3" paragraph margin={0}>
                <span style={{ fontWeight: "bold" }}>Estado del Pago:</span>{" "}
                {dataPay.status}
              </Typography>

              {/* <Voucher data={dataPay} /> */}
            </div>
          )}

          <Button
            size="large"
            variant="contained"
            onClick={() => {
              navigate("/history-enterprise");
            }}
          >
            IR A HISTORIAL
          </Button>
        </StyledContent>
      </Container>
    </>
  );
};

export default TransferPay;
