import { Box, CssBaseline } from "@mui/material";
import ReportForm from "../../../../components/form/report/ReportForm";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";

const Reports = () => {
  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="REPORTES" subtitle="" />
              <Box></Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <ReportForm />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default Reports;
