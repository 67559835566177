import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  columnContainer: {
    flexDirection: "row",
    justifyContent: "flex-left",
  },

  invoiceNoContainer: {
    flexDirection: "row",
    justifyContent: "flex-left",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  invoiceDate: {
    fontSize: 10,
  },
  label: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    width: 75,
  },
  labelDate: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    width: 115,
  },
});

const Number = ({ invoice }) => (
  <Fragment>
    <View style={styles.columnContainer}>
      <View style={{flex: 1}}>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>PARTIDA:</Text>
          <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>LLEGADA:</Text>
          <Text style={styles.invoiceDate}>{invoice.trans_date}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>RECORRIDO:</Text>
          <Text style={styles.invoiceDate}>AIRPORT TO AIRPORT</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>GUIA: </Text>
        </View>
      </View>

      <View style={{flex: 1}}>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.labelDate}>FECHA DE PARTIDA:</Text>
          <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.labelDate}>FECHA DE LLEGADA: </Text>
          <Text style={styles.invoiceDate}>{invoice.trans_date}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.labelDate}>MATRICULA: </Text>
          <Text style={styles.invoiceDate}>AIRPORT TO AIRPORT</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.labelDate}>CONDUCTOR: </Text>
        </View>
      </View>
    </View>
  </Fragment>
);

export default Number;
