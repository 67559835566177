import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import Login from "./views/home/login";
import { DashboardAdmin } from "./views/users/admin/Dashboard";
import { ColorModeContext, useMode } from "./theme";
import ForgotPassword from "./views/home/forgot/Forgot";
import Home from "./views/home/Home";
import ResetPassword from "./views/home/forgot/ResetPassword";
import ListBookNow from "./views/users/admin/ListBookNow";
import AddBooking from "./views/users/admin/AddBooking";
import RegisterStepper from "./views/home/register/RegisterStepper";
import ConfirmEmail from "./views/home/confirm/ConfirmEmail";
import { AddAddress } from "./views/users/admin/address/AddAddress";
import AddEmployee from "./views/users/admin/employee/AddEmployee";
import AddRole from "./views/users/admin/role/AddRole";
import {
  ProtectedEmployeeRoute,
  ProtectedRoute,
} from "./components/protected/auth/ProtectedRoute";
import ListEmployee from "./views/users/admin/employee/ListEmployee";
import Reports from "./views/users/admin/reports/Reports";
import ReportPDF from "./views/users/admin/reports/Report-PDF";
import ReportManifest from "./views/users/admin/reports/ReportManifest";
import History from "./views/users/employee/History";
import ReportHistory from "./views/users/admin/reports/history/ReportHistory";
import Facturas from "./views/users/admin/reports/Factura";
import RoleEdit from "./views/users/admin/role/RoleEdit";
import Quote from "./views/users/admin/booking/cotizacion/quote";
import HistoryTab from "./views/users/employee/HistoryTab";
import HistoryPendingLater from "./views/users/employee/HistoryPendingLater";
import HistoryPendingNow from "./views/users/employee/HistoryPendingNow";
import CostCenter from "./views/users/admin/costcenter/views/CostCenter";
import EditEmployee from "./views/users/admin/employee/update/views/EditEmployee";
import TransferPay from "./views/users/payment/TransferPay";
import InvoiceOpenpay from "./views/users/payment/InvoiceOpenpay";

function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/history-enterprise" element={<HistoryTab />} />
          <Route path="/history-pending-now" element={<HistoryPendingNow />} />
          <Route path="/voucher/:currency" element={<TransferPay />} />
          <Route
            path="/history-pending-later"
            element={<HistoryPendingLater />}
          />

          <Route element={<ProtectedRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterStepper />} />
            <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
            <Route
              path="/reset-password/:id/:token"
              element={<ResetPassword />}
            />
            <Route path="/forgot" element={<ForgotPassword />} />
          </Route>

          <Route element={<ProtectedEmployeeRoute />}>
            <Route path="business">
              <Route index element={<DashboardAdmin />} />
              <Route path="add-employee" element={<AddEmployee />} />
              <Route path="list-employee" element={<ListEmployee />} />
              <Route path="edit-employee/:id" element={<EditEmployee />} />
              <Route path="add-role" element={<AddRole />} />
              <Route
                path="edit-role/:id/:enterprise_id"
                element={<RoleEdit />}
              />
              <Route path="add-address" element={<AddAddress />} />
              <Route path="list-book-now" element={<ListBookNow />} />
              <Route path="add-booking/:id" element={<AddBooking />} />
              <Route path="reports" element={<Reports />} />
              <Route path="cotizacion" element={<Quote />} />
              <Route path="facturas" element={<Facturas />} />
              <Route path="redirect/:currency" element={<InvoiceOpenpay />} />
              <Route path="reports-pdf" element={<ReportPDF />} />
              <Route path="reports-manifest" element={<ReportManifest />} />
              <Route path="reports-history" element={<ReportHistory />} />
              <Route path="costos" element={<CostCenter />} />
            </Route>
          </Route>
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
