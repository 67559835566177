import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import TableHeaderH from "./TableHeaderH";
import TableRowH from "./TableRowH";
import TableTotal from "./TableTotal";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    marginBottom: 20,
  },
});

const TableHorizontal = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <TableHeaderH />
    <TableRowH items={invoice.items} />
    <TableTotal invoice={invoice} />
  </View>
);

export default TableHorizontal;
