import React from "react";
import { Copyright } from "../copyright/Copyright";
import "./footer.css";
import logo from "../../assets/logo-main.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        <div className="container grid1">
          <div className="box">
            <img src={logo} alt="" width="150" height="70"/>
            <p>{t("footerDescription")}</p>
          </div>

          <div className="box">
            <h2>Links</h2>
            <ul>
              <li>Home</li>
              <li>About</li>
              <li>Contact</li>
              <li>Faq's</li>
              <li>Policy</li>
            </ul>
          </div>

          <div className="box">
            <h2>{t("contact")}</h2>
            <div className="icon">
              <label>☏ Call Center: +51 54 200 900</label>
            </div>
            <div className="icon">
              <label>📱 Movil (WhatsApp): 958 200 900*</label>
            </div>
            <div className="icon">
              <label>📱 Movil (WhatsApp): 951 200 900</label>
            </div>
            <div className="icon">
              <label>📧 Email: info@taximundo.com</label>
            </div>
          </div>
        </div>
        <div className="legal container">
          <Copyright />
        </div>
      </footer>
    </>
  );
};

export default Footer;
