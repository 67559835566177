import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
const stylesEnterprise = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },
});

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 1,
    display: "flex",
    alignSelf: "center",
  },
  document: {
    color: "#4B44BE",
    fontSize: 18,
    textTransform: "uppercase",
    fontFamily: "Helvetica-Bold",
    marginRight: 60,
  },
});

const EnterpriseName = ({ title, enterprise, name, lastname }) => (
  <>
    <View style={styles.titleContainer}>
      <Text style={styles.document}>{title}</Text>
    </View>

    <View style={stylesEnterprise.headerContainer}>
      <Text>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>Empresa:</Text>
        {enterprise}
      </Text>
      <Text>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>Encargado: </Text>
        {lastname} {name}
      </Text>
    </View>
  </>
);

export default EnterpriseName;
