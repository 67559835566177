import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import DataTable from "../../../../components/datatable/DataTable";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import { tokens } from "../../../../theme";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  decodeDataToken,
  getToken,
} from "../../../../helpers/token/refreshToken";
import { getEntepriseInvoice } from "../../../../api/booking/invoice";
import { dateFormat } from "../../../../helpers/date/date";
import { searchInvoiceBusiness } from "./repository/invoice.repository";
import DetailsTable from "./views/components/DetailsTable";
import { generateOpenPayURL } from "../../../../api/openpay/openpay";
import { createInvoiceCash } from "../../../../api/employee/invoicecash";

const Facturas = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [booking, setBooking] = useState([]);

  const ListIgv = {
    1: "INAFECTO",
    2: "AFECTO",
    3: "MAS IGV",
  };

  useEffect(() => {
    const searchBookingByEnterprise = async () => {
      try {
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getEntepriseInvoice(enterprise_id);
        setBooking([]);
        Object.values(response.data).map((data, idx) => {
          console.log(data);
          data.invoice = data.invoice.toUpperCase();
          data.date_issue = dateFormat(new Date(data.date_issue));
          data.date_expire = dateFormat(new Date(data.date_expire));
          data.igv = ListIgv[data.igv];
          if (data.status === "ANULADO") {
            data.total = 0;
            data.subTotal = 0;
            data.subIgv = 0;
          } else {
            const total = Number(data.total);
            const toll = Number(data.toll);
            const fullPrice = total + toll;
            if (data.igv === "INAFECTO") {
              data.subTotal = 0;
              data.subIgv = 0;
            } else if (data.igv === "AFECTO") {
              const sub = Math.round((fullPrice / 1.18) * 100) / 100;
              data.subTotal = sub;
              data.subIgv = Math.round((fullPrice - sub) * 100) / 100;
            } else if (data.igv === "MAS IGV") {
              const imp = Math.round(fullPrice * 0.18 * 100) / 100;
              data.subIgv = imp;
              data.subTotal = fullPrice;
              data.total = fullPrice + imp;
            }
          }

          setBooking((oldData) => [...oldData, data]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    searchBookingByEnterprise();
  }, []);

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const columns = [
    { headerName: "FACTURA", field: "invoice", width: 120 },
    {
      headerName: "EMISION",
      field: "date_issue",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "VENCIMIENTO",
      field: "date_expire",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "DESCRIPCION",
      field: "notes",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 300,
    },
    {
      headerName: "Sub TOTAL",
      field: "subTotal",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "IGV",
      field: "subIgv",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "TOTAL",
      field: "total",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "ESTADO",
      field: "status",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { status } }) => {
        return (
          <>
            {status === "PAGADO" && (
              <p style={{ color: "green", fontWeight: "bold" }}>{status}</p>
            )}
            {status === "NO PAGADO" && (
              <p style={{ color: "red", fontWeight: "bold" }}>PENDIENTE</p>
            )}
            {status != "NO PAGADO" && status != "PAGADO" && (
              <p style={{ color: "red", fontWeight: "bold" }}>{status}</p>
            )}
          </>
        );
      },
    },
    {
      headerName: "",
      field: "opt",
      width: 150,
      renderCell: ({ row: { id, status, invoice, total } }) => {
        return (
          <>
            <Button
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                setOpenDetails(true);
                setInvoiceName(invoice);
                searchInvoiceBusinessFun(id);
              }}
            >
              VER
            </Button>
            {status === "NO PAGADO" && (
              <Button
                variant="contained"
                sx={{
                  background: "blue",
                  color: "white",
                  fontWeight: "bold",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  generateOpenPayForm(total, id, invoice);
                }}
              >
                PAGAR
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2); // Agregar un cero inicial si es necesario
    var dia = ("0" + fecha.getDate()).slice(-2); // Agregar un cero inicial si es necesario
    var hora = ("0" + fecha.getHours()).slice(-2); // Agregar un cero inicial si es necesario
    var minuto = ("0" + fecha.getMinutes()).slice(-2); // Agregar un cero inicial si es necesario
    var segundo = ("0" + fecha.getSeconds()).slice(-2); // Agregar un cero inicial si es necesario

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const generateOpenPayForm = async (total, id, invoice) => {
    try {
      const dataToken = getToken("dttkn");
      const { name, lastname, phone, email } = decodeDataToken(dataToken);
      const orderID = `${id}-${Date.now()}`;
      const redirectUrl = `https://business.taximundo.com/business/redirect/PEN`;
      // const redirectUrl = `http://localhost:3003/business/redirect/PEN`;

      const bookingOpenPay = {
        method: "card",
        amount: Number(total),
        currency: "PEN",
        description: `${invoice}, PAGO DE FACTURA POR SERVICIO DE TRANSPORTE`,
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(),
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      const response = await generateOpenPayURL(bookingOpenPay);
      if (response.data.message === "success") {
        const data = response.data.data;
        const url = response.data.data.payment_method.url;
        window.open(url, "_blank");

        const invoiceCash = {
          invoiceId: id,
          createdBy: "BUSINESS",
          transactionId: data.id,
          currency: data.currency,
          amount: data.amount,
          operationDate: data.operation_date,
          status: data.status,
        };
        await createInvoiceCash(invoiceCash);
      } else {
        alert("ERROR");
      }
    } catch (error) {
      console.log(error);
      alert("ERROR");
    }
  };

  const [invoiceName, setInvoiceName] = useState("");
  const detailsColumns = [
    {
      headerName: "FECHA",
      field: "date",
      width: 80,
    },
    {
      headerName: "Pedido",
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
      renderCell: ({ row: { invoice_status, order_number } }) => {
        return (
          <div
            style={{
              fontSize: 14,
              color: "white",
              background: invoice_status === 1 ? "red" : "blue",
              borderRadius: 3,
              padding: 2,
            }}
          >
            {order_number}
          </div>
        );
      },
    },
    {
      headerName: "NOMBRE",
      field: "fullname_employee",
      width: 200,
      renderCell: ({ row: { employee_name, employee_lastname } }) => {
        return (
          <div>
            {employee_name} {employee_lastname}
          </div>
        );
      },
    },

    {
      headerName: "ORIGEN",
      field: "origin_name",
      width: 250,
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      width: 250,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      width: 100,
    },
    {
      headerName: "TOTAL",
      field: "price_total",
      width: 100,
    },
  ];

  const [openDetails, setOpenDetails] = useState(false);
  const handleDetailsClose = () => setOpenDetails(false);
  const [listDetails, setListDetails] = useState([]);

  const searchInvoiceBusinessFun = async (invoiceId) => {
    try {
      const response = await searchInvoiceBusiness("token", invoiceId);
      console.log(response.data);
      setListDetails([]);
      Object.values(response.data).map((item, idx) => {
        item.idx = idx + 1;
        item.id = item.order_number;
        setListDetails((oldData) => [...oldData, item]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #000",
    boxShadow: 20,
    width: "80%",
    p: 2,
    overflow: "scroll",
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Header title="FACTURAS" subtitle="" />
            </Box>

            <Box backgroundColor={colors.primary[400]} p={0}>
              <Grid container sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={12}>
                  {booking.length > 0 && (
                    <DataTable data={booking} columns={columns} />
                  )}
                </Grid>
              </Grid>
            </Box>

            <Modal open={openDetails} onClose={handleDetailsClose}>
              <Box
                height={"70%"}
                sx={styleModal}
                backgroundColor={colors.primary[400]}
              >
                <Grid
                  container
                  spacing={0}
                  alignContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item xs={11} textAlign={"center"}>
                    <Typography variant="h5" component="h2">
                      FACTURA : {invoiceName}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} textAlign={"end"}>
                    <IconButton
                      variant="contained"
                      color="error"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDetailsClose();
                      }}
                    >
                      <HighlightOffOutlinedIcon sx={{ fontSize: "25px" }} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    {listDetails.length > 0 && (
                      <DetailsTable
                        data={listDetails}
                        checkboxSelection={false}
                        columns={detailsColumns}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Modal>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={5000}
              handleClose={handleClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </main>
      </div>
    </>
  );
};

export default Facturas;
