import { URL_SUNAT_RUC } from "../../helpers/endpoint";
import { baseBackend } from "../base/baseUrl";
//https://api.apis.net.pe/v1/dni?numero=46027897
//https://api.apis.net.pe/v1/ruc?numero=10460278975
export const getSunatRuc = async (ruc) => {
  return await baseBackend.post(
    URL_SUNAT_RUC,
    JSON.stringify({
      ruc,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
