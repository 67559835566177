import { getReportHistory } from "../repository/report.repository";

export const GetFilterHistory = async (params) => {
  try {
    const response = await getReportHistory(params);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
