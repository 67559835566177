import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import TableHeader from "./Table/TableHeader";
import TableRow from "./Table/TableRow";
import TableBlankSpace from "./Table/TableBlankSpace";
import TableFooter from "./Table/TableFooter";
import TableHeaderDriving from "./Table/TableHeaderDriving";
import TableRowDriving from "./Table/TableRowDriving";
import TableHeaderOccurrences from "./Table/TableHeaderOccurrences";
import TableRowOccurrences from "./Table/TableRowOccurrences";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    //borderWidth: 1,
    // borderColor: "#bff0fd",
  },
});

const ItemsTable = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <Text style={{ fontFamily: "Helvetica-Bold" }}>DATOS DEL CONDUCTOR</Text>
    <TableHeader />
    <TableRow items={invoice.items} />

    <Text style={{ fontFamily: "Helvetica-Bold", marginTop: 10 }}>
      TURNOS DE CONDUCCION
    </Text>
    <TableHeaderDriving />
    <TableRowDriving items={invoice.items} />

    <Text style={{ fontFamily: "Helvetica-Bold", marginTop: 10 }}>
      HORAS DE DESCANSO
    </Text>
    <TableHeaderDriving />
    <TableRowDriving items={invoice.items} />
    {/* <TableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />  OCURRENCIAS DEL VIAJE*/}
    {/* <TableFooter items={invoice.items} /> */}
    <Text style={{ fontFamily: "Helvetica-Bold", marginTop: 10 }}>
      OCURRENCIAS DEL VIAJE
    </Text>
    <TableHeaderOccurrences />
    <TableRowOccurrences items={invoice.items} />
  </View>
);

export default ItemsTable;
