import { updateSede } from "../repository/sede.repository";

export const UpdateSede = async (sede) => {
  try {
    const response = await updateSede(sede);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
