import { Box, CssBaseline } from "@mui/material";
import AddressForm from "../../../../components/form/address/AddressForm";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import AddressList from "./AddressList";

export const AddAddress = () => {
  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="AGREGAR DIRECCIONES" subtitle="" />
              <Box></Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <AddressForm />
                <AddressList />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};
