import axios from "axios";
import { URL_DRIVER } from "../helpers/endpoint";

const base = axios.create({
  baseURL: "http://localhost:4000",
});

export const getDrivers = async (token) => {
  return await base.get(URL_DRIVER, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createdDriver = async (user, contract, license, driver, commissions, vehicles) => {
  return await base.post(
    URL_DRIVER,
    JSON.stringify({
      user, contract, license, driver, commissions, vehicles
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
