import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../../../theme";

const EmployeeBox = ({ employees, area, costCenter, admin, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="10px 10px">
      <Box height={200}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item sm={6}>
            <Typography
              fontWeight={"bold"}
              variant="h3"
              sx={{ color: colors.greenAccent[500] }}
            >
              USUARIOS
            </Typography>
          </Grid>
          <Grid item sm={6} textAlign={"end"}>
            {icon}
          </Grid>
        </Grid>

        <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
          COLABORADORES: {employees}
        </Typography>
        <Typography variant="h4" sx={{ color: colors.greenAccent[100] }}>
          <span style={{ fontWeight: "bold" }}>AREAS: </span> {area}
        </Typography>
        <Typography variant="h4" sx={{ color: colors.greenAccent[100] }}>
          <span style={{ fontWeight: "bold" }}>CENTRO DE COSTOS: </span>
          {costCenter}
        </Typography>
        <Typography variant="h4" sx={{ color: colors.greenAccent[100] }}>
          <span style={{ fontWeight: "bold" }}>ADMINISTRADORES: </span> {admin}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmployeeBox;
