import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../../../theme";
import { red } from "@mui/material/colors";

const BookingBox = ({ title, subtitle, icon, month, week, day }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="10px 10px">
      <Box height={200}>
        <Grid container spacing={1} alignItems={"center"}>
          <Grid item sm={6}>
            <Typography
              fontWeight={"bold"}
              variant="h3"
              sx={{ color: colors.greenAccent[500] }}
            >
              TRASLADOS
            </Typography>
          </Grid>
          <Grid item sm={6} textAlign={"end"}>
            {icon}
          </Grid>
        </Grid>

        <Typography variant="h3" sx={{ color: colors.greenAccent[500] }}>
          COMPLETADOS: <span style={{ fontWeight: "bold" }}>{title}</span>
        </Typography>
        <Typography variant="h4" sx={{ color: colors.greenAccent[100] }}>
          <span style={{ fontWeight: "bold" }}>ULTIMO MES:</span> {month}
        </Typography>
        <Typography variant="h4" sx={{ color: colors.greenAccent[100] }}>
          <span style={{ fontWeight: "bold" }}>ULTIMA SEMANA:</span> {week}
        </Typography>
        <Typography variant="h4" sx={{ color: colors.greenAccent[100] }}>
          <span style={{ fontWeight: "bold" }}>HOY DIA:</span> {day}
        </Typography>
      </Box>
    </Box>
  );
};

export default BookingBox;
