import { baseBackend } from "../base/baseUrl";
import { URL_INVOICE } from "../../helpers/endpoint";

//booking business por rol
export const getEntepriseInvoice = async (enterprise_id) => {
  return await baseBackend.get(`${URL_INVOICE}/enterprise/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
