import { baseBackend } from "../base/baseUrl";

const URL_INVOICE_CASH = "/api/v1/enterprise-invoice";

export const createInvoiceCash = async (invoice) => {
  return await baseBackend.post(
    `${URL_INVOICE_CASH}/create`,
    JSON.stringify({ invoice }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};
