import { createSlice } from "@reduxjs/toolkit";

export const addressSlice = createSlice({
  name: "address",
  initialState: [],
  reducers: {
    addAddress: (state, action) => {
      state.push(action.payload);
    },
    deletedAddress: (state, action) => {
      const addressFound = state.find(
        (address) => address.id === action.payload
      );
      if (addressFound) {
        state.splice(state.indexOf(addressFound), 1);
      }
    },
    updatedAddress: (state, action) => {
      const { id, name, url, url_address } = action.payload;
      const addressFound = state.find((address) => address.id === id);
      if (addressFound) {
        addressFound.name = name;
        addressFound.url = url;
        addressFound.url_address = url_address;
      }
    },
  },
});

export const { addAddress, deletedAddress, updatedAddress } =
  addressSlice.actions;
export default addressSlice.reducer;
