import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000000";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000000",
    backgroundColor: "#4B44BE",
    borderBottomWidth: 1,
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    height: 20,
    flexGrow: 1,
  },
  index: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  fullname: {
    width: "65%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  license: {
    width: "30%",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
});

const TableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.index}>No</Text>
    <Text style={styles.fullname}>NOMBRES Y APELLIDOS</Text>
    <Text style={styles.license}>LICENCIA DE CONDUCIR</Text>
  </View>
);

export default TableHeader;
