import { updateCostCenter } from "../repository/costcenter.repository";

export const UpdateCostCenter = async (costCenter) => {
  try {
    const response = await updateCostCenter(costCenter);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
