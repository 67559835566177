import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },

  lineContainer: {
    flexDirection: "row",
    marginTop: 1,
  },
  enterprise: {
    width: "80%",
    fontSize: 12,
  },
  ruc: {
    fontSize: 12,
    fontFamily: "Helvetica-Bold",
  },
});

const Enterprise = ({ invoice }) => (
  <View>
    {console.log(invoice)}
    <View style={styles.lineContainer}>
      <Text style={styles.enterprise}>
        <Text style={{ fontFamily: "Helvetica-Bold" }}>Empresa: </Text>
        {invoice.items[0]?.enterpriseName}
      </Text>
      {/* <Text>
        <Text style={styles.ruc}>RUC: </Text>
        47430588855
      </Text> */}
    </View>
    <Text>
      <Text style={{ fontFamily: "Helvetica-Bold" }}>Responsable: </Text>
      {invoice.items[0]?.clientName}
    </Text>
  </View>
);

export default Enterprise;
