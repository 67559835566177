import React from "react";
import { useTranslation } from "react-i18next";
import { Box, CssBaseline } from "@mui/material";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import EmployeeForm from "../../../../components/form/employee/EmployeeForm";

const AddEmployee = () => {
  const { t } = useTranslation();

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={t("CREAR COLABORADOR")} subtitle="" />
            </Box>

            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="0px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                <EmployeeForm />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default AddEmployee;
