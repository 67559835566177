import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";

import DataTable from "../../../../components/datatable/DataTable";
import SnackBar from "../../../../components/snackbar/SnackBar";
import {
  decodeDataToken,
  getToken,
} from "../../../../helpers/token/refreshToken";
import { tokens } from "../../../../theme";
import { modalStyle } from "../address/AddressStyle";
import Topbar from "../../../../components/topbar/TopBar";
import Header from "../../../../components/header/Header";
import {
  deletedEmployee,
  getEmployeesFromEnterprise,
} from "../../../../api/employee/employee";

const ListEmployee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);

  //delete
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UID, setUID] = useState("");
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const statusUser = { 1: "ACTIVO", 2: "SUSPENDIDO", 4: "ELIMINADO" };

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getEmployeesFromEnterprise(token, enterprise_id);
        setEmployees([]);
        Object.values(response?.data).map((employee, index) => {
          if (employee.status != 4) {
            employee.index = index + 1;
            employee.statusName = statusUser[employee.status];
            setEmployees((oldData) => [...oldData, employee]);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);

  const handleClose = () => setOpen(false);

  const handleEmployeeDelete = async (status) => {
    setIsLoading(true);
    try {
      const token = getToken("etknre");
      await deletedEmployee(token, UID, status);
      setUID("");
      snackBarDialog(
        status === 4 ? "EMPLEADO ELIMINADO" : "EMPLEADO SUSPENDIDO"
      );
      setIsLoading(false);
      setOpen(false);
      //setEmployees(employees.filter((el) => el.id !== UID));
    } catch (error) {
      snackBarDialog("ERROR DEL SERVIDOR", "error");
      setIsLoading(false);
      setOpen(false);
    }
  };

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "DNI",
      field: "dni",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 90,
    },
    {
      headerName: "NOMBRE",
      field: "fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 280,
    },
    {
      headerName: "EMAIL",
      field: "email",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "PHONE",
      field: "phone",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 110,
    },
    {
      headerName: "ROLE",
      field: "subrole_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 90,
    },
    {
      headerName: "SEDE",
      field: "sede_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 90,
    },
    {
      headerName: "C. DE COSTOS",
      field: "cost_center",
      type: "string",
      align: "left",
      width: 90,
      renderCell: ({ row: { costcenter } }) => {
        return (
          <>
            {costcenter.map((item, idx) => (item.name ? `${item.name}, ` : ""))}
          </>
        );
      },
    },
    {
      headerName: "ESTADO",
      field: "statusName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 90,
    },
    {
      headerName: "MODIFICACION",
      field: "date_m",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id, status } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/business/edit-employee/${id}`);
              }}
            >
              <BorderColorOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setUserStatus(status);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [userStatus, setUserStatus] = useState(0);

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title={t("LISTA DE COLABORADORES")} subtitle="" />
          </Box>
          <Box>
            <Box display="grid" gridAutoRows="120px">
              <Box gridColumn="span 12" gridRow="span 2">
                <DataTable data={employees} columns={columns} />
              </Box>
            </Box>
          </Box>
          <Modal open={open} onClose={handleClose}>
            <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
              <Typography variant="h6" component="h2" textAlign={"center"}>
                ¿ELIMINAR EMPLEADO?
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => handleEmployeeDelete(4)}
                    disabled={isLoading}
                  >
                    ELIMINAR
                  </Button>
                </Grid>

                {userStatus == 1 ? (
                  <Grid item xs={4} md={4}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={() => handleEmployeeDelete(2)}
                      disabled={isLoading}
                    >
                      SUSPENDER
                    </Button>
                  </Grid>
                ) : (
                  <Grid item xs={4} md={4}>
                    <Button
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={() => handleEmployeeDelete(1)}
                      disabled={isLoading}
                    >
                      ACTIVAR
                    </Button>
                  </Grid>
                )}

                <Grid item xs={4} md={4}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    onClick={handleClose}
                    disabled={isLoading}
                  >
                    CANCELAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </main>
      </div>
    </div>
  );
};

export default ListEmployee;
