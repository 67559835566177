import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { set, ref, onValue } from "firebase/database"
import { Box, IconButton, Stack, useTheme } from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";

const TableBookNow = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [booking, setBooking] = useState([])
  
  useEffect(() => {
    const starCountRef = ref(db, 'bookingNow/');
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setBooking([])
      if(data !== null){
        //console.log(data)
        Object.values(data).map((booking) => {
          setBooking(oldArray => [...oldArray, booking])
        })

      }
    })
    
  }, []);

  useEffect(() => {
    if (booking){
      booking.map((booking) => {
        booking.originName = booking.origin.name
        booking.destinationName = booking.destination.name
      })
    }
  }, [booking]);

  console.log(booking)
  //{ field: "id", headerName: "ID", width: 40 },
  const columns = [
    {
      headerName: "Origin",
      field:  "originName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      headerName: "Destination",
      field: "destinationName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      headerName: "Persons",
      field: "persons",
      width: 20,
    },
    {
      headerName: "Notes",
      field: "note",
      width: 200,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 100,
      renderCell: ({ row: { uuid } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                //console.log(uuid)
                navigate(`/administrator/add-booking/${uuid}`);
              }}
            >
              <AdminPanelSettingsOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box m="10px">
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection getRowId={(row) =>  row.uuid} rows={booking} columns={columns} />
      </Box>
    </Box>
  );
};

export default TableBookNow;
