import { baseBackend } from "../base/baseUrl";
import { URL_SUB_ROLE } from "../../helpers/endpoint";

export const getSubroles = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_SUB_ROLE}/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createSubrole = async (token, subrole, enterprise_id) => {
  return await baseBackend.post(
    URL_SUB_ROLE,
    JSON.stringify({
      name: subrole,
      enterprise_id,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteSubrole = async (token, roles_id) => {
  return await baseBackend.delete(`${URL_SUB_ROLE}/${roles_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSubrole = async (token, enterprise_id, subrole_id) => {
  return await baseBackend.post(
    `${URL_SUB_ROLE}/get`,
    JSON.stringify({
      enterprise_id,
      subrole_id,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateSubrole = async (token, subrole) => {
  return await baseBackend.put(
    URL_SUB_ROLE,
    JSON.stringify({
      subrole,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};