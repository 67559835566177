import {
  URL_LOGIN,
  URL_FORGOT_PASSWORD,
  URL_CLIENT,
  URL_RE_SEND_EMAIL,
} from "../helpers/endpoint";
import { baseBackend } from "./base/baseUrl";

export const login = async (email, password) => {
  return await baseBackend.post(
    URL_LOGIN,
    JSON.stringify({ email, password, role: 4 }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const registerClient = async (
  name,
  lastname,
  phone,
  address,
  genres_id,
  email,
  password,
  company_id,
  roles_id,
  status
) => {
  return await baseBackend.post(
    URL_CLIENT,
    JSON.stringify({
      name,
      lastname,
      phone,
      address,
      genres_id,
      email,
      password,
      company_id,
      roles_id,
      status,
    }),
    {
      headers: { "Content-Type": "application/json" },
    }
  );
};

export const forgotPassword = async (email) => {
  return await baseBackend.post(
    URL_FORGOT_PASSWORD,
    JSON.stringify({ email, role: 4 }),
    {
      headers: { "Content-Type": "application/json" },
      //withCredentials: true,
    }
  );
};

export const reSendConfirmEmail = async (email, urlOrigin) => {
  return await baseBackend.post(
    URL_RE_SEND_EMAIL,
    JSON.stringify({ urlOrigin, email }),
    {
      headers: { "Content-Type": "application/json" },
      //withCredentials: true,
    }
  );
};
