import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";

import { login, reSendConfirmEmail } from "../../../api/axios";
import logo from "../../../assets/logo.png";
import Notification from "../../../components/notificarions/AlertNotification";
import { boxStyle } from "./LoginStyle";
import { Copyright } from "../../../components/copyright/Copyright";
import { setToken } from "../../../helpers/token/refreshToken";
import SnackBar from "../../../components/snackbar/SnackBar";

const theme = createTheme();
export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const emailRequire = t("emailRequire");
  const emailInvalid = t("emailInvalid");
  const passwordRequire = t("passwordRequire");
  const passwordLong = t("passwordLong");

  const checkoutSchema = yup.object().shape({
    email: yup.string().email(emailInvalid).required(emailRequire),
    password: yup.string().required(passwordRequire).min(6, passwordLong),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => {
    setStateSnk(false);
  };
  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const [reSendEmail, setReSendEmail] = useState(false);
  const [validEmail, setValidEmail] = useState(null);

  const handleFormSubmit = async (values) => {
    //e.preventDefault();
    try {
      setIsLoading(false);
      const response = await login(values.email, values.password);
      const token = response?.data.token;
      const dataToken = response?.data.dataToken;
      setToken("etknre", token);
      setToken("dttkn", dataToken);
      navigate("/");
    } catch (err) {
      const errMsg = err.response.data.error;
      setErrorMessage(errMsg);
      setReSendEmail(false);
      setValidEmail(null);

      if (errMsg === "UNVERIFIED_EMAIL") {
        setReSendEmail(true);
        setValidEmail(values.email);
      }
      // if(errMsg)

      setTimeout(() => {
        setErrorMessage(null);
      }, 5000);
    }
  };

  const sendEmailConfirm = async () => {
    try {
      setIsLoading(true);
      await reSendConfirmEmail(validEmail, "business");
      snackBarDialog("EMAIL DE VERIFICACION ENVIADO");
      setReSendEmail(false);
      setIsLoading(false);
      setValidEmail(null);
    } catch (error) {
      snackBarDialog("ERROR, NO SE LOGRO ENVIAR EMAIL", "error");
      setReSendEmail(false);
      setIsLoading(false);
      setValidEmail(null);
      console.log(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box sx={boxStyle}>
          <img src={logo} alt="Logo" style={{ width: 152, height: 152 }} />
          <Typography component="h1" variant="h5">
            Taxi Mundo Empresas
          </Typography>

          <Box sx={{ mt: 1, width: 350 }}>
            <Notification description={errorMessage} alertType="error" />
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        value={values.email}
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        autoComplete="email"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        value={values.password}
                        required
                        fullWidth
                        name="password"
                        label={t("password")}
                        type="password"
                        id="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={!!touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                        autoComplete="current-password"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={isLoading}
                      >
                        {t("login")}
                      </Button>
                    </Grid>
                    {reSendEmail && (
                      <>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="h7" fontStyle={"italic"}>
                            Enviar email de Verficacion a: {validEmail}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            sx={{ mb: 2 }}
                            onClick={(e) => {
                              e.preventDefault();
                              sendEmailConfirm();
                            }}
                            disabled={isLoading}
                          >
                            {t("ENVIAR")}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  <Grid container>
                    <Grid item>
                      <Link to="/forgot">{t("forgotPassword")}</Link>
                    </Grid>
                    <Grid item>
                      <Link to="/register">{t("registerNow")}</Link>
                    </Grid>
                  </Grid>
                  <SnackBar
                    stateSnk={stateSnk}
                    vertical={vertical}
                    horizontal={horizontal}
                    duration={5000}
                    handleClose={handleSnkClose}
                    severity={severity}
                    messageSnk={messageSnk}
                  />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
