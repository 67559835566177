import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { db } from "../../../firebase";
import { set, ref } from "firebase/database";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Stack,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Container,
  Tabs,
  Tab,
  Autocomplete,
  Modal,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

import {
  decodeDataToken,
  decodeRefreshToken,
  deleteToken,
  getToken,
  verifyExpireTokenRefresh,
} from "../../../helpers/token/refreshToken";
import { addBooking } from "../../../features/booking/bookingSlice";
import SnackBar from "../../../components/snackbar/SnackBar";

import "./booking.css";
import { displayBox } from "./BookingStyle";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getModels } from "../../../api/resources/model";
import { getEnterpriseEmployeeAddress } from "../../../api/users/enterprise/address/address";
import {
  dateFormat,
  formatDateTime,
  timeFormat,
} from "../../../helpers/date/date";
import { getEnterpriseData } from "../../../api/users/enterprise/data/enterprise";
import { sendICSEmail, sendICSEmailNow } from "../../../api/resources/ics/ics";
import { CreateBackupUseCase } from "./usecases/backup/CreateBackupUseCase";
import { getRatesAndRoutesAddress } from "../../../api/booking/taxroutes";
import { CounterOfferObserver } from "./usecases/CounterOfferObserver";
import loadingStyle from "./styles/loading.module.css";
import { UpdateCounterOffer } from "./usecases/UpdateCounterOffer";
import { SendNotificationDriver } from "./usecases/SendNotificationDriver";
import { DeleteAllCounterOffers } from "./usecases/DeleteAllCounterOffers";
import { BookingObserver } from "./usecases/BookingObserver";
import {
  getDriverStore,
  removeDriverStore,
  saveDriverStore,
} from "../../../helpers/store/DriverStore";
import { DeleteBooking } from "./usecases/DeleteBooking";
import { SendNotificationsDriver } from "./usecases/SendNotificationsDriver";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { GetSettingUseCase } from "./usecases/GetSettingUseCase";
import { formattedDate } from "../../../helpers/date/formattedDate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Booking = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const theme = createTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bookingState = useSelector((state) => state.bookings);
  const [model, setModel] = useState([]);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const [dateEnd, setDateEnd] = useState(null);
  const [timeEnd, setTimeEnd] = useState(null);

  const [vehicleSelect, setVehicleSelect] = useState(1);
  const [priceDriver, setPriceDriver] = useState(0);
  const [priceTotal, setPriceTotal] = useState(0);
  const [tax, setTax] = useState(0);

  //api address
  const [addressApi, setAddressApi] = useState([]);
  const [addressOrigin, setAddressOrigin] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  const [addressDestination, setAddressDestination] = useState(
    bookingState?.data ? bookingState?.data.destination.name : null
  );

  //OBSERVERS DE BOOKING
  const [bookingNow, setBookingNow] = useState(null);
  const [uidBooking, setUidBooking] = useState("");
  const [isBookingActive, setIsBookingActive] = useState(false);
  const [driverSelect, setDriverSelect] = useState(null);

  const handleChange = (event, newValue) => setValue(newValue);

  useEffect(() => {
    const models = async () => {
      try {
        const response = await getModels();
        const data = response.data.filter((model) => model.id);
        setModel(data);
      } catch (error) {
        console.log(error);
      }
    };
    models();
  }, []);
  //tipo de auto cantidad de pasajeros
  useEffect(() => {
    const getAddressEnterprise = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");

        if (dataToken != null && token != null) {
          const { enterprise_id } = decodeDataToken(dataToken);
          const uid = decodeRefreshToken(token);

          setAddressApi([]);
          setAddressOrigin(null);
          //setAddressDestination(null);
          const response = await getEnterpriseEmployeeAddress(
            token,
            uid,
            enterprise_id
          );
          setAddressApi(response.data);
          const resPaymentType = await getEnterpriseData("", enterprise_id);
          setPaymentType(resPaymentType.data[0].payment_type);
        } else {
          //  navigate("/login");
        }
      } catch (error) {
        console.log(error);
      }
    };
    getAddressEnterprise();
    getRatesAndRoutes();
  }, []);

  const [routesEnterprise, setRoutesEnterprise] = useState([]);
  const getRatesAndRoutes = async (id) => {
    try {
      const dataToken = getToken("dttkn");
      if (dataToken != null) {
        const { enterprise_id } = decodeDataToken(dataToken);
        const resp = await getRatesAndRoutesAddress(enterprise_id);
        setRoutesEnterprise(resp.data.routes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setPriceTotal(0);
    setPriceDriver(0);
    setTax(0);
    if (addressOrigin != null && addressDestination != null) {
      const nameOrigin = addressOrigin.name;
      const nameDestination = addressDestination.name;

      routesEnterprise.map((route, idx) => {
        const checkOriginName = nameOrigin === route.originName;
        const checkDestinationaName = nameDestination === route.destinationName;
        const checkOriRevName = nameOrigin === route.destinationName;
        const checkDestRevName = nameDestination === route.originName;

        if (
          (checkOriginName && checkDestinationaName) ||
          (checkOriRevName && checkDestRevName)
        ) {
          const origin = {
            latitude: route.originLat,
            longitude: route.originLng,
          };
          const destination = {
            latitude: route.destinationLat,
            longitude: route.destinationLng,
          };

          const checkOrigin = distanceBetweenCoordinates(addressOrigin, origin);
          const checkDestination = distanceBetweenCoordinates(
            addressDestination,
            destination
          );

          const checkOriRev = distanceBetweenCoordinates(
            addressOrigin,
            destination
          );
          const checkDestRev = distanceBetweenCoordinates(
            addressDestination,
            origin
          );

          if (
            (checkOrigin && checkDestination) ||
            (checkOriRev && checkDestRev)
          ) {
            if (vehicleSelect == 1) {
              setPriceTotal(Number(route.total));
              setPriceDriver(Number(route.driver));
              setTax(Number(route.tax));
            }
            if (vehicleSelect == 2) {
              setPriceTotal(Number(route.suv_total));
              setPriceDriver(Number(route.suv_driver));
              setTax(Number(route.suv_tax));
            }
            if (vehicleSelect == 3) {
              setPriceTotal(Number(route.mini_total));
              setPriceDriver(Number(route.mini_driver));
              setTax(Number(route.mini_tax));
            }
            if (vehicleSelect == 4) {
              setPriceTotal(Number(route.mini15_total));
              setPriceDriver(Number(route.mini15_driver));
              setTax(Number(route.mini15_tax));
            }
          }
        }
      });
    }
  }, [addressOrigin, addressDestination, vehicleSelect]);

  function distanceBetweenCoordinates(pointA, pointB) {
    const earthRadius = 6371;
    const diffLat = toRadians(pointB.latitude - pointA.latitude);
    const diffLng = toRadians(pointB.longitude - pointA.longitude);

    const a =
      Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
      Math.cos(toRadians(pointA.latitude)) *
        Math.cos(toRadians(pointB.latitude)) *
        Math.sin(diffLng / 2) *
        Math.sin(diffLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance <= 0.3;
  }

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  const [address, setAddress] = useState(
    bookingState?.data ? bookingState?.data.origin.name : ""
  );
  const [coordinates, setCoordinates] = useState({
    lat: bookingState?.data ? bookingState?.data.origin.lat : null,
    lng: bookingState?.data ? bookingState?.data.origin.lng : null,
  });

  const [coordDestination, setCoordDestination] = useState({
    lat: bookingState?.data ? bookingState?.data.destination.lat : null,
    lng: bookingState?.data ? bookingState?.data.destination.lng : null,
  });

  const [serviceList, setServiceList] = useState(
    bookingState?.data ? bookingState?.data.serviceList : []
  );
  const [route1, setRoute1] = useState("");
  const [indice, setIndice] = useState(-1);

  const checkoutSchema = yup.object().shape({
    persons: yup.number().required("required"),
    booking: yup.number().required("required"),
    vehicle: yup.number().required("required"),
    note: yup.string(),
  });

  const initialValues = bookingState?.data
    ? bookingState?.data
    : {
        persons: 1,
        vehicle: "",
        booking: 1,
        note: "",
      };

  const handleServiceAdd = () => {
    if (indice === -1) {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
    } else if (serviceList[indice].service !== "") {
      setServiceList([...serviceList, { service: "" }]);
      setIndice(indice + 1);
      setRoute1("");
    } else {
      window.alert("INGRESE UNA RUTA ANTES DE CREAR OTRO CAMPO");
    }
  };

  const handleServiceRemove = (index) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
    setIndice(indice - 1);
    console.log(indice);
    console.log(serviceList);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const checkTime = (booking) => {
    if (booking != 1) {
      const today = new Date();
      const { day, month, year, hour, minute } = formatDateTime(
        date.$d,
        time.$d
      );

      const dateOrder = new Date(year, month, day, hour, minute, 0);
      const diffInMillisec = dateOrder.getTime() - today.getTime();
      const diffInMin = diffInMillisec / (1000 * 60);

      if (diffInMin <= 30) {
        snackBarDialog("TODA RESERVA DEBE SER MAYOR A 30 MINUTOS", "error");
        return;
      }
      return true;
    }

    return true;
  };

  const handleFormSubmit = async (values) => {
    if (addressOrigin.latitude && addressOrigin.longitude) {
      if (addressDestination.latitude && addressDestination.longitude) {
        const verify = verifyExpireTokenRefresh();
        if (!verify) {
          if (values.booking != 1 && date == null && time == null) {
            snackBarDialog("SELECCIONE LA FECHA Y HORA", "error");
            return;
          }

          if (checkTime(values.booking)) {
            setIsLoading(true);

            const uuid = uuidv4();
            const nDate = new Date(Date.now());

            const data = createDataMySql(values, uuid, nDate);
            const backup = await CreateBackupUseCase(data);

            if (backup.message === "success") {
              const orderNumber = backup.insertId;
              const fData = createDataFirebase(
                values,
                uuid,
                orderNumber,
                nDate
              );
              await createBookingFirebase(fData);
              if (values.booking == 1) {
                await SendNotificationsDriver();
              }
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          }
        } else {
          deleteToken("etknre");
          const origin = {
            name: address,
            lat: coordinates.lat,
            lng: coordinates.lng,
          };
          const destination = {
            name: addressDestination,
            lat: coordDestination.lat,
            lng: coordDestination.lng,
          };
          dispatch(
            addBooking({
              ...values,
              id: uuidv4(),
              origin,
              destination,
              serviceList,
            })
          );
          navigate("/login");
        }
      } else {
        snackBarDialog("Ingrese el Destino del servicio", "error");
      }
    } else {
      snackBarDialog("Ingrese el Origen del servicio", "error");
    }
  };

  const createDataFirebase = (values, uuid, counter, nDate) => {
    const token = getToken("etknre");
    const uid = decodeRefreshToken(token);
    const clientToken = getToken("dttkn");
    const { name, lastname, email, phone, enterprise_id, commertial_name } =
      decodeDataToken(clientToken);

    const data = {
      uuid,
      client: Number(uid),
      clientName: `${name} ${lastname}`,
      clientEmail: email,
      clientPhone: phone,
      enterpriseId: enterprise_id,
      enterpriseName: commertial_name,
      origin: {
        name: addressOrigin.name,
        lat: addressOrigin.latitude,
        lng: addressOrigin.longitude,
        url: addressOrigin.url,
      },
      destination: {
        name: addressDestination.name,
        lat: addressDestination.latitude,
        lng: addressDestination.longitude,
        url: addressDestination.url,
      },
      intermediate: serviceList,
      persons: values.persons,
      vehicle: values.vehicle,
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",
      note: values.note ? values.note.toUpperCase() : "",
      noteAdmin: "",
      currency: 1,
      payment_type: paymentType === "EFECTIVO" ? 1 : 2,
      cotizar:
        values.booking == 4 ? false : paymentType === "EFECTIVO" ? false : true,
      payment_voucher: 1,
      tax: Number(tax),
      toll: 0,
      price: Number(priceDriver),
      total: Number(priceTotal),
      discount: 0,
      advance: 0,
      booking_type: values.booking === 4 ? 2 : values.booking,
      statusService: "PENDIENTE",
      statusPayment: "PENDIENTE",
      statusBooking: "PENDIENTE",
      createBooking: "BUSINESS",
      counterBooking: counter,
      created: Date.now(),
      isCreated: false,
    };

    return data;
  };

  const createDataMySql = (values, uuid, nDate) => {
    const token = getToken("etknre");
    const uid = decodeRefreshToken(token);

    return {
      uuid,
      userType: 0, // 0 para empresas 1 para clientes
      originName: addressOrigin.name,
      originLat: addressOrigin.latitude,
      originLng: addressOrigin.longitude,
      originUrl: "",
      destinationName: addressDestination.name,
      destinationLat: addressDestination.latitude,
      destinationLng: addressDestination.longitude,
      destinationUrl: "",
      date: values.booking === 1 ? dateFormat(nDate) : dateFormat(date.$d),
      time: values.booking === 1 ? timeFormat(nDate) : timeFormat(time.$d),
      dateEnd: values.booking === 3 ? dateFormat(dateEnd.$d) : "",
      timeEnd: values.booking === 3 ? timeFormat(timeEnd.$d) : "",
      persons: values.persons,
      vehicleModel: values.vehicle,
      notes: values.note ? values.note.toUpperCase() : "",
      notesAdmin: "",
      userId: uid,
      driverId: 0,
      vehicleId: 0,
      paymentId: 0,
      bookingType: values.booking == 4 ? 2 : values.booking,
      tourId: 0, //values.booking === 4 ? tour.tour_id : "",
      invoiceId: 0,
      createdBy: "BUSINESS",
      status: 1,
      statePayment: "PENDIENTE",
      assigned: false,
      invoice: false,
      invoiceStatus: false,
      travelStatus: 1,
      currency: 1,
      paymentType: 1,
      paymentVoucher: 1,
      tax: tax,
      toll: 0,
      price: priceDriver,
      total: priceTotal,
      discount: 0,
      advance: 0,
      quote: values.booking == 4 ? false : true,
    };
  };

  const createBookingFirebase = async (data) => {
    const dbRef = `BookingBusiness/${data.uuid}`;

    set(ref(db, dbRef), data)
      .then(async () => {
        snackBarDialog("PEDIDO CREADO, VER SU HISTORIAL");
        try {
          if (data.booking_type === 2) {
            const newBooking = {
              counterBooking: data.counterBooking,
              clientName: data.clientName,
              clientEmail: data.clientEmail,
              clientPhone: data.clientPhone,
              origin: { name: addressOrigin.name },
              destination: { name: addressDestination.name },
              date: dateFormat(date.$d),
              time: timeFormat(time.$d),
              note: data.note ? data.note.toUpperCase() : "",
              persons: data.persons,
              vehicle: data.vehicle,
              web: "Business",
            };
            await sendICSEmail("token", newBooking);
          } else if (data.booking_type === 1) {
            setOpenOffers(true);
            const dataEmail = {
              pedido: data.counterBooking,
              name: data.clientName,
              email: data.clientEmail,
              telefono: data.clientPhone,
              origen: data.origin.name,
              destino: data.destination.name,
              date: data.date,
              time: data.time,
              notas: data.note ? data.note.toUpperCase() : "",
              persons: data.persons,
              vehicle: data.vehicle,
              web: "Business",
            };
            await sendICSEmailNow("token", dataEmail);
          }
        } catch (error) {
          snackBarDialog(
            "PEDIDO CREADO, PERO FALLO EL ENVIO DE EMAIL",
            "error"
          );
        }

        setAddressOrigin(null);
        setAddressDestination(null);
        setServiceList([]);
      })
      .catch((error) => {
        console.log(error);
        snackBarDialog("Error: No se creo el Pedido", "error");
      });
  };

  const [openOffers, setOpenOffers] = useState(false);
  const [offersList, setOfferList] = useState([]);
  const handleCloseOffer = () => setOpenOffers(false);

  useEffect(() => {
    if (uidBooking != "") {
      const cliToken = getToken("etknre");
      const uid = decodeRefreshToken(cliToken);
      const unsubscribe = CounterOfferObserver(uid, uidBooking, (offers) => {
        if (offers.length > 0) {
          setOfferList(offers);
        } else setOfferList([]);
      });
      return () => {
        if (unsubscribe) unsubscribe();
      };
    }
  }, [uidBooking]);

  useEffect(() => {
    const readBooking = async () => {
      const cliToken = getToken("etknre");
      const uid = decodeRefreshToken(cliToken);
      const unsubscribe = BookingObserver(uid, (offerList) => {
        if (offerList.length > 0) {
          setBookingNow(offerList[0]);
          console.log(offerList[0]);
          setIsBookingActive(true);
          setUidBooking(offerList[0].uuid);
          const driver = getDriverStore();

          if (driver != null) setDriverSelect(driver);
        } else {
          handleCloseOffer();
          setIsBookingActive(false);
          removeDriverStore();
        }
      });

      return () => {
        if (unsubscribe) unsubscribe();
      };
    };
    readBooking();
  }, []);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "90vh",
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 1,
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const acceptCounterOffer = async (offer) => {
    const idBooking = offer.idBooking;
    const idDriver = offer.idDriver;
    const idRider = offer.idRider;
    const price = offer.priceOffer;

    const driver = {
      driverName: offer.nameDriver,
      driverPhone: offer.phoneDriver,
      vehicleId: offer.vehicleId,
      brand: offer.brand,
      model: offer.model,
      bodywork: offer.bodywork,
      plate: offer.plate,
      color: offer.color,
      time: offer.timeArrived,
    };
    saveDriverStore(driver);
    setDriverSelect(driver);
    await UpdateCounterOffer(idBooking, idDriver, price);
    await SendNotificationDriver(idDriver);
    await DeleteAllCounterOffers(idRider, idBooking);
  };

  const cancelBooking = (idBooking) => {
    DeleteBooking(idBooking);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box mb={3} sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Booking-Tabs"
              centered
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                label={t("Bookings")}
                {...a11yProps(0)}
                sx={{ color: "#000000", fontSize: 18 }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box onSubmit={handleSubmit} sx={{ mt: 1, margin: "5px" }}>
                    <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
                      <Grid item xs={12} sm={6}>
                        {addressApi ? (
                          <Autocomplete
                            value={addressOrigin}
                            disablePortal
                            name="addressOrigin"
                            id="combo-box-origin"
                            onChange={(e, newValue) => {
                              setAddressOrigin(newValue);
                            }}
                            options={addressApi}
                            getOptionLabel={(option) => `${option.name}`}
                            renderInput={(params) => (
                              <TextField {...params} label={t("origin")} />
                            )}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            disabled
                            type="text"
                            label={t("Lista de Direcciones")}
                          />
                        )}
                      </Grid>

                      {serviceList.map((singleService, index) => (
                        <Grid key={index} item xs={12} sm={6}>
                          {addressApi ? (
                            <Autocomplete
                              value={addressOrigin}
                              disablePortal
                              name="client"
                              id="combo-box-destination"
                              onChange={(e, newValue) => {
                                setAddressOrigin(newValue);
                              }}
                              options={addressApi}
                              getOptionLabel={(option) =>
                                `${option.name} ${option.url_address}`
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={t("RUTA INTERMEDIA")}
                                />
                              )}
                            />
                          ) : (
                            <TextField
                              fullWidth
                              disabled
                              type="text"
                              label={t("RUTA INTERMEDIA")}
                            />
                          )}
                        </Grid>
                      ))}

                      <Grid item xs={12} sm={6}>
                        {addressApi ? (
                          <Autocomplete
                            value={addressDestination}
                            disablePortal
                            name="addressDestination"
                            id="combo-box-destination"
                            onChange={(e, newValue) => {
                              setAddressDestination(newValue);
                            }}
                            options={addressApi}
                            getOptionLabel={(option) =>
                              `${option.name} ${option.url_address}`
                            }
                            renderInput={(params) => (
                              <TextField {...params} label={t("destination")} />
                            )}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            disabled
                            type="text"
                            label={t("Lista de Direcciones")}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          value={values.persons}
                          fullWidth
                          type="number"
                          label={t("persons")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="persons"
                          error={!!touched.persons && !!errors.persons}
                          helperText={touched.persons && errors.persons}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel>{t("vehicle")}</InputLabel>
                          <Select
                            value={values.vehicle}
                            id="vehicle"
                            name="vehicle"
                            label={t("vehicle")}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setVehicleSelect(e.target.value);
                            }}
                            error={!!touched.vehicle && !!errors.vehicle}
                          >
                            {model.map((model, index) => (
                              <MenuItem key={index} value={model.id}>
                                {t(model.name)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={values.booking === 3 ? 4 : 6}
                      >
                        <FormControl fullWidth>
                          <InputLabel>{t("Solicitar")}</InputLabel>
                          <Select
                            id="booking"
                            value={values.booking}
                            name="booking"
                            label={"Solicitar"}
                            onChange={handleChange}
                          >
                            <MenuItem value={1}>{t("now")}</MenuItem>
                            <MenuItem value={2}>
                              {t("later")}(reservar)
                            </MenuItem>
                            <MenuItem value={3}>{t("perHours")}</MenuItem>
                            <MenuItem value={4}>Cotizar</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {values.booking != 1 && (
                        <Grid item xs={6} sm={values.booking === 3 ? 2 : 3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={2}>
                              <MobileDatePicker
                                label={
                                  values.booking === 3
                                    ? t("startDate")
                                    : t("date")
                                }
                                inputFormat="DD/MM/YYYY"
                                name="date"
                                value={date}
                                onChange={(newValue) => {
                                  setDate(newValue);
                                  setDateEnd(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                      )}
                      {values.booking != 1 && (
                        <Grid item xs={6} sm={values.booking === 3 ? 2 : 3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <MobileTimePicker
                                label={
                                  values.booking === 3
                                    ? t("startTime")
                                    : t("time")
                                }
                                value={time}
                                name="time"
                                onChange={(newValue) => {
                                  setTime(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Grid>
                      )}

                      <Grid
                        item
                        xs={6}
                        sm={2}
                        sx={displayBox(values.booking, 3, 3)}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={2}>
                            <MobileDatePicker
                              label={t("endDate")}
                              inputFormat="DD/MM/YYYY"
                              name="dateEnd"
                              value={dateEnd}
                              onChange={(newValue) => {
                                setDateEnd(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={2}
                        sx={{
                          display: {
                            xs: values.booking === 3 ? "block" : "none",
                            md: values.booking === 3 ? "block" : "none",
                          },
                        }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <MobileTimePicker
                              label={t("endTime")}
                              value={timeEnd}
                              name="timeEnd"
                              onChange={(newValue) => {
                                setTimeEnd(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          value={values.note}
                          fullWidth
                          type="text"
                          label={t("notes")}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="note"
                          error={!!touched.note && !!errors.note}
                          helperText={touched.note && errors.note}
                          multiline
                          inputProps={{
                            maxLength: 200,
                            maxRows: 4,
                            style: { textTransform: "uppercase" },
                          }}
                        />
                      </Grid>

                      <Grid item xs={isBookingActive ? 11 : 12}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          variant="contained"
                          sx={{ height: 45 }}
                          disabled={isLoading}
                        >
                          <Typography variant="string">
                            {values.booking === 1
                              ? t("booknow")
                              : t("booklater")}
                          </Typography>
                        </Button>
                      </Grid>

                      {isBookingActive && (
                        <Grid item xs={1}>
                          <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                            sx={{
                              height: 45,
                              display: "flex",
                              justifyContent: "space-between",
                              padding: 0,
                            }}
                            disabled={isLoading}
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenOffers(true);
                            }}
                          >
                            <p
                              className={loadingStyle.loader}
                              style={{ height: 30, width: 30, margin: "auto" }}
                            />

                            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                              {offersList.length}
                            </p>
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <SnackBar
                    stateSnk={stateSnk}
                    vertical={vertical}
                    horizontal={horizontal}
                    duration={4000}
                    handleClose={handleSnkClose}
                    severity={severity}
                    messageSnk={messageSnk}
                  />
                </form>
              )}
            </Formik>
          </TabPanel>

          <Modal open={openOffers} onClose={handleCloseOffer}>
            <Box
              style={{
                ...modalStyle,
                background: "white",
                overflowY: "auto",
              }}
              sx={{
                width: {
                  xs: "95%",
                  sm: "500px",
                },
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: "bold",
                  padding: 3,
                }}
              >
                MI PEDIDO
              </p>
              <p
                style={{
                  position: "absolute",
                  top: 5,
                  right: 3,
                  background: "red",
                  borderRadius: "10px",
                  margin: "0px 5px 0px 0px",
                  textAlign: "center",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "14px",
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
                onClick={handleCloseOffer}
              >
                X
              </p>
              <div
                style={{
                  background: `${bookingNow?.idDriver ? "#bfeaaa" : "#d8dcd6"}`,
                  border: "2px solid black",
                  borderRadius: "5px",
                  width: "95%",
                  margin: "auto",
                  padding: 2,
                  overflowX: "auto",
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <p style={{ textAlign: "center" }}>
                      <span style={{ fontWeight: "bold" }}>PEDIDO:</span>
                      {bookingNow?.counterBooking}
                    </p>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {bookingNow?.idDriver && (
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          PEDIDO ACEPTADO
                        </span>
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {bookingNow?.idDriver && (
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          CONDUCTOR LLEGARA EN:
                        </span>
                        {driverSelect?.time}
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {bookingNow?.idDriver ? (
                      <div>
                        <span style={{ fontWeight: "bold" }}>CONDUCTOR:</span>
                        {driverSelect?.driverName}
                      </div>
                    ) : (
                      <>CONDUCTOR: ...</>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {bookingNow?.idDriver ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "15px",
                        }}
                      >
                        <p>
                          <span style={{ fontWeight: "bold" }}>PLACA:</span>
                          {driverSelect?.plate}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>MARCA:</span>
                          {driverSelect?.bodywork}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>COLOR:</span>
                          {driverSelect?.color}
                        </p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <p>
                      <span style={{ fontWeight: "bold" }}>TEL:</span>{" "}
                      {driverSelect?.driverPhone}
                    </p>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <p>
                      <span style={{ fontWeight: "bold" }}>FECHA:</span>{" "}
                      {bookingNow?.date}
                    </p>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <p>
                      <span style={{ fontWeight: "bold" }}>HORA:</span>{" "}
                      {bookingNow?.time}
                    </p>
                  </Grid>
                  {bookingNow?.idDriver ? (
                    <></>
                  ) : (
                    <Grid item xs={12} sm={12} textAlign={"end"} padding={1}>
                      <button
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          background: "red",
                          padding: "5px",
                          color: "white",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          cancelBooking(bookingNow.uuid);
                        }}
                      >
                        CANCELAR
                      </button>
                    </Grid>
                  )}
                </Grid>
              </div>

              <Typography
                style={{
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: "bold",
                  padding: 3,
                }}
              >
                BUSCANDO CONDUCTORES CERCANOS
              </Typography>

              <Grid container spacing={1}>
                {offersList.length > 0 ? (
                  offersList.map((offer, idx) => {
                    return (
                      <Grid key={idx} item xs={12}>
                        <div
                          style={{
                            background: "#d8dcd6",
                            border: "2px solid black",
                            borderRadius: "5px",
                            width: "90%",
                            margin: "auto",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "4px",
                            }}
                          >
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                PRECIO:
                              </span>{" "}
                              {offer.priceOffer}
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                TIEMPO:
                              </span>{" "}
                              {offer.timeArrived}
                            </p>
                          </div>
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              CONDUCTOR:
                            </span>
                            {offer.nameDriver}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "4px",
                              fontSize: "15px",
                            }}
                          >
                            <p>
                              <span style={{ fontWeight: "bold" }}>PLACA:</span>
                              {offer.plate}
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>MARCA:</span>
                              {offer.bodywork}
                            </p>
                            <p>
                              <span style={{ fontWeight: "bold" }}>COLOR:</span>
                              {offer.color}
                            </p>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <button
                              style={{
                                border: "none",
                                borderRadius: "5px",
                                background: "green",
                                padding: "5px",
                                color: "white",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                acceptCounterOffer(offer);
                              }}
                            >
                              ACEPTAR
                            </button>
                          </div>
                        </div>
                      </Grid>
                    );
                  })
                ) : (
                  <div style={{ width: "100%" }}>
                    <p
                      className={loadingStyle.loader}
                      style={{
                        height: 50,
                        width: 50,
                        background: "red",
                        margin: "auto",
                      }}
                    />
                    <p style={{ textAlign: "center", margin: "auto" }}>
                      ELIGE UNO DE LOS CONDUCTORES
                    </p>
                  </div>
                )}
              </Grid>

              <Button
                fullWidth
                size="small"
                color="info"
                variant="contained"
                sx={{ fontWeight: "bold", background: "#128C7E" }}
                href={`https://api.whatsapp.com/send?phone=+51958200900&text=*Pedido:* ${bookingNow?.counterBooking},%0A *Cliente:* ${bookingNow?.clientName},%0A *Origen:* ${bookingNow?.origin?.name}, *Destino:* ${bookingNow?.destination?.name} `}
                target={"_blank"}
              >
                Consultar al 958200900 &nbsp;
                <WhatsAppIcon />
              </Button>
            </Box>
          </Modal>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Booking;
