import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000000";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 20,
    fontStyle: "bold",
    
  },
  index: {
    width: "5%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingLeft: 8,
  },
  fullname: {
    width: "65%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft:2
  },
  dni: {
    width: "20%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 8,
  },
  firm: {
    width: "10%",
    textAlign: "right",
    paddingRight: 8,
  },
});

const TableRowOccurrences = ({ items }) => {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.sno.toString()}>
      <Text style={styles.index}>{item.sno}</Text>
      <Text style={styles.fullname}>{item.desc}</Text>
      <Text style={styles.dni}>{item.qty}</Text>
      <Text style={styles.firm}>{item.qty}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRowOccurrences;