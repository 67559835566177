import { REGEX_PHONE } from "../../../helpers/regex";

export const rules = (yup) => {
  return {
    commertial_name: yup.string().required("required"),
    business_name: yup.string().required("required"),
    business_email: yup.string().email("invalid email").required("required"),
    business_address: yup.string().required("required"),
    business_frequency: yup.string().required("required"),
    employee_name: yup.string().required("required"),
    employee_lastName: yup.string().required("required"),
    employee_password: yup.string().required("required"),
  };
};

export const initial = {
  business_ruc: "",
  commertial_name: "",
  business_name: "",
  business_address: "",
  business_email: "",
  business_category: "",
  business_category_other: "",
  business_budget: "",
  business_frequency: "",
  employee_dni: "",
  employee_name: "",
  employee_lastName: "",
  employee_password: "",
  employee_genre: "",
};
