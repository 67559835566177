import { baseBackend } from "../base/baseUrl";
import { URL_EMPLOYEE } from "../../helpers/endpoint";

export const createdEnterpriseAndEmployee = async (enterprise, employee) => {
  return await baseBackend.post(
    `${URL_EMPLOYEE}/business`,
    JSON.stringify({
      enterprise,
      employee,
    }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const createdEmployee = async (token, employee) => {
  return await baseBackend.post(URL_EMPLOYEE, JSON.stringify({ employee }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmployeesFromEnterprise = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_EMPLOYEE}s/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
//axios.delete(url, { data: { hello: "world" }, headers: { "Authorization": "Bearer_token_here" } });
export const deletedEmployee = async (token, employee_id, status) => {
  return await baseBackend.delete(`${URL_EMPLOYEE}/${employee_id}`, {
    data: { status },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmployeeById = async (token, id) => {
  return await baseBackend.get(`${URL_EMPLOYEE}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateEmployee = async (token, employee) => {
  return await baseBackend.put(URL_EMPLOYEE, JSON.stringify({ employee }), {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

const URL_SUB_ROLE = "/api/v1/subrole";
export const getSubroles = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_SUB_ROLE}/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
// export const updateClient = async (
//   token,
//   name,
//   lastname,
//   phone,
//   address,
//   genres_id
// ) => {
//   return await baseBackend.put(
//     URL_CLIENT,
//     JSON.stringify({
//       name,
//       lastname,
//       phone,
//       address,
//       genres_id,
//     }),
//     {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };
