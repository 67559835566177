import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";

import logo from "../../assets/logo-main.png";
import Translation from "../translation/Translation";
import "./topMenu.css";
import HeaderProfileTab from "./headerProfile/HeaderProfileTab";
import {
  decodeDataToken,
  deleteToken,
  getToken,
  verifyExpireTokenRefresh,
} from "../../helpers/token/refreshToken";

const pages = ["home", "about", "contact"];

function TopMenu() {
  const [user, setUser] = useState(false);
  const [fullName, setFullName] = useState("");
  const [subrole, setSubrole] = useState("");
  const [commertialName, setCommertialName] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);

  useEffect(() => {
    const token = getToken("etknre");
    const tokenData = getToken("dttkn");
    if (token) {
      if (tokenData) setUser(true);
      else deleteToken("etknre");
    }
  }, []);

  useEffect(() => {
    if (user) {
      const verifyTkn = verifyExpireTokenRefresh();

      if (verifyTkn) {
        deleteToken("etknre");
        deleteToken("dttkn");
        navigate("/");
      }

      const dataToken = getToken("dttkn");
      const { name, lastname, sub_role, commertial_name } =
        decodeDataToken(dataToken);
      setFullName(`${name} ${lastname}`);
      setSubrole(sub_role);
      setCommertialName(commertial_name);
    }
  }, [user]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const renderBoxLogin = () => {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Button color="secondary" variant="contained" onClick={handleLogin}>
          {t("login")}
        </Button>
      </Box>
    );
  };

  return (
    <AppBar position="static" enableColorOnDark sx={{ background: "#191975" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <img src={logo} alt="" width="100" height="50" />
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{t(page)}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <Link to={`/${page}`} className="link">
                  {t(page)}
                </Link>
              </Button>
            ))}
          </Box>

          <Translation backgroundColor={"#4d4f4f"} />
          {user ? (
            <HeaderProfileTab
              fullName={fullName}
              subrole={subrole}
              commertialName={commertialName}
            />
          ) : (
            renderBoxLogin()
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopMenu;
