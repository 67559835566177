import { Box, Button, Grid, Modal, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CostCenterTable from "./CostCenterTable";
import { tokens } from "../../../../../../theme";
import SnackBar from "../../../../../../components/snackbar/SnackBar";
import { modalStyle } from "../style/ModalStyle";

const CostCenterList = ({ data, columns }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UID, setUID] = useState("");
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };
  const handleClose = () => setOpen(false);

  const handleAddressDelete = async () => {
    try {
    } catch (error) {}
  };

  return (
    <>
      {/* <main className="content"> */}
      <Box>
        <CostCenterTable data={data} columns={columns} />
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
          <Typography variant="h6" component="h2">
            ELIMINAR ROL?
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} md={6} textAlign="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleAddressDelete}
                disabled={isLoading}
              >
                ELIMINAR
              </Button>
            </Grid>
            <Grid item xs={6} md={6} textAlign="center">
              <Button
                color="error"
                variant="contained"
                onClick={handleClose}
                disabled={isLoading}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={5000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
      {/* </main> */}
    </>
  );
};

export default CostCenterList;