import React, { Fragment, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import Title from "./elements/Title";
import Number from "./elements/Number";
import { data } from "./elements/Data";
import BillTo from "./elements/BillTo";
import MessageFooter from "./elements/MessageFooter";
import ItemsTable from "./elements/ItemsTable";
import DocumentName from "./elements/DocumentName";
import TableCustomers from "./elements/TableCustomers";

const ReportManifest = () => {
  const [tableData, setTableData] = useState();
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });
  return (
    <PDFViewer width="1000" height="600" className="app">
      <Document>
        <Page size="A4" style={styles.page}>
          <Title title="Taxi Mundo PERU" />
          <BillTo invoice={data} />
          <DocumentName title={"MANIFESTO DE PASAJEROS"} number={100} />
          <Number invoice={data} />
          <TableCustomers invoice={data} />
          {/* <MessageFooter /> */}
        </Page>
      </Document>
    </PDFViewer>
  );
};
//<PDFViewer>
//</PDFViewer>
export default ReportManifest;
