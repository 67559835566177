export const employeeSchema = (yup) => {
  return {
    dni: yup.string().required("required"),
    name: yup.string().required("required"),
    lastname: yup.string().required("required"),
    phone: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
  };
};

export const employeeInitial = {
  dni: "",
  name: "",
  lastname: "",
  phone: "+51 ",
  email: "",
};