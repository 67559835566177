import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#000000";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000000",
    backgroundColor: "#4B44BE",
    borderBottomWidth: 1,
    alignItems: "center",
    alignContent: "center",
    textAlign: "center",
    height: 20,
    flexGrow: 1,
    padding:1
  },
  index: {
    width: "5%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  fullname: {
    width: "65%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
  time: {
    width: "10%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    fontFamily: "Helvetica-Bold",
    color: "white",
  },
  firm: {
    width: "10%",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },
});

const Hora = () => {
  const styles = StyleSheet.create({
    column: {
      flexDirection: "column",
      borderRightColor: borderColor,
      borderRightWidth: 1,
      alignItems: "center",
      height: "100%",
      width: "20%",
      color: "white",
      fontFamily: "Helvetica-Bold",
    },
    row: {
      flexDirection: "row",
    },
    start: {
      borderRightColor: borderColor,
      borderRightWidth: 1,
      width: "50%",
      fontSize:8
    },
    end: {
      width: "50%",
      fontSize:8
    },
  });

  return (
    <View style={styles.column}>
      <Text>HORA</Text>
      <View style={styles.row}>
        <Text style={styles.start}>INICIO</Text>
        <Text style={styles.end}>FIN</Text>
      </View>
    </View>
  );
};

const TableHeaderDriving = () => (
  <View style={styles.container}>
    <Text style={styles.index}>No</Text>
    <Text style={styles.fullname}>NOMBRES Y APELLIDOS DEL CONDUCTOR</Text>
    <Hora style={styles.time} />
    {/* <Text style={styles.time}>H. INICIO </Text>
    <Text style={styles.time}>H. FIN</Text> */}
    <Text style={styles.firm}>FIRMA</Text>
  </View>
);

export default TableHeaderDriving;
