import { URL_ENTERPRISE } from "../../../../helpers/endpoint";
import { baseBackend } from "../../../base/baseUrl";

export const getEnterpriseData = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_ENTERPRISE}/edit/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
