export const data = {
  id: "5df3180a09ea16dc4b95f910",
  invoice_no: "201906-28",
  balance: "$2,283.74",
  company: "TAXIMUNDO",
  email: "info@taximundo.com",
  phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
  address: "Servicios Generales Trans Mundo E.I.R.L.",
  trans_date: "2019-09-12",
  due_date: "2019-10-12",
  items: [
    {
      sno: 100000,
      date: "02/01/2023",
      desc: "ad sunt culpa occaecat qui",
      qty: 100.59,
      rate: 405.89,
    },
    {
      sno: 2,
      date: "02/01/2023",
      desc: "cillum quis sunt qui aute",
      qty: 100.59,
      rate: 373.11,
    },
    {
      sno: 3,
      date: "02/01/2023",
      desc: "ea commodo labore culpa irure",
      qty: 5,
      rate: 458.61,
    },
    {
      sno: 4,
      date: "02/01/2023",
      desc: "nisi consequat et adipisicing dolor nisi consequat et adipisicing dolor",
      qty: 10,
      rate: 725.24,
    },
    {
      sno: 5,
      date: "02/01/2023",
      desc: "proident cillum anim elit esse",
      qty: 4,
      rate: 141.02,
    },
  ],
};
