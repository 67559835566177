import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { convertDate } from "../../../../../../helpers/date/convertDate";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    height: 16,
    fontStyle: "bold",
  },
  index: {
    width: "5%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // paddingLeft: 1,
    fontSize: 7,
  },
  fulldate: {
    width: "6%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  fullname: {
    width: "15%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  origin: {
    width: "22%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  destination: {
    width: "22%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  note: {
    width: "19%",
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "left",
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  toll: {
    width: "5%",
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 4,
    fontSize: 8,
  },
  price: {
    width: "7%",
    height: "100%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 6,
    fontSize: 8,
  },
});

const stylesHour = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    height: 44,
    fontStyle: "bold",
  },
  index: {
    width: "5%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // paddingLeft: 1,
    fontSize: 7,
  },
  fulldate: {
    width: "6%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 7,
    marginLeft: 2,
    fontSize: 6.5,
  },
  fullname: {
    width: "15%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  origin: {
    width: "62%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  toll: {
    width: "5%",
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 4,
    fontSize: 8,
  },
  price: {
    width: "7%",
    height: "100%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 6,
    fontSize: 8,
  },
});

const TableRowH = ({ items }) => {
  const compareByDate = (a, b) => {
    const dateB = convertDate(b.date, a.time);
    const dateA = convertDate(a.date, a.time);
    return dateB - dateA;
  };
  const Br = () => "\n";
  const rows = items.sort(compareByDate).map((item) =>
    !item.dateEnd ? (
      <View wrap={false} style={styles.row} key={item.order_number.toString()}>
        <Text style={styles.index}>{item.order_number}</Text>
        <Text style={styles.fulldate}>{item.date}</Text>
        <Text style={styles.fullname}>{item.fullname_employee}</Text>
        <Text style={styles.origin}>{item.origin_name}</Text>
        <Text style={styles.destination}>{item.destination_name}</Text>
        <Text style={styles.note}>{item.notes}</Text>
        <Text style={styles.toll}>{item.toll}</Text>
        <Text style={styles.price}>{item.price_total}</Text>
      </View>
    ) : (
      <View
        wrap={false}
        style={stylesHour.row}
        key={item.order_number.toString()}
      >
        <Text style={stylesHour.index}>{item.order_number}</Text>
        <Text style={stylesHour.fulldate}>
          {item.date} {item.time}
          <Br />
          {item.dateEnd} {item.timeEnd}
        </Text>
        <Text style={stylesHour.fullname}>{item.fullname_employee}</Text>
        <Text style={stylesHour.origin}>
          ORIGEN: {item.origin_name}
          <Br />
          {item.notes}
          <Br />
          DESTINO: {item.destination_name}
        </Text>
        <Text style={stylesHour.toll}>{item.toll}</Text>
        <Text style={stylesHour.price}>{item.price_total}</Text>
      </View>
    )
  );

  return <Fragment>{rows}</Fragment>;
};

export default TableRowH;
