import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import Topbar from "../../../../../components/topbar/TopBar";
import Header from "../../../../../components/header/Header";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from "../../../../../theme";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import CostCenterList from "./components/CostCenterList";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CreateCostCenter } from "../usecases/CreateCostCenter";
import {
  decodeDataToken,
  getToken,
} from "../../../../../helpers/token/refreshToken";
import { CreateSede } from "../usecases/CreateSede";
import { ReadCostCenter } from "../usecases/ReadCostCenter";
import { ReadEnterpriseSede } from "../usecases/ReadEnterpriseSede";
import { UpdateSede } from "../usecases/UpdateSede";
import { UpdateCostCenter } from "../usecases/UpdateCostCenter";
import { DeleteSede } from "../usecases/DeleteSede";
import { DeleteCostCenter } from "../usecases/DeleteCostCenter";
import { modalStyle } from "./style/ModalStyle";

const CostCenter = () => {
  const location = useLocation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const costSchema = yup.object().shape({
    costCenter: yup.string().required("obligatorio"),
  });
  const sedeSchema = yup.object().shape({
    sede: yup.string().required("obligatorio"),
  });
  const costValues = { costCenter: "" };
  const sedeValues = { sede: "" };

  const [UID, setUID] = useState("");
  const [listCostCenter, setListCostCenter] = useState([]);
  const [listSede, setListSede] = useState([]);

  useEffect(() => {
    const getListCostCenter = async () => {
      try {
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await ReadCostCenter(enterprise_id);
        if (response.message === "success") {
          setListCostCenter(response.costcenter);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getListCostCenter();
  }, []);

  useEffect(() => {
    const getListSede = async () => {
      try {
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await ReadEnterpriseSede(enterprise_id);
        console.log(response);
        if (response.message == "success") {
          setListSede(response.sede);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getListSede();
  }, []);

  const costColumns = [
    {
      headerName: "NOMBRE CENTRO DE COSTOS",
      field: "name",
      type: "string",
      headerAlign: "center",
      align: "left",
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id, name } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(id);
                setNameEdit(name);
                setOpenUpdate(true);
                handleSelectCost();
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setOpenDelete(true);
                handleSelectCost();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const sedeColumns = [
    {
      headerName: "SEDES",
      field: "name",
      type: "string",
      headerAlign: "center",
      align: "left",
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id, name } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                setUID(id);
                setNameEdit(name);
                setOpenUpdate(true);
                handleSelectSede();
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setOpenDelete(true);
                handleSelectSede();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const createCostCenter = async (values) => {
    try {
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const costCenter = {
        name: values.costCenter,
        enterpriseId: enterprise_id,
      };
      const response = await CreateCostCenter(costCenter);
      if (response.message == "success")
        snackBarDialog("CENTRO DE COSTO CREADO ");
      else snackBarDialog("ERROR AL CREAR EL CENTRO DE COSTO", "error");
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR DEL SERVIDOR", "error");
    }
  };
  const createSede = async (values) => {
    try {
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const sede = {
        name: values.sede,
        enterpriseId: enterprise_id,
      };
      const response = await CreateSede(sede);
      if (response.message == "success") snackBarDialog("SEDE CREADO ");
      else snackBarDialog("ERROR AL CREAR LA SEDE", "error");
    } catch (error) {
      console.log(error);
    }
  };

  const deleteResource = async () => {
    try {
      if (selectOption) {
        console.log("delete sede");
        const response = await DeleteSede(UID);
        handleDeleteClose();
        snackBarDialog("ELIMINACION EXITOSA");
      } else {
        console.log("delete cost");
        const response = await DeleteCostCenter(UID);
        handleDeleteClose();
        snackBarDialog("ELIMINACION EXITOSA");
      }
      //here
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE LOGRO ELIMINAR", "error");
    }
  };

  const [nameEdit, setNameEdit] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectOption, setSelectOption] = useState(false);
  const handleDeleteClose = () => setOpenDelete(false);
  const handleUpdateClose = () => setOpenUpdate(false);
  const handleSelectSede = () => setSelectOption(true);
  const handleSelectCost = () => setSelectOption(false);

  const updateResource = async () => {
    try {
      if (selectOption) {
        console.log("update sede");
        const sedeUpdate = {
          id: UID,
          name: nameEdit,
        };
        const response = await UpdateSede(sedeUpdate);
        handleUpdateClose();
        snackBarDialog("ACTUALIZACION REALIZADA");
      } else {
        console.log("update cost");
        const costUpdate = {
          id: UID,
          name: nameEdit,
        };
        const response = await UpdateCostCenter(costUpdate);
        handleUpdateClose();
        snackBarDialog("ACTUALIZACION REALIZADA");
      }
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO SE LOGRO ACTUALIZAR", "error");
    }
  };

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={"AGREGAR CENTRO DE COSTO"} subtitle="" />
              <Box></Box>
            </Box>
            <Box display="grid" gap="10px">
              <Formik
                onSubmit={createCostCenter}
                initialValues={costValues}
                validationSchema={costSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box backgroundColor={colors.primary[400]} p={2}>
                      <Grid
                        container
                        spacing={1.5}
                        sx={{ alignItems: "center" }}
                      >
                        <Grid item xs={12} sm={8}>
                          <TextField
                            value={values.costCenter}
                            fullWidth
                            type="text"
                            label={"CENTRO DE COSTOS"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="costCenter"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.costCenter && !!errors.costCenter}
                            helperText={touched.costCenter && errors.costCenter}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} textAlign={"center"}>
                          <Button
                            fullWidth
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{ height: 45 }}
                          >
                            {"AGREGAR"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                )}
              </Formik>

              <Formik
                onSubmit={createSede}
                initialValues={sedeValues}
                validationSchema={sedeSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box backgroundColor={colors.primary[400]} p={2}>
                      <Grid
                        container
                        spacing={1.5}
                        sx={{ alignItems: "center" }}
                      >
                        <Grid item xs={12} sm={8}>
                          <TextField
                            value={values.sede}
                            fullWidth
                            type="text"
                            label={"SEDE"}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="sede"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            error={!!touched.sede && !!errors.sede}
                            helperText={touched.sede && errors.sede}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4} textAlign={"center"}>
                          <Button
                            fullWidth
                            type="submit"
                            color="secondary"
                            variant="contained"
                            sx={{ height: 45 }}
                          >
                            {"AGREGAR"}
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                )}
              </Formik>

              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} padding={1}>
                  <CostCenterList data={listCostCenter} columns={costColumns} />
                </Grid>
                <Grid item xs={12} sm={6} padding={1}>
                  <CostCenterList data={listSede} columns={sedeColumns} />
                </Grid>
              </Grid>

              <Modal open={openUpdate} onClose={handleUpdateClose}>
                <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                  <Typography variant="h6" component="h2" textAlign={"center"}>
                    ACTUALIZAR {selectOption ? "SEDE" : "CENTRO DE COSTOS"}
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        value={nameEdit}
                        name="nameEdit"
                        label="NOMBRE"
                        onChange={(e) => setNameEdit(e.target.value)}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={6} textAlign="center">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={updateResource}
                        disabled={isLoading}
                      >
                        ACTUALIZAR
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={6} textAlign="center">
                      <Button
                        color="error"
                        variant="contained"
                        onClick={handleUpdateClose}
                        disabled={isLoading}
                      >
                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <Modal open={openDelete} onClose={handleDeleteClose}>
                <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
                  <Typography variant="h6" component="h2">
                    DESEA ELIMINAR?
                  </Typography>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={6} md={6} textAlign="center">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={deleteResource}
                        disabled={isLoading}
                      >
                        ELIMINAR
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={6} textAlign="center">
                      <Button
                        color="error"
                        variant="contained"
                        onClick={handleDeleteClose}
                        disabled={isLoading}
                      >
                        CANCELAR
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>

              <SnackBar
                stateSnk={stateSnk}
                vertical={vertical}
                horizontal={horizontal}
                duration={5000}
                handleClose={handleSnkClose}
                severity={severity}
                messageSnk={messageSnk}
              />
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default CostCenter;
