import { Box, CssBaseline } from "@mui/material";
import RoleForm from "../../../../components/form/role/RoleForm";
import Header from "../../../../components/header/Header";
import Topbar from "../../../../components/topbar/TopBar";
import {
  decodeDataToken,
  getToken,
} from "../../../../helpers/token/refreshToken";
import RoleList from "./RoleList";

const AddRole = () => {
  const token = getToken("etknre");
  const dataToken = getToken("dttkn");
  const { enterprise_id } = decodeDataToken(dataToken);

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="AGREGAR ROL" subtitle="" />
              <Box></Box>
            </Box>
            <Box display="grid" gap="10px">
              <Box gridColumn="span 12" gridRow="span 2">
                <RoleForm token={token} enterprise_id={enterprise_id} />
                <RoleList token={token} enterprise_id={enterprise_id} />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default AddRole;
