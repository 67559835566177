import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 0,
  },
});

const EnterpriseName = ({ enterprise, name, lastname }) => (
  <View style={styles.headerContainer}>
    <Text>
      <Text style={{ fontFamily: "Helvetica-Bold" }}>Empresa:</Text>
      {enterprise}
    </Text>
    <Text>
      <Text style={{ fontFamily: "Helvetica-Bold" }}>Encargado: </Text>
      {lastname} {name}
    </Text>
  </View>
);

export default EnterpriseName;
