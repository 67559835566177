import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { useNavigate } from "react-router-dom";
import { Typography, useTheme, MenuList } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";
import { IconButton } from "@mui/material";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { decodeDataToken, getToken } from "../../helpers/token/refreshToken";
import { useTranslation } from "react-i18next";

const DrawerUI = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [state, setState] = React.useState({ left: false });
  const navigate = useNavigate();
  const dataToken = getToken("dttkn");
  const { name, lastname } = decodeDataToken(dataToken);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, ["left"]: open });
  };

  const redirectTo = (to) => (event) => {
    navigate(to);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(0),
    borderTop: "0px solid rgba(0, 0, 0, .125)",
  }));

  const listMain = [
    {
      title: t("team").toUpperCase(),
      subList: [
        { title: t("add").toUpperCase(), url: "/business/add-employee" },
        { title: "LISTA", url: "/business/list-employee" },
      ],
    },
    {
      title: t("addresses").toUpperCase(),
      subList: [
        { title: t("addresses").toUpperCase(), url: "/business/add-address" },
      ],
    },
    {
      title: "ROLES/C.COSTOS",
      subList: [
        { title: t("role").toUpperCase(), url: "/business/add-role" },
        {
          title: t("C. COSTOS/SEDE").toUpperCase(),
          url: "/business/costos",
        },
      ],
    },
    {
      title: t("quote").toUpperCase(),
      subList: [
        { title: t("quote").toUpperCase(), url: "/business/cotizacion" },
      ],
    },
    {
      title: t("reports").toUpperCase(),
      subList: [
        { title: t("reports").toUpperCase(), url: "/business/reports" },
      ],
    },
    {
      title: t("facturas").toUpperCase(),
      subList: [
        { title: t("facturas").toUpperCase(), url: "/business/facturas" },
      ],
    },
  ];

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <Box mt={2} mb={1}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            alt="profile-user"
            width="60px"
            height="60px"
            src={`../../assets/logo.png`}
            style={{ cursor: "pointer", borderRadius: "50%" }}
          />
        </Box>
        <Box textAlign="center">
          <Typography
            variant="h3"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "2px 0 0 0" }}
          >
            {name.split(" ")[0]} {lastname.split(" ")[0]}
          </Typography>
        </Box>
      </Box>
      <List>
        <ListItemButton onClick={redirectTo("/")}>
          <HomeOutlinedIcon />
          <ListItemText
            primary={t("Home").toUpperCase()}
            sx={{ marginLeft: 2 }}
          />
        </ListItemButton>
        <ListItemButton onClick={redirectTo("/business")}>
          <HomeOutlinedIcon />
          <ListItemText
            primary={t("Panel").toUpperCase()}
            sx={{ marginLeft: 2 }}
          />
        </ListItemButton>
        {listMain.map((list, index) => (
          <Accordion key={index} sx={{ background: colors.primary[400] }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="booking-content"
              id="booking-header"
            >
              <Typography variant="h6" color={colors.grey[300]}>
                {list.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MenuList>
                {list.subList.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemButton onClick={redirectTo(item.url)}>
                      <InboxIcon />
                      <ListItemText
                        primary={item.title}
                        sx={{ marginLeft: 2 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </MenuList>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuOutlinedIcon />
        </IconButton>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer(false)}
          PaperProps={{
            sx: {
              background: colors.primary[400],
            },
          }}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default DrawerUI;
