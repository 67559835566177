import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    height: 14,
    fontStyle: "bold",
  },
  index: {
    width: "6%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // paddingLeft: 1,
    fontSize: 7,
  },
  fulldate: {
    width: "7%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  fullHour: {
    width: "15%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  fullname: {
    width: "17%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  originHour: {
    width: "47%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  origin: {
    width: "27%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  destination: {
    width: "27%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  toll: {
    width: "6%",
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 4,
    fontSize: 8,
  },
  price: {
    width: "9%",
    height: "100%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 6,
    fontSize: 8,
  },
  originHourContainer: {
    maxHeight: 100, // Define la altura máxima que deseas
    overflow: "hidden", // Oculta el contenido que exceda la altura máxima
  },
});

const TableRow = ({ items }) => {
  const Br = () => "\n";
  const rows = items.map((item) => {
    return item.booking_type_id != 3 ? (
      <View style={styles.row} key={item.order_number.toString()}>
        <Text style={styles.index}>{item.order_number}</Text>
        <Text style={styles.fullname}>{item.fullname_employee}</Text>
        <Text style={styles.fulldate}>{item.date}</Text>
        <Text style={styles.origin}>{item.origin_name}</Text>
        <Text style={styles.destination}>{item.destination_name}</Text>
        <Text style={styles.toll}>{item.toll}</Text>
        <Text style={styles.price}>{item.price_total}</Text>
      </View>
    ) : (
      <View
        style={{ ...styles.row, maxHeight: 44, height: "100%" }}
        key={item.order_number.toString()}
      >
        <Text style={styles.index}>{item.order_number}</Text>
        <Text style={styles.fullname}>{item.fullname_employee}</Text>
        <Text style={styles.fullHour}>
          {item.date} {item.time}
          <Br />
          {item.dateEnd} {item.timeEnd}
        </Text>
        <Text style={styles.originHour}>
          ORIGEN: {item.origin_name}
          <Br />
          {item.notes.substring(0, 120)}
          <Br />
          DESTINO: {item.destination_name}
        </Text>

        <Text style={styles.toll}>{item.toll}</Text>
        <Text style={styles.price}>{item.price_total}</Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
