import { baseBackend } from "../base/baseUrl";

const URL_ADVANCE = "/api/v1/advance-cash";

export const createAdvanceCash = async (advance) => {
  return await baseBackend.post(`${URL_ADVANCE}`, JSON.stringify({ advance }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const getAdvanceCashTransaction = async (transactionId) => {
  return await baseBackend.get(`${URL_ADVANCE}/${transactionId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getAdvanceCashOrder = async (orderNumber) => {
  return await baseBackend.get(`${URL_ADVANCE}-order/${orderNumber}`, {
    headers: { "Content-Type": "application/json" },
  });
};
