import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  createTheme,
  CssBaseline,
  Popper,
  Paper,
  Typography,
  Button,
  Stack,
  Modal,
} from "@mui/material";

import Header from "../../../components/header/Header";
import TopMenu from "../../../components/topbar/TopMenu";
import {
  getToken,
  decodeRefreshToken,
  decodeDataToken,
} from "../../../helpers/token/refreshToken";
import { historyEmployeeById } from "../../../api/booking/booking";
import { convertDate } from "../../../helpers/date/convertDate";

import PropTypes from "prop-types";
import { generateOpenPayURL } from "../../../api/openpay/openpay";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import HistoryTable from "./table/HistoryTable";
import Title from "./pdf/elements/Title";
import Contact from "./pdf/elements/Contact";
import EnterpriseName from "./pdf/elements/EnterpriseName";
import Table from "./pdf/elements/Table";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: 1,
        height: 1,
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

const History = () => {
  const theme = createTheme();
  const [address, setAddress] = useState([]);
  const { t } = useTranslation();

  const bookingType = {
    1: "AHORA",
    2: "RESERVAS",
    3: "HORAS",
    4: "OTROS",
  };

  useEffect(() => {
    const token = getToken("etknre");
    const uid = decodeRefreshToken(token);
    try {
      const getBookingBusiness = async () => {
        const response = await historyEmployeeById(uid);
        console.log(response);
        setAddress([]);
        const compareByDate = (a, b) => {
          const dateB = convertDate(b.date, b.time);
          const dateA = convertDate(a.date, a.time);
          return dateB - dateA;
        };

        Object.values(response.data.history)
          .sort(compareByDate)
          .map((booking, index) => {
            booking.index = index + 1;
            booking.fulldate = `${booking.date} ${booking.time}`;
            booking.fullname = `${booking.driver_name} ${booking.driver_lastname}`;
            booking.status =
              booking.status === "PAGADO" ? "REALIZADO" : "PENDIENTE";
            setAddress((oldData) => [...oldData, booking]);
          });
        console.log(response.data);
      };
      getBookingBusiness();
    } catch (error) {
      console.log(error);
    }
    //traer todos los pedidos de este empleado
  }, []);

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };

  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value || ""}
        width={params.colDef.computedWidth}
      />
    );
  }

  renderCellExpand.propTypes = {
    colDef: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
  };

  const columns = [
    { field: "index", headerName: "Nro", width: 50 },
    {
      headerName: t("number").toUpperCase(),
      field: "order_number",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: t("date").toUpperCase(),
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: t("time").toUpperCase(),
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: t("origin").toUpperCase(),
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
    },
    {
      headerName: t("destination").toUpperCase(),
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 250,
      wrap: true,
    },
    {
      headerName: t("driver_name").toUpperCase(),
      field: "fullname",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 180,
    },
    {
      headerName: t("plate").toUpperCase(),
      field: "plate",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: t("state").toUpperCase(),
      field: "status",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "NOTA",
      field: "notes",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 300,
      renderCell: renderCellExpand,
    },
    {
      headerName: "SERVICIO",
      field: "booking_type_id",
      type: "string",
      align: "left",
      width: 80,
      renderCell: ({ row: { booking_type_id } }) => {
        return <>{bookingType[booking_type_id]}</>;
      },
    },
    {
      headerName: "Total",
      field: "price_total",
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      headerName: "Pagar",
      field: "payment",
      width: 100,
      renderCell: ({
        row: { id, order_number, currency, price_total, advanceList },
      }) => {
        let sum = advanceList.reduce((acc, currValue) => {
          return acc + Number(currValue.amount);
        }, 0);
        const total = price_total - sum;

        return (
          <div>
            {total > 0 && (
              <Button
                sx={{ backgroundColor: "green", color: "white" }}
                fullWidth
                onClick={(e) => {
                  e.preventDefault();
                  const data = {
                    id: id,
                    orderNumber: order_number,
                    total,
                    currency,
                  };
                  setDataSelect(data);
                  handleOpenPayment();
                }}
              >
                Pagar
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  const [payment, setPayment] = useState(false);
  const handleClosePayment = () => setPayment(false);
  const handleOpenPayment = () => setPayment(true);
  const [dataSelect, setDataSelect] = useState(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: 5,
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const generateOpenPayForm = async (booking) => {
    try {
      const curren = ["", "PEN", "USD"];
      const baseUrl = "https://booking.taximundo.com";
      // const baseUrl = "http://localhost:3003";
      const redirectUrl = `${baseUrl}/voucher/${curren[booking.currency]}`;

      const token = getToken("dttkn");
      const { name, lastname, email, phone } = decodeDataToken(token);
      const orderID = `${booking.orderNumber}-${Date.now()}`;

      const bookingOpenPay = {
        method: "card",
        amount: Number(booking.total),
        currency: curren[booking.currency],
        description: `${booking.orderNumber}. PAGO POR SERVICIO DE TRANSPORTE`,
        order_id: orderID,
        confirm: "false",
        send_email: "false",
        redirect_url: redirectUrl,
        due_date: dateOpenpayFormat(), //"2023-12-09T19:22:00",
        customer: {
          name: name,
          last_name: lastname,
          phone_number: phone,
          email: email,
        },
      };

      console.log(bookingOpenPay);
      const response = await generateOpenPayURL(bookingOpenPay);
      const paymentUrl = response.data.data.payment_method.url;
      window.open(paymentUrl, "_blank");
    } catch (error) {
      console.log(error);
      //manage error
    }
  };

  const dateOpenpayFormat = () => {
    var fecha = new Date();

    var año = fecha.getFullYear();
    var mes = ("0" + (fecha.getMonth() + 1)).slice(-2);
    var dia = ("0" + fecha.getDate()).slice(-2);
    var hora = ("0" + fecha.getHours()).slice(-2);
    var minuto = ("0" + fecha.getMinutes()).slice(-2);
    var segundo = ("0" + fecha.getSeconds()).slice(-2);

    var formatoFecha =
      año + "-" + mes + "-" + dia + "T" + hora + ":" + minuto + ":" + segundo;

    return formatoFecha;
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  const [dataSelected, setDataSelected] = useState([]);
  const [items, setItems] = useState(null);
  const contactData = {
    company: "TAXIMUNDO",
    email: "info@taximundo.com",
    phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
    address: "Servicios Generales Trans Mundo E.I.R.L.",
  };
  const [userData, setUserData] = useState({
    enterprise: "",
    name: "",
    lastname: "",
  });
  useEffect(() => {
    if (dataSelected.length > 0) {
      const dataToken = getToken("dttkn");
      const { commertial_name, igv, name, lastname } =
        decodeDataToken(dataToken);
      console.log(dataSelected);
      const data = {
        enterprise: commertial_name,
        name: name,
        lastname: lastname,
      };
      setUserData(data);

      let total = dataSelected.reduce(
        (accum, order) => accum + Number(order.price_total),
        0
      );
      let toll = dataSelected.reduce(
        (accum, order) => accum + Number(order.toll),
        0
      );
      let tax = dataSelected.reduce(
        (accum, order) => accum + Number(order.tax),
        0
      );

      let imp = 0;
      let totalPay = 0;
      let subTotal = 0;

      if (igv === "MAS IGV") {
        imp = Math.round((Number(total) + Number(toll)) * 0.18 * 100) / 100;
        subTotal = Math.round((Number(total) + Number(toll)) * 100) / 100;
        totalPay =
          Math.round((Number(total) + Number(toll) + Number(imp)) * 100) / 100;
      } else if (igv === "INAFECTO") {
        totalPay = Math.round((Number(total) + Number(toll)) * 100) / 100;
      } else if (igv === "AFECTO") {
        subTotal = Math.round((total / 1.18) * 100) / 100;
        imp = Math.round((total - subTotal) * 100) / 100;
        totalPay = Math.round((Number(total) + Number(toll)) * 100) / 100;
      }

      setItems({
        id: "5df3180a09ea16dc4b95f910",
        invoice_no: "201906-28",
        items: dataSelected,
        igv: imp,
        total: totalPay,
        peaje: toll,
        subTotal: subTotal,
      });
    }
  }, [dataSelected]);

  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
      <CssBaseline />
      <div className="app">
        <main className="content">
          <TopMenu />
          <Box m="10px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="HISTORIAL DE PEDIDOS" subtitle="" />
              {dataSelected.length > 0 && items && (
                <PDFDownloadLink
                  document={
                    <Document>
                      <Page size="A4" style={styles.page}>
                        <Title title="Taxi Mundo PERU" />
                        <Contact invoice={contactData} />
                        <EnterpriseName
                          title={"DETALLES DE SERVICIO"}
                          enterprise={userData.enterprise}
                          name={userData.name}
                          lastname={userData.lastname}
                        />

                        <Table invoice={items} />
                      </Page>
                    </Document>
                  }
                  fileName={"detalles-taximundo.pdf"}
                >
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ fontWeight: "bold" }}
                  >
                    DESCARGAR
                  </Button>
                </PDFDownloadLink>
              )}
            </Box>

            <Box display="grid" gridAutoRows="120px">
              <Box gridColumn="span 12" gridRow="span 2">
                <HistoryTable
                  data={address}
                  columns={columns}
                  setDataSelected={setDataSelected}
                />
              </Box>
            </Box>

            {/* PAGO OPENPAY */}
            <Modal open={payment} onClose={handleClosePayment}>
              <Box sx={style}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold" }}
                  textAlign={"center"}
                >
                  PAGAR PEDIDO?
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Button
                    fullWidth
                    size="small"
                    color="primary"
                    variant="contained"
                    sx={{ fontWeight: "bold" }}
                    onClick={(e) => {
                      handleClosePayment();
                      generateOpenPayForm(dataSelect);
                    }}
                  >
                    CONFIRMAR
                  </Button>
                  <Button
                    fullWidth
                    size="small"
                    color="error"
                    variant="contained"
                    sx={{ fontWeight: "bold" }}
                    onClick={handleClosePayment}
                  >
                    CANCELAR
                  </Button>
                </Stack>
              </Box>
            </Modal>
          </Box>
        </main>
      </div>
      {/* </ThemeProvider> */}
    </>
  );
};

export default History;
