import { baseBackend } from "../../../../api/base/baseUrl";

const URL_FILTER_HISTORY = "/api/v2/enterprise";
export const getReportHistory = async (params) => {
  return await baseBackend.post(
    `${URL_FILTER_HISTORY}/history`,
    JSON.stringify({ params }),
    { headers: { "Content-Type": "application/json" } }
  );
};

const URL_NO_INVOICE = "/api/v2/noinvoice"
export const getEnterpriseNoInvoice = async (params) => {
  return await baseBackend.post(
    `${URL_NO_INVOICE}/enterprise`,
    JSON.stringify({ params }),
    { headers: { "Content-Type": "application/json" } }
  );
};

