import { URL_ADDRESS } from "../../../../helpers/endpoint";
import { baseBackend } from "../../../base/baseUrl";

export const getEnterpriseAddress = async (token, enterprise_id) => {
  return await baseBackend.get(`${URL_ADDRESS}/enterprise/${enterprise_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEnterpriseEmployeeAddress = async (
  token,
  employee_id,
  enterprise_id
) => {
  return await baseBackend.post(
    `${URL_ADDRESS}/employee`,
    JSON.stringify({ employee_id, enterprise_id }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
