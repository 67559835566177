import React, { Fragment, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import Title from "./elements/Title";
import Number from "./elements/Number";
import { data } from "./elements/Data";
import BillTo from "./elements/BillTo";
import MessageFooter from "./elements/MessageFooter";
import ItemsTable from "./elements/ItemsTable";
import DocumentName from "./elements/DocumentName";
// Create styles
// const styles = StyleSheet.create({
//   table: {
//     display: "table",
//     width: "auto",
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderRightWidth: 0,
//     borderBottomWidth: 0,
//   },
//   tableRow: {
//     margin: "auto",
//     flexDirection: "row",
//   },
//   tableCol: {
//     width: "25%",
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderLeftWidth: 0,
//     borderTopWidth: 0,
//   },
//   tableCell: {
//     margin: "auto",
//     marginTop: 5,
//     fontSize: 10,
//   },
// });

const ReportPDF = () => {
  const [tableData, setTableData] = useState();
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });
  return (
    <PDFViewer width="1000" height="600" className="app">
      <Document>
        <Page size="A4" style={styles.page}>
          <Title title="Taxi Mundo PERU" />
          <BillTo invoice={data} />
          <DocumentName title={"HOJA DE RUTA"} number={100}/>
          <Number invoice={data}/>
          <ItemsTable invoice={data} />
        </Page>
      </Document>
    </PDFViewer>
  );
};
//<PDFViewer>
//</PDFViewer>
export default ReportPDF;
