import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import Title from "./elements/Title";
import Contact from "./elements/Contact";
import DocumentName from "./elements/DocumentName";
import Table from "./elements/Table";
import { useLocation } from "react-router-dom";
import { Box, Button } from "@mui/material";
import EnterpriseName from "./elements/EnterpriseName";

const ReportHistory = () => {
  const [tableData, setTableData] = useState(null);
  const location = useLocation();
  const userData = location.state.userData;

  useEffect(() => {
    const invoice = location.state.invoiceData;
    const total = invoice.sumTotal;
    const toll = invoice.sumToll;
    let imp = 0;
    let totalPay = 0;
    let subTotal = 0;
    if (invoice.igv === "MAS IGV") {
      imp = Math.round((Number(total) + Number(toll)) * 0.18 * 100) / 100;
      subTotal = Math.round((Number(total) + Number(toll)) * 100) / 100;
      totalPay =
        Math.round((Number(total) + Number(toll) + Number(imp)) * 100) / 100;
    } else if (invoice.igv === "INAFECTO") {
      totalPay = Math.round((Number(total) + Number(toll)) * 100) / 100;
    } else if (invoice.igv === "AFECTO") {
      subTotal = Math.round((total / 1.18) * 100) / 100;
      imp = Math.round((total - subTotal) * 100) / 100;
      totalPay = Math.round((Number(total) + Number(toll)) * 100) / 100;
    }

    setTableData([]);
    setTableData({
      id: "5df3180a09ea16dc4b95f910",
      invoice_no: "201906-28",
      company: "TAXIMUNDO",
      email: "info@taximundo.com",
      phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
      address: "Servicios Generales Trans Mundo E.I.R.L.",
      items: userData,
      igv: imp,
      total: totalPay,
      peaje: toll,
      subTotal: subTotal,
      from: userData[userData.length - 1].date,
      to: userData[0].date,
    });
  }, []);

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <>
      {tableData && (
        <PDFDownloadLink
          document={
            <Document>
              <Page size="A4" style={styles.page}>
                <Title title="Taxi Mundo PERU" />
                <Contact invoice={tableData} />
                <DocumentName
                  title={"DETALLE DE SERVICIOS"}
                  from={tableData.from}
                  to={tableData.to}
                />
                <EnterpriseName
                  enterprise={location.state.invoiceData.commertial_name}
                  name={location.state.invoiceData.name}
                  lastname={location.state.invoiceData.lastname}
                />
                <Table invoice={tableData} />
              </Page>
            </Document>
          }
          fileName={"detalles-taximundo.pdf"}
        >
          <Box width={"100%"} height="100vh" textAlign={"center"}>
            <Button
              variant="contained"
              sx={{ marginTop: 10, fontWeight: "bold" }}
            >
              DESCARGAR
            </Button>
          </Box>
        </PDFDownloadLink>
      )}
    </>
  );
};
export default ReportHistory;
