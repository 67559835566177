import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 20,
    fontStyle: "bold",
  },
  index: {
    width: "81%",
    textAlign: "center",
    fontSize: 8,
  },
  title: {
    width: "10%",
    height: 20,
    borderRightColor: borderColor,
    borderRightWidth: 0,
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    fontSize: 8,
    padding: 2,
  },
  price: {
    width: "9%",
    height: 20,
    textAlign: "right",
    fontSize: 8,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    padding: 2,
  },
});

const TableTotal = ({ invoice }) => (
  <>
    <View style={styles.row}>
      <Text style={styles.index}>{}</Text>
      <Text style={styles.title}>{"Peajes"}</Text>
      <Text style={styles.price}>{invoice.peaje}</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.index}>{}</Text>
      <Text style={styles.title}>{"Sub Total"}</Text>
      <Text style={styles.price}>{invoice.subTotal}</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.index}>{}</Text>
      <Text style={styles.title}>{"IGV"}</Text>
      <Text style={styles.price}>{invoice.igv}</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.index}>{}</Text>
      <Text style={styles.title}>{"Total"}</Text>
      <Text style={styles.price}>{invoice.total}</Text>
    </View>
  </>
);

export default TableTotal;
