export const dateFormat = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day < 10 ? "0" + day : day}/${
    month < 10 ? "0" + month : month
  }/${year}`;
};

export const timeFormat = (date) => {
  const hour = date.getHours();
  const minute = date.getMinutes();
  return `${
    hour > 12
      ? hour % 12 < 10
        ? "0" + (hour % 12)
        : hour % 12
      : hour > 9
        ? hour
        : "0" + hour
  }:${minute > 9 ? minute : `0${minute}`} ${hour > 11 ? "PM" : "AM"}`;
};

export const formatDateTime = (date, time) => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const hour = time.getHours();
  const minute = time.getMinutes();

  return { day, month, year, hour, minute };
};
