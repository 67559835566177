import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import { Box, Button, useTheme, Grid, TextField } from "@mui/material";
import { tokens } from "../../../theme";
import { createSubrole } from "../../../api/admin/subrole";
import SnackBar from "../../snackbar/SnackBar";

const RoleForm = ({ token, enterprise_id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleClose = () => {
    setStateSnk(false);
  };

  const checkoutSchema = yup.object().shape({
    role: yup.string().required("required"),
  });

  const initialValues = {
    role: "",
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleFormSubmit = async (values) => {
    console.log(values);
    try {
      await createSubrole(token, values.role.toUpperCase(), enterprise_id);
      snackBarDialog("ROL CREADO CORRECTAMENTE");
      values.role = "";
    } catch (error) {
      snackBarDialog("Error: NO SE PUEDE CREAR ROLES REPETIDOS", "error");
    }
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box backgroundColor={colors.primary[400]} p={2}>
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={8}>
                <TextField
                  value={values.role}
                  fullWidth
                  type="text"
                  label={t("role").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="role"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                  }}
                  error={!!touched.role && !!errors.role}
                  helperText={touched.role && errors.role}
                />
              </Grid>

              <Grid item xs={12} sm={4} textAlign={"center"}>
                <Button
                  fullWidth
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                >
                  {t("AGREGAR")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleClose}
            severity={severity}
            messageSnk={messageSnk}
          />
        </form>
      )}
    </Formik>
  );
};

export default RoleForm;
