import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { db } from "../../../../firebase";
import { ref, onValue } from "firebase/database";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";

import * as yup from "yup";
import { tokens } from "../../../../theme";
import { getClientId } from "../../../../api/client";
import { getDrivers } from "../../../../api/driver";

const FormBooking = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [booking, setBooking] = useState(null);
  const [client, setClient] = useState(null);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    const starCountRef = ref(db, `bookingNow/${id}`);
    onValue(starCountRef, (snapshot) => {
      let data = snapshot.val();
      //setBooking([])
      if (data !== null) {
        const clientData = async () => {
          const tokenJson = window.localStorage.getItem("loggedUser");
          if (tokenJson) {
            const token = JSON.parse(tokenJson);
            try {
              const response = await getClientId(token, data.client);
              setClient(response?.data[0]);
              //console.log(response?.data[0]?.name)
            } catch (error) {
              console.log(error);
            }
          }
        };

        clientData();
        const nDate = new Date(data.date);
        data.date = `${nDate.getDay()}/${
          nDate.getMonth() + 1
        }/${nDate.getFullYear()}`;
        data.time = `${nDate.getHours()}:${nDate.getMinutes()}`;
        setBooking(data);
      }
    });
  }, []);

  useEffect(() => {
    if (booking) {
      booking.client = client.name;
    }
  }, [client]);

  useEffect(() => {
    const driversData = async () => {
      const tokenJson = window.localStorage.getItem("loggedUser");
      if (tokenJson) {
        const token = JSON.parse(tokenJson);
        try {
          const response = await getDrivers(token);
          //setClient(response?.data[0]);
          console.log(response?.data)
        } catch (error) {
          console.log(error);
        }
      }
    };
    driversData()
  }, []);

  const checkoutSchema = yup.object().shape({
    driver: yup.number().required("required"),
  });

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleFormSubmit = (values) => {
    console.log(values);
  };

  return (
    <>
      {client ? (
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={booking}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="20px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                backgroundColor={colors.primary[400]}
                sx={{
                  "& > div": {
                    gridColumn: isNonMobile ? undefined : "span 4",
                  },
                }}
                p={2}
              >
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  type="text"
                  label={t("origin")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.origin.name}
                  name="origin"
                  color="success"
                  sx={{ gridColumn: "span 4" }}
                />
                {/* backgroundColor: '#F2F0F0', input: { color: 'black' }*/}
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("destination")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.destination.name}
                  name="destination"
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("client")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.client}
                  name="client"
                  sx={{ gridColumn: "span 2" }}
                />

                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("date")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.date}
                  name="persons"
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("time")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.time}
                  name="persons"
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("persons")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.persons}
                  name="persons"
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  disabled
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("notes")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.note}
                  name="notes"
                  sx={{ gridColumn: "span 3" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("vehicle")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.vehicle}
                  name="vehicle"
                  sx={{ gridColumn: "span 1" }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="text"
                  label={t("driver")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.driver}
                  name="driver"
                  sx={{ gridColumn: "span 3" }}
                />

                <FormControl sx={{ gridColumn: "span 1" }}>
                  <InputLabel>{t("currency")}</InputLabel>
                  <Select
                    id="currency"
                    value={values.currency}
                    name="currency"
                    label={t("currency")}
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>{t("PEN")}</MenuItem>
                    <MenuItem value={2}>{t("USD")}</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  variant="outlined"
                  color="success"
                  type="number"
                  label={t("price")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.price}
                  name="price"
                  sx={{ gridColumn: "span 1" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  {t("saveChanges")}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <span>loading...</span>
      )}
    </>
  );
};

export default FormBooking;
