export const buttonStyle = {
  fontWeight: "bold",
  background: "white",
  borderRadius: 3,
  borderStyle: "solid",
  borderColor: "black",
  borderWidth: 1,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "black",
  width: "100%",
  height: "100%",
  fontSize: 15,
};
