import { getEnterpriseInvoice } from "../repository/dashboard.repository";

export const ReadEnterpriseInvoice = async (enterpriseId) => {
  try {
    const response = await getEnterpriseInvoice(enterpriseId);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response.data;
  }
};
