import { Navigate, Outlet } from "react-router-dom";
import {
  deleteToken,
  getToken,
  verifyExpireTokenRefresh,
} from "../../../helpers/token/refreshToken";

//entras a register/login/reset/validation
export const ProtectedRoute = ({ children, redirectTo = "/" }) => {
  const token = verifyExpireTokenRefresh();
  const data = getToken("dttkn");

  if (token || data == null) {
    deleteToken("etknre");
    deleteToken("dttkn");
    return children ? children : <Outlet />;
  }

  return <Navigate to={redirectTo} />;
};

//entras a profile booking traveller
export const ProtectedEmployeeRoute = ({ children, redirectTo = "/" }) => {
  const token = verifyExpireTokenRefresh();
  const data = getToken("dttkn");

  if (token || data == null) {
    deleteToken("etkre");
    deleteToken("dttkn");
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
};
