import { deleteCostCenter } from "../repository/costcenter.repository";

export const DeleteCostCenter = async (sedeId) => {
  try {
    const response = await deleteCostCenter(sedeId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
