import { getDashboardInfo } from "../repository/dashboard.repository";

export const ReadDashboard = async (enterpriseId) => {
  try {
    const response = await getDashboardInfo(enterpriseId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
