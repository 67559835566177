import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  useTheme,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
  Stack,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { tokens } from "../../../theme";
import SnackBar from "../../snackbar/SnackBar";
import { getSubroles } from "../../../api/admin/subrole";
import { decodeDataToken, getToken } from "../../../helpers/token/refreshToken";
import { getEmployeesFromEnterprise } from "../../../api/employee/employee";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  searchBookingEnterprisePerEmployee,
  searchBookingEnterprisePerRole,
  searchBookingPerEnterprise,
} from "../../../api/booking/booking";
import { useNavigate } from "react-router-dom";
import { GetFilterHistory } from "./usecases/GetFilterHistory";
import { ReadEnterpriseSede } from "../../../views/users/admin/costcenter/usecases/ReadEnterpriseSede";
import { ReadCostCenter } from "../../../views/users/admin/costcenter/usecases/ReadCostCenter";
import { GetEnterpriseNoInvoice } from "./usecases/GetEnterpriseNoInvoice";
import { convertDate } from "../../../helpers/date/convertDate";
import GenerateReport from "./components/GenerateReport";
import ReportTable from "./components/table/ReportTable";

import * as XLSX from "xlsx";

const ReportForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [employees, setEmployees] = useState([]);
  const [roles, setRoles] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [role, setRole] = useState(null);
  //LISTA DE RESULTADOS
  const [booking, setBooking] = useState([]);
  const [history, setHistory] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [costCenters, setCostCenters] = useState([]);

  //CREDITO
  useEffect(() => {
    const enterpriseCredit = async () => {
      try {
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const params = {
          id: enterprise_id,
          dateStart: null,
          dateEnd: null,
          employeeId: 0,
        };
        const response = await GetEnterpriseNoInvoice(params);

        setBooking([]);

        Object.values(response.history)
          .sort(compareByDate)
          .map((item) => {
            item.fullname_employee = `${item.employee_name} ${item.employee_lastname}`;
            item.fullname_driver = `${item.driver_name} ${item.driver_lastname}`;
            setBooking((oldData) => [...oldData, item]);
          });
      } catch (error) {
        console.log(error);
      }
    };
    enterpriseCredit();
  }, []);

  const compareByDate = (a, b) => {
    const dateB = convertDate(b.date, b.time);
    const dateA = convertDate(a.date, a.time);
    return dateB - dateA;
  };
  //ROLES
  useEffect(() => {
    const getSubRoles = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getSubroles(token, enterprise_id);
        setRoles([]);
        Object.values(response?.data).map((role, index) => {
          role.index = index + 1;
          setRoles((oldData) => [...oldData, role]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getSubRoles();
  }, []);

  //EMPLEADOS
  useEffect(() => {
    const getEmployees = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getEmployeesFromEnterprise(token, enterprise_id);
        setEmployees(response?.data);
        const listSedes = await ReadEnterpriseSede(enterprise_id);
        const listCost = await ReadCostCenter(enterprise_id);
        if (listSedes.message === "success") {
          setSedes(listSedes.sede);
          setSede(listSedes.sede.length > 0 ? listSedes.sede[0] : {});
        }
        if (listCost.message === "success") {
          setCostCenters(listCost.costcenter);
          setCostCenter(
            listCost.costcenter.length > 0 ? listCost.costcenter[0] : {}
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
  }, []);
  //COSTCENTER
  const [costCenter, setCostCenter] = useState({});
  const [sede, setSede] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleClose = () => setStateSnk(false);

  const checkoutSchema = yup.object().shape({
    type_report: yup.string().required("requerido"),
  });

  const initialValues = {
    type_report: 7,
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleFormSubmit = async (values) => {
    try {
      values.role = "";
    } catch (error) {}
  };

  const columns = [
    { headerName: "PEDIDO", field: "order_number", width: 50 },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "TRABAJADOR",
      field: "fullname_employee",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "ORIGEN",
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "CONDUCTOR",
      field: "fullname_driver",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "PLATE",
      field: "plate",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PRECIO",
      field: "price_total",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "NOTA",
      field: "notes",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
  ];

  const historyColumns = [
    { headerName: "PEDIDO", field: "order_number", width: 50 },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "TRABAJADOR",
      field: "fullname_employee",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { name, lastname } }) => {
        return (
          <>
            {name} {lastname}
          </>
        );
      },
    },
    {
      headerName: "ORIGEN",
      field: "origin_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "DESTINO",
      field: "destination_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "CONDUCTOR",
      field: "driver_name",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
      renderCell: ({ row: { driver_name, driver_lastname } }) => {
        return (
          <>
            {driver_name} {driver_lastname}
          </>
        );
      },
    },
    {
      headerName: "PLATE",
      field: "plate",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    {
      headerName: "PRECIO",
      field: "price_total",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
  ];

  const getReports = async (type_report) => {
    try {
      setBooking([]);
      setIsLoading(true);
      if (type_report === 1) {
        if (!employee) {
          setIsLoading(false);
          return snackBarDialog("SELECCIONE UN TRABAJADOR", "error");
        }
        await searchBookingByEmployee(employee.id);
      } else if (type_report === 2) {
        if (!role) {
          setIsLoading(false);
          return snackBarDialog("SELECCIONE EL AREA", "error");
        }
        await searchBookingByRole(role.enterprises_id, role.id);
      } else if (type_report === 3 || type_report === 4 || type_report === 5) {
        await searchBookingBySede(type_report);
      } else if (type_report === 6) {
        await searchBookingByEnterprise();
      } else if (type_report === 7) {
        await searchNoInvoice();
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const searchBookingByRole = async (enterprise_id, subrole_id) => {
    try {
      const response = await searchBookingEnterprisePerRole(
        enterprise_id,
        subrole_id,
        fromDate,
        toDate
      );
      setBooking([]);
      Object.values(response.data)
        .sort(compareByDate)
        .map((book, index) => {
          setBooking((oldData) => [...oldData, book]);
        });
      setHistory([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const searchBookingByEmployee = async (employee_id) => {
    try {
      const response = await searchBookingEnterprisePerEmployee(
        employee_id,
        fromDate,
        toDate
      );
      setBooking([]);
      Object.values(response.data)
        .sort(compareByDate)
        .map((book, index) => {
          setBooking((oldData) => [...oldData, book]);
        });
      setHistory([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const searchBookingByEnterprise = async () => {
    try {
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const response = await searchBookingPerEnterprise(
        enterprise_id,
        fromDate,
        toDate
      );
      setBooking([]);
      Object.values(response.data)
        .sort(compareByDate)
        .map((book) => {
          setBooking((oldData) => [...oldData, book]);
        });
      setHistory([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const searchBookingBySede = async (report) => {
    try {
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const params = {
        sedeId: report === 3 || report === 5 ? sede.id : 0,
        costCenterId: report === 4 || report === 5 ? costCenter.id : 0,
        enterpriseId: enterprise_id,
        dateStart: fromDate,
        dateEnd: toDate,
      };
      setBooking([]);
      const response = await GetFilterHistory(params);
      if (response.message === "success") {
        setHistory([]);
        Object.values(response.history)
          .sort(compareByDate)
          .map((history) => {
            setHistory((oldHistory) => [...oldHistory, history]);
          });
        setIsLoading(false);
      } else if (response.message === "ERROR_EMPTY_SEDE_HISTORY") {
        setHistory([]);
        snackBarDialog("SEDES: RESULTADO ES 0", "error");
        setIsLoading(false);
      } else if (response.message === "ERROR_EMPTY_COST_CENTER_HISTORY") {
        setHistory([]);
        snackBarDialog("CENTRO DE COSTOS: RESULTADO ES 0", "error");
        setIsLoading(false);
      } else if ("ERROR_EMPTY_COST_CENTER_SEDE_HISTORY") {
        setHistory([]);
        snackBarDialog("SEDE Y CENTRO DE COSTO: RESULTADO ES 0", "error");
      } else {
        snackBarDialog("FALLO EL SERVIDOR", "error");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const searchNoInvoice = async () => {
    setIsLoading(true);
    try {
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const params = {
        id: enterprise_id,
        dateStart: fromDate,
        dateEnd: toDate,
        employeeId: employee?.id ? employee?.id : 0,
      };
      const response = await GetEnterpriseNoInvoice(params);
      if (response.message == "success") {
        console.log(response.history);
        setHistory([]);
        setBooking([]);
        response.history.sort(compareByDate).map((item) => {
          item.fullname_employee = `${item.employee_name} ${item.employee_lastname}`;
          item.fullname_driver = `${item.driver_name} ${item.driver_lastname}`;
          setBooking((oldData) => [...oldData, item]);
        });
      } else {
        console.log(response);
      }
      console.log(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const [reportData, setReportData] = useState([]);
  const [totalData, setTotalData] = useState(null);

  const setReportBookingBusiness = async () => {
    try {
      setReportData([]);
      const dataToken = getToken("dttkn");
      const { igv, commertial_name, name, lastname } =
        decodeDataToken(dataToken);

      let sumTotal = 0;
      let sumToll = 0;

      Object.values(booking).map((book) => {
        sumToll += Number(book.toll);
        sumTotal += Number(book.price_total);
        setReportData((oldData) => [...oldData, book]);
      });

      setTotalData({ sumTotal, sumToll, igv, commertial_name, name, lastname });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (reportData.length > 0 && totalData.sumTotal > 0) {
      navigate(`/business/reports-history`, {
        state: { userData: reportData, invoiceData: totalData },
      });
    }
  }, [reportData]);

  const report = {
    PERSONA: 1,
    ROLE: 2,
    SEDE: 3,
    CENTRO_COSTOS: 4,
    SEDE_COSTOS: 5,
    TODO: 6,
    FACTURAR: 7,
  };

  const [value, setValue] = useState("vertical");
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [bookingData, setBookingData] = useState([]);

  const handleDownload = () => {
    const enterprise = [{ A: "TAXIMUNDO" }, {}];
    const title = [{ A: "DETALLE DE SERVICIOS" }, {}];

    const infoAditional = {
      A: "",
    };

    let table = [
      {
        A: "Nro",
        B: "FECHA",
        C: "HORA",
        D: "AREA",
        E: "C. DE COSTO",
        F: "NOMBRE",
        G: "ORIGEN",
        H: "DESTINO",
        I: "CONDUCTOR",
        J: "PLACA",
        K: "NOTAS",
        L: "PEAJE",
        M: "PRECIO",
      },
    ];

    bookingData.forEach((book) => {
      table.push({
        A: book.order_number,
        B: book.date,
        C: book.time,
        D: book.sub_role_name ?? "N/A",
        E: book.cost_center_name ?? "N/A",
        F: book.fullname_employee,
        G: book.origin_name,
        H: book.destination_name,
        I: book.fullname_driver,
        J: book.plate,
        K: book.notes,
        L: book.toll,
        M: book.price_total,
      });
    });

    const dataFinal = [...enterprise, ...title, ...table];

    setTimeout(() => {
      creandoArchivo(dataFinal);
    }, 1000);
  };

  const creandoArchivo = (dataFinal) => {
    const widths = [8, 15, 15, 15, 15, 25, 25, 25, 25, 10, 25, 10, 10];
    const libro = XLSX.utils.book_new();

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true });

    hoja["!merges"] = [
      XLSX.utils.decode_range("A1:G1"),
      XLSX.utils.decode_range("A2:G2"),
    ];

    let propiedades = [];

    widths.forEach((col) => {
      propiedades.push({
        width: col,
      });
    });

    hoja["!cols"] = propiedades;

    XLSX.utils.book_append_sheet(libro, hoja, "DETALLES");

    XLSX.writeFile(libro, "DETALLES.xlsx");
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <FormControl>
          <label>FORMATO PDF</label>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="horizontal"
              control={<Radio color="success" />}
              label="Horizontal"
              sx={{ color: "white" }}
            />
            <FormControlLabel
              value="vertical"
              control={<Radio color="success" />}
              label="Vertical"
              sx={{ color: "white" }}
            />
          </RadioGroup>
        </FormControl>
      </div>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box backgroundColor={colors.primary[400]} p={1}>
              <Grid container spacing={0.5} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="select-report">REPORTE</InputLabel>
                    <Select
                      labelId="select-report"
                      id="type_report"
                      value={values.type_report}
                      name="type_report"
                      onChange={handleChange}
                      label="REPORTE"
                    >
                      <MenuItem value={1}>POR PERSONA</MenuItem>
                      <MenuItem value={2}>POR AREA</MenuItem>
                      <MenuItem value={3}>POR SEDE</MenuItem>
                      <MenuItem value={4}>POR CENTRO DE COSTOS</MenuItem>
                      <MenuItem value={5}>POR SEDE Y CENTRO DE COSTOS</MenuItem>
                      <MenuItem value={6}>TODO</MenuItem>
                      <MenuItem value={7}>POR FACTURAR</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {(values.type_report === report.PERSONA ||
                  values.type_report === report.FACTURAR) && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        value={employee}
                        disablePortal
                        name="employee"
                        id="combo-box-employee"
                        onChange={(e, newValue) => {
                          setEmployee(newValue);
                        }}
                        options={employees}
                        getOptionLabel={(option) =>
                          `${option.id}: ${option.name} ${option.lastname} - ${option.dni} - ${option.subrole_name}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("COLABORADOR")} />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {values.type_report === report.ROLE && (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <Autocomplete
                        value={role}
                        disablePortal
                        name="role"
                        id="combo-box-roles"
                        onChange={(e, newValue) => {
                          setRole(newValue);
                        }}
                        options={roles}
                        getOptionLabel={(option) =>
                          `${option.index}: ${option.name}`
                        }
                        renderInput={(params) => (
                          <TextField {...params} label={t("ROLES")} />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {(values.type_report == report.SEDE ||
                  values.type_report == report.SEDE_COSTOS) && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={values.type_report == 3 ? 3 : 2}
                    >
                      <Autocomplete
                        value={sede}
                        disablePortal
                        name="sede"
                        id="box-sedes"
                        onChange={(e, newValue) => {
                          setSede(newValue);
                        }}
                        options={sedes}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField {...params} label={t("SEDES")} />
                        )}
                      />
                    </Grid>
                  </>
                )}

                {(values.type_report == report.CENTRO_COSTOS ||
                  values.type_report == report.SEDE_COSTOS) && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={values.type_report == 4 ? 3 : 2}
                    >
                      <Autocomplete
                        value={costCenter}
                        disablePortal
                        name="costCenter"
                        id="box-costcenter"
                        onChange={(e, newValue) => {
                          setCostCenter(newValue);
                        }}
                        options={costCenters}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("CENTRO DE COSTOS")}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={6} sm={2} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack>
                      <MobileDatePicker
                        value={fromDate}
                        label="Desde"
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setFromDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={2} md={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack>
                      <MobileDatePicker
                        value={toDate}
                        label="Hasta"
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue) => {
                          setToDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={6} sm={4} md={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{ height: 45, fontWeight: "bold", color: "white" }}
                    onClick={(e) => {
                      e.preventDefault();
                      getReports(values.type_report);
                    }}
                    disabled={isLoading}
                  >
                    BUSCAR
                  </Button>
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <GenerateReport
                        position={value}
                        bookingData={bookingData}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleDownload}
                        sx={{ height: 45, color: "white", fontWeight: "bold" }}
                      >
                        excel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  {booking.length > 0 && (
                    <ReportTable
                      data={booking}
                      columns={columns}
                      setDataSelected={setBookingData}
                    />
                  )}
                  {history.length > 0 && (
                    <ReportTable
                      data={history}
                      columns={historyColumns}
                      setDataSelected={setBookingData}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={5000}
              handleClose={handleClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default ReportForm;
