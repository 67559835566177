import { Link } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Formik } from "formik";

import { forgotPassword } from "../../../api/axios";
import { Copyright } from "../../../components/copyright/Copyright";
import SnackBar from "../../../components/snackbar/SnackBar";
import { useState } from "react";

const theme = createTheme();

export default function ForgotPassword() {
  const { t } = useTranslation();

  const checkoutSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("Email Required"),
  });

  const initialValues = {
    email: "",
  };

  const handleFormSubmit = async (values) => {
    try {
      setIsLoading(true);
      await forgotPassword(values.email);
      snackBarDialog("SE ENVIO UN MESAJE A SU CORREO PARA RECUPERAR SU CUENTA");
      setIsLoading(false);
    } catch (err) {
      snackBarDialog("ERROR: REVISE EL CORREO INGRESADO", "error");
      setIsLoading(false);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [messageSnk, setMessageSnk] = useState("");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={`../../assets/logo.png`}
            alt="Logo"
            style={{ width: 152, height: 152 }}
          />
          <Typography component="h1" variant="h5">
            Taxi Mundo Empresas
          </Typography>
          <Box>
            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={checkoutSchema}
            >
              {({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ margin: "4px" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          value={values.email}
                          required
                          fullWidth
                          id="email"
                          label="Email"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={!!touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 2, mb: 2 }}
                          disabled={isLoading}
                        >
                          {t("resetPassword")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  <Grid container justifyContent="end">
                    <Grid item>
                      <Link to="/login">{t("singin")}</Link>
                    </Grid>
                  </Grid>
                  <SnackBar
                    stateSnk={stateSnk}
                    vertical={vertical}
                    horizontal={horizontal}
                    duration={4000}
                    handleClose={handleSnkClose}
                    severity={severity}
                    messageSnk={messageSnk}
                  />
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
