import React from "react";
import Alert from "@mui/material/Alert";

const AlertNotification = ({ description, alertType }) => {
  return (
    <>
      {description ? (
        <Alert
          variant="filled"
          severity={alertType}
          sx={{ marginBottom: "10px" }}
        >
          {description}
        </Alert>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default AlertNotification;
