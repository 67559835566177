import { baseBackend } from "../../../../../api/base/baseUrl";

const URL_INVOICE = "/api/v1/invoice"
export const searchInvoiceBusiness = async (token, id) => {
  return await baseBackend.get(`${URL_INVOICE}/business/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};