import { createBackup } from "../../../../../api/backup/backup";

export const CreateBackupUseCase = async (data) => {
  try {
    const resp = await createBackup(data);
    return resp.data;
  } catch (error) {
    return error.response.data;
  }
};
