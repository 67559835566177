import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  FormControl,
  InputLabel,
  OutlinedInput,
  Grid,
  CssBaseline,
  Select,
  MenuItem,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { tokens } from "../../../../../../theme";
import {
  getEmployeeById,
  getSubroles,
  updateEmployee,
} from "../../../../../../api/employee/employee";
import { ReadEnterpriseSede } from "../../../costcenter/usecases/ReadEnterpriseSede";
import { ReadCostCenter } from "../../../costcenter/usecases/ReadCostCenter";
import { ReadRelationCostCenter } from "../../../costcenter/usecases/ReadRelationCostCenter";
import {
  decodeDataToken,
  getToken,
} from "../../../../../../helpers/token/refreshToken";
import { employeeSchema } from "./values/EmployeeValues";
import Topbar from "../../../../../../components/topbar/TopBar";
import Header from "../../../../../../components/header/Header";
import SnackBar from "../../../../../../components/snackbar/SnackBar";

const EditEmployee = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { t } = useTranslation();
  const [employee, setEmployee] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [roles, setRoles] = useState("");

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const [sedes, setSedes] = useState([]);
  const [costCenters, setCostCenters] = useState([]);

  const checkoutSchema = yup.object().shape(employeeSchema(yup));

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const token = "";
        const response = await getEmployeeById(token, id);
        setEmployee(response.data[0]);

        const listSede = await ReadEnterpriseSede(
          response.data[0].enterprises_id
        );
        const listCostCenter = await ReadCostCenter(
          response.data[0].enterprises_id
        );
        if (listSede.message === "success") {
          setSedes(listSede.sede);
        }
        if (listCostCenter.message === "success") {
          setCostCenters(listCostCenter.costcenter);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getEmployee();
  }, []);

  useEffect(() => {
    const getSubRoles = async () => {
      try {
        const token = "";
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getSubroles(token, enterprise_id);
        setRoles([]);
        Object.values(response?.data).map((role, index) => {
          role.index = index + 1;
          setRoles((oldData) => [...oldData, role]);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const getCostCenter = async () => {
      try {
        const response = await ReadRelationCostCenter(employee.id);
        console.log(response);
        if (response.message === "success") {
          setCostCenterOne(
            response.relation[0] ? response.relation[0].cost_center_id : 0
          );
          setCostCenterTwo(
            response.relation[1] ? response.relation[1].cost_center_id : 0
          );
          if (response.relation.length === 2) setNewGrid(true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (employee) {
      getSubRoles();
      getCostCenter();
    } else {
      console.log("no hay employee");
    }
  }, [employee]);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    try {
      const employee = {
        id: values.id,
        dni: values.dni,
        email: values.email,
        name: values.name.toUpperCase(),
        lastname: values.lastname.toUpperCase(),
        phone: values.phone,
        enterprise_id: values.enterprise_id,
        sub_role_id: values.sub_role_id,
        sede_id: values.sede_id ? values.sede_id : 0,
        costCenter: {
          costCenterOne,
          costCenterTwo,
        },
      };

      await updateEmployee("", employee);
      snackBarDialog("ACTUALIZACION EXITOSO");
      setIsLoading(false);
    } catch (error) {
      snackBarDialog("Error al Registrar Usuario", "error");
      setIsLoading(false);
    }
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleSnkClose = () => setStateSnk(false);

  const [newGrid, setNewGrid] = useState(false);
  const [costCenterOne, setCostCenterOne] = useState(0);
  const [costCenterTwo, setCostCenterTwo] = useState(0);

  const createCostGrid = (e) => {
    e.preventDefault();
    setNewGrid(true);
  };
  const deleteCostGrid = (e) => {
    e.preventDefault();
    setCostCenterTwo(0);
    setNewGrid(false);
  };

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="5px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={t("ACTUALIZAR COLABORADOR")} subtitle="" />
            </Box>
            <Box display="grid" gap="0px">
              <Box>
                {employee ? (
                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={employee}
                    validationSchema={checkoutSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box backgroundColor={colors.primary[400]} p={1}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                  DNI
                                </InputLabel>
                                <OutlinedInput
                                  value={values.dni}
                                  name="dni"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  id="control-dni"
                                  label="DNI"
                                  aria-describedby="outlined-helper-text"
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                value={values.email}
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="text"
                                label={t("email")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                value={values.name}
                                fullWidth
                                variant="outlined"
                                type="text"
                                label={t("name")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="name"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                color="success"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                value={values.lastname}
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="text"
                                label={t("lastname")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="lastname"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                error={!!touched.lastname && !!errors.lastname}
                                helperText={touched.lastname && errors.lastname}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                value={values.phone}
                                fullWidth
                                variant="outlined"
                                color="success"
                                type="tel"
                                label={t("phone")}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                name="phone"
                                error={!!touched.phone && !!errors.phone}
                                helperText={touched.phone && errors.phone}
                                sx={{ gridColumn: "span 1" }}
                              />
                            </Grid>
                            {roles && (
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                  <InputLabel>{t("Rol")}</InputLabel>
                                  <Select
                                    id="sub_role_id"
                                    value={values.sub_role_id}
                                    name="sub_role_id"
                                    label={t("Rol")}
                                    onChange={handleChange}
                                  >
                                    {roles.map((role, index) => (
                                      <MenuItem key={index} value={role.id}>
                                        {t(role.name)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )}
                            {sedes.length > 0 && (
                              <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                  <InputLabel>SEDES</InputLabel>
                                  <Select
                                    id="sede_id"
                                    value={values.sede_id}
                                    name="sede_id"
                                    label="SEDES"
                                    onChange={handleChange}
                                  >
                                    {sedes.map((sede, idx) => (
                                      <MenuItem key={idx} value={sede.id}>
                                        {sede.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )}
                            {costCenters.length > 0 && (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                alignContent={"center"}
                                alignItems={"center"}
                                display={"flex"}
                              >
                                <FormControl sx={{ width: "85%" }}>
                                  <InputLabel>CENTRO DE COSTOS</InputLabel>
                                  <Select
                                    id="sub_role_id"
                                    value={costCenterOne}
                                    name="costCenterOne"
                                    label="CENTRO DE COSTOS"
                                    onChange={(e) =>
                                      setCostCenterOne(e.target.value)
                                    }
                                  >
                                    {costCenters.map((costCenter, idx) => (
                                      <MenuItem key={idx} value={costCenter.id}>
                                        {costCenter.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <Button
                                  variant="contained"
                                  color="success"
                                  sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    width: "10%",
                                  }}
                                  onClick={createCostGrid}
                                  disabled={newGrid}
                                >
                                  +
                                </Button>
                              </Grid>
                            )}

                            {newGrid && (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                alignContent={"center"}
                                alignItems={"center"}
                                display={"flex"}
                              >
                                <FormControl sx={{ width: "85%" }}>
                                  <InputLabel>CENTRO DE COSTOS</InputLabel>
                                  <Select
                                    id="two"
                                    value={costCenterTwo}
                                    name="costCenterTwo"
                                    label="CENTRO DE COSTOS"
                                    onChange={(e) =>
                                      setCostCenterTwo(e.target.value)
                                    }
                                  >
                                    {costCenters.map((costCenter, idx) => (
                                      <MenuItem key={idx} value={costCenter.id}>
                                        {costCenter.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <Button
                                  variant="contained"
                                  color="success"
                                  sx={{
                                    color: "white",
                                    fontWeight: "bold",
                                    width: "10%",
                                  }}
                                  onClick={deleteCostGrid}
                                >
                                  -
                                </Button>
                              </Grid>
                            )}

                            <Grid item xs={12} sm={12}>
                              <Button
                                fullWidth
                                type="submit"
                                color="secondary"
                                variant="contained"
                                disabled={isLoading}
                                sx={{ height: 45 }}
                              >
                                {t("UPDATE EMPLEADO")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                        <SnackBar
                          stateSnk={stateSnk}
                          vertical={vertical}
                          horizontal={horizontal}
                          duration={4000}
                          handleClose={handleSnkClose}
                          severity={severity}
                          messageSnk={messageSnk}
                        />
                      </form>
                    )}
                  </Formik>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default EditEmployee;
