import { baseBackend } from "../../../../../api/base/baseUrl";

const URL_SEDE = "/api/v2/sede";
export const createSede = async (sede) => {
  return await baseBackend.post(
    `${URL_SEDE}/create`,
    JSON.stringify({ sede }),
    { headers: { "Content-Type": "application/json" } }
  );
};

export const readEnterpriseSede = async (enterpriseId) => {
  return await baseBackend.get(`${URL_SEDE}/enterprise/id/${enterpriseId}`, {
    headers: { "Content-type": "application/json" },
  });
};

export const updateSede = async (sede) => {
  return await baseBackend.put(`${URL_SEDE}/update`, JSON.stringify({ sede }), {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteSede = async (sedeId) => {
  return await baseBackend.delete(`${URL_SEDE}/delete/id/${sedeId}`, {
    headers: { "Content-Type": "application/json" },
  });
};
