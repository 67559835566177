import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  useTheme,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  FormControlLabel,
  Checkbox,
  TextField,
  FormLabel,
  RadioGroup,
  Radio,
  Autocomplete,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { tokens } from "../../../theme";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import "./address.css";
import { decodeDataToken, getToken } from "../../../helpers/token/refreshToken";
import { getEmployeesFromEnterprise } from "../../../api/employee/employee";
import {
  createAddress,
  getEnterpriseAddress,
} from "../../../api/admin/address";
import { addressInitial, addressSchema } from "./AddressValues";
import SnackBar from "../../snackbar/SnackBar";
import { useDispatch, useSelector } from "react-redux";
import { addAddress } from "../../../features/address/addressSlice";

const AddressForm = () => {
  const theme = useTheme();
  const addressState = useSelector((state) => state.address);
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [radio, setRadio] = useState("global");
  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkoutSchema = yup.object().shape(addressSchema(yup));
  const initialValues = addressInitial;

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleSnkClose = () => {
    setStateSnk(false);
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  useEffect(() => {
    const getEmployees = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getEmployeesFromEnterprise(token, enterprise_id);
        setEmployees(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getEmployees();
    getAddressEnterprise();
  }, []);

  const [address, setAddress] = useState([]);
  const getAddressEnterprise = async () => {
    try {
      const token = getToken("etknre");
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const response = await getEnterpriseAddress(token, enterprise_id);
      setAddress(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const areSimilarSimple = (msg1, msg2) => {
    return (
      msg1.toLowerCase().includes(msg2.toLowerCase()) ||
      msg2.toLowerCase().includes(msg1.toLowerCase())
    );
  };

  const checkSimilarity = async (name) => {
    let similarityList = [];
    Object.values(address).map(async (item) => {
      const indice = areSimilarSimple(name, item.name);
      if (indice) similarityList.push(item.name);
    });
    return similarityList;
  };

  const handleFormSubmit = async (values) => {
    try {
      const similarityList = await checkSimilarity(values.name);
      setIsLoading(true);
      setAddressName([]);
      if (similarityList.length > 0) {
        setAddressValue();
        setOpen(true);
        setAddressName(similarityList);
        setAddressValue(values);
      } else {
        handleCreateAddress(values);
      }
    } catch (error) {
      snackBarDialog("ERROR: NO RE AGREGO DIRECCION", "error");
      setIsLoading(false);
    }
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  const [addressName, setAddressName] = useState([]);
  const [addressValue, setAddressValue] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsLoading(false);
  };

  const handleCreateAddress = async (values) => {
    try {
      const coordinate = values.latitude.split(",");
      const token = getToken("etknre");
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const address = {
        global: false,
        global_enterprise: true,
        url: values.url,
        name: values.name.toUpperCase(),
        latitude: Number(coordinate[0]),
        longitude: Number(coordinate[1]),
        enterprises_id: enterprise_id,
        employees_id: null,
      };

      if (radio === "employee") {
        address.global_enterprise = false;
        address.employees_id = employee.id;
      }
      const response = await createAddress(token, address);
      snackBarDialog("DIRECCION AGREGADA");
      values.url = "";
      values.name = "";
      values.latitude = "";
      setIsLoading(false);
      address.id = response.data.id;
      address.url_address = response.data.url_address;
      address.index = addressState.length + 1;
      dispatch(addAddress(address));
      values.url = "";
      values.name = "";
    } catch (error) {
      console.log(error);
      snackBarDialog("ERROR: NO RE AGREGO DIRECCION", "error");
      setIsLoading(false);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    border: "1px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 1,
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={checkoutSchema}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box backgroundColor={colors.primary[400]} p={2}>
            <Grid container spacing={1.5} sx={{ alignItems: "center" }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>URL</InputLabel>
                  <OutlinedInput
                    value={values.url}
                    id="address-url"
                    name="url"
                    label="URL"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="des"
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <LinkOutlinedIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={values.name}
                  fullWidth
                  type="text"
                  label={t("address").toUpperCase()}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="name"
                  inputProps={{ style: { textTransform: "uppercase" } }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  value={values.latitude}
                  fullWidth
                  type="text"
                  label={"LATITUD y LONGITUD"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  inputProps={{ style: { textTransform: "uppercase" } }}
                  name="latitude"
                />
              </Grid>

              <Grid item xs={12} sm={6} textAlign="center">
                <FormControl>
                  <FormLabel id="label-placement" color="secondary">
                    DIRECCION
                  </FormLabel>
                  <RadioGroup row name="position" defaultValue="global">
                    <FormControlLabel
                      value="global"
                      control={
                        <Radio
                          color="success"
                          onClick={(e) => {
                            console.log(e.target.value);
                            setRadio(e.target.value);
                          }}
                        />
                      }
                      label="GLOBAL"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="employee"
                      control={
                        <Radio
                          color="success"
                          onClick={(e) => {
                            console.log(e.target.value);
                            setRadio(e.target.value);
                          }}
                        />
                      }
                      label="INDIVIDUAL"
                      labelPlacement="end"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {radio === "employee" ? (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    value={employee}
                    disablePortal
                    name="employee"
                    id="combo-box-employee"
                    onChange={(e, newValue) => {
                      setEmployee(newValue);
                    }}
                    options={employees}
                    getOptionLabel={(option) =>
                      `${option.id}: ${option.name} ${option.lastname} - ${option.dni} - ${option.subrole_name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("employee")} />
                    )}
                  />
                </Grid>
              ) : (
                <></>
              )}

              <Grid item xs={12} sm={12} textAlign={"center"}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  sx={{ height: 45 }}
                  disabled={isLoading}
                >
                  {t("AGREGAR")}
                </Button>
              </Grid>
            </Grid>
          </Box>
          <SnackBar
            stateSnk={stateSnk}
            vertical={vertical}
            horizontal={horizontal}
            duration={5000}
            handleClose={handleSnkClose}
            severity={severity}
            messageSnk={messageSnk}
          />
          <Modal open={open} onClose={handleClose}>
            <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
              <Typography variant="h4" component="h2">
                DIRECCIONES SIMILARES
              </Typography>
              <Typography variant="h6" component="h3">
                {addressName.map((elem, idx) => (
                  <div key={idx}>
                    {elem} <br />
                  </div>
                ))}
              </Typography>
              <Grid container spacing={1} alignItems="center" marginTop={1}>
                <Grid item xs={6} md={6} textAlign="center">
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handleCreateAddress(addressValue)}
                  >
                    AGREGAR
                  </Button>
                </Grid>
                <Grid item xs={6} md={6} textAlign="center">
                  <Button
                    color="error"
                    variant="contained"
                    onClick={handleClose}
                  >
                    CANCELAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </form>
      )}
    </Formik>
  );
};

export default AddressForm;
