import { Box, Grid, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../../../theme";
import { dateFormat, timeFormat } from "../../../../../../helpers/date/date";
import { red } from "@mui/material/colors";

const FactureBox = ({ invoice, noInvoice, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box width="100%" m="10px 0px 0px 10px">
      <Box height={200} backgroundColor={colors.primary[400]} overflow="auto">
        <Grid container spacing={0} alignItems={"center"}>
          <Grid item sm={6}>
            <Typography
              fontWeight={"bold"}
              variant="h3"
              sx={{ color: colors.greenAccent[500] }}
            >
              FACTURAS
            </Typography>
          </Grid>
          <Grid item sm={6} textAlign={"end"}>
            {icon}
          </Grid>
        </Grid>
        {invoice.map((invoice, i) => (
          <Grid
            container
            key={`${invoice.id}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="5px"
          >
            <Grid item sm={4}>
              <Typography
                color={colors.greenAccent[500]}
                fontWeight="600"
                sx={{ fontSize: 14 }}
              >
                {invoice.invoice.toUpperCase()}
              </Typography>
              <p style={{ fontSize: 12, margin: -1 }}>
                {dateFormat(new Date(invoice.date_expire))}
              </p>
            </Grid>
            <Grid item sm={4} color={colors.grey[100]}>
              <p style={{ fontSize: 12, margin: -1 }}>{invoice.total}</p>
            </Grid>
            <Grid
              item
              sm={4}
              textAlign={"center"}
              backgroundColor={
                invoice.status == "EXPIRADO"
                  ? "red"
                  : invoice.status == "PAGADO"
                  ? "#176CC7"
                  : "yellow"
              }
              color={
                invoice.status == "EXPIRADO"
                  ? "white"
                  : invoice.status == "PAGADO"
                  ? "white"
                  : "black"
              }
              p="5px 5px"
              borderRadius="4px"
            >
              {invoice.status == "EXPIRADO"
                ? "EXPIRADO"
                : invoice.status == "PAGADO"
                ? "PAGADO"
                : "PENDIENTE"}
            </Grid>
          </Grid>
        ))}
        {/* <Typography
          marginTop="5px"
          variant="h5"
          sx={{ color: colors.greenAccent[500] }}
        >
          NO FACTURADOS
        </Typography>
        {noInvoice.map((item, i) => (
          <Box
            key={`${item.id}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="5px"
          >
            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                {item.order_number}
              </Typography>
            </Box>
            <Box color={colors.grey[100]}>
              <p style={{ fontSize: 12, margin: -1 }}>
                {item.date} {item.time}
              </p>
            </Box>
            <Box
              backgroundColor={colors.greenAccent[500]}
              p="5px 5px"
              borderRadius="4px"
            >
              {item.price_total}
            </Box>
          </Box>
        ))} */}
      </Box>
    </Box>
  );
};

export default FactureBox;
