import { Box, useTheme, CssBaseline, Grid, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { tokens } from "../../../theme";
import Header from "../../../components/header/Header";
import Topbar from "../../../components/topbar/TopBar";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BookingBox from "./dashboard/views/components/BookingBox";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { ReadDashboard } from "./dashboard/usecases/ReadDashboard";
import { decodeDataToken, getToken } from "../../../helpers/token/refreshToken";
import EmployeeBox from "./dashboard/views/components/EmployeeBox";
import FactureBox from "./dashboard/views/components/FactureBox";
import { ReadEnterpriseInvoice } from "./dashboard/usecases/ReadEnterpriseInvoice";

export const DashboardAdmin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [welcome, setWelcome] = useState({ fullName: "", enterprise: "" });

  useEffect(() => {
    const readDashboardInformation = async () => {
      try {
        const dataToken = getToken("dttkn");
        const { name, lastname, commertial_name, enterprise_id } =
          decodeDataToken(dataToken);
        setWelcome({
          fullName: `${name} ${lastname}`,
          enterprise: commertial_name,
        });

        const response = await ReadDashboard(enterprise_id);
        if (response.message == "success") {
          setData(response);
        } else console.log("error", response);
      } catch (error) {
        console.log(error);
      }
    };

    const readEnterpriseInvoice = async () => {
      try {
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        //156
        const response = await ReadEnterpriseInvoice(enterprise_id);
        if (response.message === "success") {
          const compareByDate = (a, b) => {
            const dateB = new Date(b.date_expire);
            const dateA = new Date(a.date_expire);
            return dateB - dateA;
          };
          setListBooking([]);
          Object.values(response.invoice)
            .sort(compareByDate)
            .map((item, idx) => {
              setListBooking((oldData) => [...oldData, item]);
            });
          setListNoInvoice(response.noInvoice);
        } else {
          console.log(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    readEnterpriseInvoice();
    readDashboardInformation();
  }, []);
  const [listBooking, setListBooking] = useState([]);
  const [listNoInvoice, setListNoInvoice] = useState([]);

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="20px">
            {/* HEADER */}
            <Box>
              <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "0 0 5px 0", textAlign: "center" }}
              >
                {welcome.enterprise}
              </Typography>
              <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "0 0 5px 0", textAlign: "center" }}
              >
                BIENVENIDO(A), {welcome.fullName}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={t("dashboard")} subtitle="" />
            </Box>
            {/* GRID & CHARTS */}
            <Box display="grid" gap="20px">
              {/* ROW 1 */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"100%"}
                  >
                    <BookingBox
                      title={data?.message ? data.countBooking.countTotal : 0}
                      month={data?.message ? data.countBooking.countMonth : 0}
                      week={data?.message ? data.countBooking.countWeek : 0}
                      day={data?.message ? data.countBooking.countDay : 0}
                      icon={
                        <AutoStoriesIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "40px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <EmployeeBox
                      title={"USUARIOS"}
                      employees={data?.message ? data.countEmployee : 0}
                      area={data?.message ? data.countUsers.countArea : 0}
                      costCenter={
                        data?.message ? data.countUsers.countCostCenter : 0
                      }
                      admin={data?.message ? data.countUsers.countAdmin : 0}
                      icon={
                        <PersonAddIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "40px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Box
                    gridColumn="span 3"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FactureBox
                      invoice={listBooking}
                      noInvoice={listNoInvoice}
                      icon={
                        <DescriptionOutlinedIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "40px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm={3}>
                  <Box
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <StatBox
                      title="1,325,134"
                      subtitle="Traffic Received"
                      progress="0.80"
                      increase="+43%"
                      icon={
                        <TrafficIcon
                          sx={{
                            color: colors.greenAccent[600],
                            fontSize: "26px",
                          }}
                        />
                      }
                    />
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};
