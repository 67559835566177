import { isExpired, decodeToken } from "react-jwt";

export const setToken = (nameToken, token) => {
  window.localStorage.setItem(nameToken, JSON.stringify(token));
};

export const getToken = (nameToken) => {
  try {
    if (!localStorage) return null;

    const tokenJson = window.localStorage.getItem(nameToken);

    if (!tokenJson) return null;

    const token = JSON.parse(tokenJson);
    return token;
  } catch (error) {
    return null;
  }
};

export const deleteToken = (nameToken) => {
  window.localStorage.removeItem(nameToken);
};

export const verifyExpireTokenRefresh = () => {
  const token = getToken("etknre");

  if (!token) return true;

  const isTokenExpired = isExpired(token);
  return isTokenExpired;
};

export const decodeRefreshToken = (token) => {
  const { uid } = decodeToken(token);
  return uid;
};

export const decodeDataToken = (token) => {
  const {
    name,
    lastname,
    email,
    phone,
    sub_role,
    enterprise_id,
    commertial_name,
    igv,
  } = decodeToken(token);
  return {
    name,
    lastname,
    email,
    phone,
    sub_role,
    enterprise_id,
    commertial_name,
    igv,
  };
};
