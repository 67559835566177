import {
  Box,
  Button,
  CssBaseline,
  Grid,
  IconButton,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import Header from "../../../../../components/header/Header";
import SnackBar from "../../../../../components/snackbar/SnackBar";
import Topbar from "../../../../../components/topbar/TopBar";
import { ref, onValue } from "firebase/database";
import { db } from "../../../../../firebase";
import {
  decodeDataToken,
  getToken,
} from "../../../../../helpers/token/refreshToken";
import { tokens } from "../../../../../theme";
import { DataGrid, esES } from "@mui/x-data-grid";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Document,
  Page,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
import Title from "../pdf/elements/Title";
import Contact from "../pdf/elements/Contact";
import DocumentName from "../pdf/elements/DocumentName";
import Table from "../pdf/elements/Table";
import Enterprise from "../pdf/elements/Enterprise";
import TableHour from "../pdf/elements/TableHour";

const Quote = () => {
  const token = getToken("etknre");
  const dataToken = getToken("dttkn");
  const { enterprise_id } = decodeDataToken(dataToken);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //LISTA DE RESULTADOS
  const [booking, setBooking] = useState(null);
  const payment_type = {
    1: "EFECTIVO",
    2: "CREDITO",
  };
  //CREDITO
  useEffect(() => {
    const starCountRef = ref(db, "BookingBusiness/");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      setBooking([]);
      if (data !== null) {
        const compareByDate = (a, b) => {
          return b.created - a.created;
        };
        //enterprise_id
        // && booking.cotizar
        Object.values(data)
          .sort(compareByDate)
          .map((booking) => {
            if (
              booking.booking_type === 2 &&
              booking.enterpriseId == enterprise_id &&
              !booking.cotizar
            ) {
              console.log(booking);
              booking.originName = booking.origin.name;
              booking.destinationName = booking.destination.name;
              booking.payment_type = payment_type[booking.payment_type];
              setBooking((oldArray) => [...oldArray, booking]);
            }
          });
      }
    });
  }, []);
  const [dataSelected, setDataSelected] = useState([]);
  const data = {
    id: "5df3180a09ea16dc4b95f910",
    invoice_no: "201906-28",
    company: "TAXIMUNDO",
    email: "info@taximundo.com",
    phone: "(+51) 54 200 900 - (+51) 958 200 900 - (+51) 951 200 900",
    address: "Servicios Generales Trans Mundo E.I.R.L.",
    items: dataSelected,
    igv: 0,
    total: 0,
    peaje: 0,
    subTotal: 0,
    from: dataSelected.length > 0 ? dataSelected[0].date : 0,
    to:
      dataSelected.length > 0 ? dataSelected[dataSelected.length - 1].date : 0,
  };

  const columns = [
    { headerName: "PEDIDO", field: "counterBooking", width: 50 },
    {
      headerName: "FECHA",
      field: "date",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 80,
    },
    {
      headerName: "HORA",
      field: "time",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 70,
    },
    {
      headerName: "TRABAJADOR",
      field: "clientName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "ORIGEN",
      field: "originName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "DESTINO",
      field: "destinationName",
      type: "string",
      headerAlign: "left",
      align: "left",
      width: 200,
    },
    {
      headerName: "PEAJE",
      field: "toll",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 80,
    },
    {
      headerName: "PRECIO",
      field: "total",
      type: "string",
      headerAlign: "left",
      align: "center",
      width: 80,
    },
  ];

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
  });

  return (
    <>
      <CssBaseline />
      <div className="app">
        <main className="content">
          <Topbar />
          <Box m="10px">
            {/* HEADER */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title="COTIZACIONES" subtitle="" />
              <PDFDownloadLink
                document={
                  <Document>
                    {dataSelected.length > 0 &&
                    dataSelected[0].booking_type === 3 ? (
                      <Page size="A4" style={styles.page}>
                        <Title title="Taxi Mundo PERU" />
                        <Contact invoice={data} />
                        <DocumentName
                          title={"COTIZACION"}
                          from={data.from}
                          to={data.to}
                        />
                        <Enterprise invoice={data} />
                        <TableHour invoice={data} />
                      </Page>
                    ) : (
                      <Page size="A4" style={styles.page}>
                        <Title title="Taxi Mundo PERU" />
                        <Contact invoice={data} />
                        <DocumentName
                          title={"COTIZACION"}
                          from={data.from}
                          to={data.to}
                        />
                        <Enterprise invoice={data} />
                        <Table invoice={data} />
                      </Page>
                    )}
                  </Document>
                }
                fileName={"detalles-cotizacion-taximundo.pdf"}
              >
                <Box width={"200px"}>
                  <Button fullWidth variant="contained" color="success">
                    <PictureAsPdfIcon /> DESCARGAR
                  </Button>
                </Box>
              </PDFDownloadLink>
            </Box>
            <Box display="grid" gap="10px">
              <Box backgroundColor={colors.primary[400]} p={1}>
                <Grid container spacing={0.5} sx={{ alignItems: "center" }}>
                  <Grid item xs={12} sm={12} md={2} textAlign="end"></Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      height="100vh"
                      sx={{
                        "& .MuiDataGrid-root": {
                          border: "none",
                        },
                        "& .MuiDataGrid-cell": {
                          borderBottom: "none",
                        },
                        "& .name-column--cell": {
                          color: colors.greenAccent[300],
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          backgroundColor: colors.blueAccent[700],
                          borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          backgroundColor: colors.primary[400],
                        },
                        "& .MuiDataGrid-footerContainer": {
                          borderTop: "none",
                          backgroundColor: colors.blueAccent[700],
                        },
                        "& .MuiCheckbox-root": {
                          color: `${colors.greenAccent[200]} !important`,
                        },
                      }}
                    >
                      {booking && (
                        <DataGrid
                          getRowId={(row) => row.uuid}
                          rows={booking}
                          columns={columns}
                          localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                          }
                          checkboxSelection={true}
                          onSelectionModelChange={(ids) => {
                            setDataSelected([]);
                            ids.map((id) => {
                              const select = booking.find(
                                (row) => row.uuid === id
                              );
                              setDataSelected((oldData) => [
                                ...oldData,
                                select,
                              ]);
                            });
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>

                <SnackBar
                  stateSnk={stateSnk}
                  vertical={vertical}
                  horizontal={horizontal}
                  duration={5000}
                  handleClose={handleClose}
                  severity={severity}
                  messageSnk={messageSnk}
                />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </>
  );
};

export default Quote;

// const ReportForm = () => {
//
//   const { t } = useTranslation();
//   const navigate = useNavigate();

//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);

//   const [employees, setEmployees] = useState([]);
//   const [roles, setRoles] = useState([]);
//   const [employee, setEmployee] = useState(null);
//   const [role, setRole] = useState(null);

//   //ROLES
//   useEffect(() => {
//     const getSubRoles = async () => {
//       try {
//         const token = getToken("etknre");
//         const dataToken = getToken("dttkn");
//         const { enterprise_id } = decodeDataToken(dataToken);
//         const response = await getSubroles(token, enterprise_id);
//         setRoles([]);
//         Object.values(response?.data).map((role, index) => {
//           role.index = index + 1;
//           setRoles((oldData) => [...oldData, role]);
//         });
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getSubRoles();
//   }, []);

//   //EMPLEADOS
//   useEffect(() => {
//     const getEmployees = async () => {
//       try {
//         const token = getToken("etknre");
//         const dataToken = getToken("dttkn");
//         const { enterprise_id } = decodeDataToken(dataToken);
//         const response = await getEmployeesFromEnterprise(token, enterprise_id);
//         setEmployees(response?.data);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     getEmployees();
//   }, []);

//   const handleFormSubmit = async (values) => {
//     try {
//       //await createSubrole(token, values.role.toUpperCase(), enterprise_id);
//       snackBarDialog("ROL CREADO CORRECTAMENTE");
//       values.role = "";
//     } catch (error) {
//       snackBarDialog("Error: No se ha creado el rol", "error");
//     }
//   };

//   const getReports = async (type_report) => {
//     try {
//       setBooking(null);
//       if (type_report === 1) {
//         if (!employee)
//           return snackBarDialog("SELECCIONE UN TRABAJADOR", "error");
//         await searchBookingByEmployee(employee.id);
//       } else if (type_report === 2) {
//         if (!role) return snackBarDialog("SELECCIONE EL AREA", "error");
//         await searchBookingByRole(role.enterprises_id, role.id);
//       } else {
//         await searchBookingByEnterprise();
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const searchBookingByRole = async (enterprise_id, subrole_id) => {
//     try {
//       const response = await searchBookingEnterprisePerRole(
//         enterprise_id,
//         subrole_id,
//         fromDate,
//         toDate
//       );
//       setBooking([]);

//       Object.values(response.data).map((book, index) => {
//         setBooking((oldData) => [...oldData, book]);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const searchBookingByEmployee = async (employee_id) => {
//     try {
//       const response = await searchBookingEnterprisePerEmployee(
//         employee_id,
//         fromDate,
//         toDate
//       );
//       setBooking([]);
//       console.log(response.data);
//       Object.values(response.data).map((book, index) => {
//         setBooking((oldData) => [...oldData, book]);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const searchBookingByEnterprise = async () => {
//     try {
//       const dataToken = getToken("dttkn");
//       const { enterprise_id } = decodeDataToken(dataToken);
//       const response = await searchBookingPerEnterprise(
//         enterprise_id,
//         fromDate,
//         toDate
//       );
//       console.log(response?.data);
//       setBooking([]);
//       Object.values(response.data).map((book) => {
//         setBooking((oldData) => [...oldData, book]);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const [reportData, setReportData] = useState([]);
//   const [totalData, setTotalData] = useState(null);

//   const setReportBookingBusiness = async () => {
//     try {
//       setReportData([]);
//       const dataToken = getToken("dttkn");
//       const { igv } = decodeDataToken(dataToken);

//       let sumTotal = 0;
//       let sumToll = 0;

//       Object.values(booking).map((book) => {
//         sumToll += Number(book.toll);
//         sumTotal += Number(book.price_total);
//         // let imp = 0;
//         // let subTotal = 0;
//         // let toll = book.toll;
//         // let total = 0;
//         // if (igv === "MAS IGV") {
//         //   imp =
//         //     Math.round(
//         //       (Number(book.price_total) + Number(book.toll)) * 0.18 * 100
//         //     ) / 100;
//         //   subTotal = Number(book.price_total) + Number(book.toll);
//         //   total =
//         //     Math.round(
//         //       (Number(book.price_total) + Number(book.toll) + Number(imp)) * 10
//         //     ) / 10;
//         // } else if (igv === "INAFECTO") {
//         //   total = Number(book.price_total) + Number(book.toll);
//         // } else if (igv === "AFECTO") {
//         //   subTotal = Math.round((book.price_total / 1.18) * 100) / 100;
//         //   imp = Math.round((book.price_total - subTotal) * 100) / 100;
//         //   total =
//         //     Math.round((Number(book.price_total) + Number(book.toll)) * 10) /
//         //     10;
//         // }
//         setReportData((oldData) => [...oldData, book]);
//       });

//       setTotalData({ sumTotal, sumToll, igv });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     if (reportData.length > 0 && totalData.sumTotal > 0) {
//       navigate(`/business/reports-history`, {
//         state: { userData: reportData, invoiceData: totalData },
//       });
//     }
//   }, [reportData]);
// };
