import { onValue, ref } from "firebase/database";
import { db } from "../../../../firebase";

export const BookingObserver = (idRider, callback) => {
  try {
    const dbPath = `BookingBusiness`;
    const dbRef = ref(db, dbPath);

    const unsubscribe = onValue(
      dbRef,
      (snapshot) => {
        const resp = snapshot.val();
        let offerList = [];
        if (resp != null) {
          offerList = Object.values(resp).filter(
            (offer) =>
              Number(idRider) === Number(offer.client) &&
              Number(offer.booking_type) === 1
          );
        }
        callback(offerList); // Pasar los resultados a través del callback
      },
      (error) => {
        console.error("Error fetching data: ", error);
      }
    );

    return unsubscribe; // Devuelve la función de desuscripción
  } catch (error) {
    console.error("Error in observer: ", error);
  }
};
