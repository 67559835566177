import axios from "axios";
import { URL_CLIENT } from "../helpers/endpoint";

const base = axios.create({
  baseURL: "http://localhost:4000",
});

export const getClient = async (token) => {
  return await base.get(URL_CLIENT, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClientId = async (token, id) => {
  return await base.get(`${URL_CLIENT}/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateClient = async (
  token,
  name,
  lastname,
  phone,
  address,
  genres_id
) => {
  return await base.put(
    URL_CLIENT,
    JSON.stringify({
      name,
      lastname,
      phone,
      address,
      genres_id,
    }),
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
