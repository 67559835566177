import { readRelationCostCenter } from "../repository/costcenter.repository";

export const ReadRelationCostCenter = async (employeeId) => {
  try {
    const response = await readRelationCostCenter(employeeId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
