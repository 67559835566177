import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import DataTable from "../../../../components/datatable/DataTable";
import { deleteSubrole, getSubroles } from "../../../../api/admin/subrole";
import SnackBar from "../../../../components/snackbar/SnackBar";
import { getToken } from "../../../../helpers/token/refreshToken";
import { tokens } from "../../../../theme";
import { modalStyle } from "../address/AddressStyle";
import { Edit } from "@mui/icons-material";

const RoleList = ({ token, enterprise_id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [role, setRole] = useState([]);

  //delete
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [UID, setUID] = useState("");
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";
  const handleSnkClose = () => setStateSnk(false);

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleClose = () => setOpen(false);

  const handleAddressDelete = async () => {
    setIsLoading(true);
    try {
      const token = getToken("etknre");
      await deleteSubrole(token, UID);
      setUID("");
      snackBarDialog("ROL ELIMINADO CORRECTAMENTE");
      setIsLoading(false);
      setOpen(false);
      setRole(role.filter((el) => el.id !== UID));
    } catch (error) {
      snackBarDialog("ERROR:ESTE ROL TIENE A UN COLABORADOR", "error");
      setIsLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    const getSubRoles = async () => {
      try {
        const response = await getSubroles(token, enterprise_id);
        setRole([]);
        Object.values(response?.data).map((role, index) => {
          role.index = index + 1;
          setRole((oldData) => [...oldData, role]);
        });
      } catch (error) {
        console.log(error);
      }
    };
    getSubRoles();
  }, []);

  const columns = [
    { field: "index", headerName: "ID", width: 50 },
    {
      headerName: "ROLE",
      field: "name",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 120,
      align: "center",
      renderCell: ({ row: { id } }) => {
        return (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="success"
              aria-label="add"
              component="label"
              sx={{ background: "#ffff" }}
              onClick={() => {
                navigate(`/business/edit-role/${id}/${enterprise_id}`);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              aria-label="delete"
              component="label"
              sx={{ background: "#AA151B" }}
              onClick={() => {
                setUID(id);
                setOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <main className="content">
      <Box>
        <Box display="grid" gridAutoRows="120px">
          <Box gridColumn="span 12" gridRow="span 2">
            <DataTable data={role} columns={columns} />
          </Box>
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box backgroundColor={colors.primary[400]} sx={modalStyle}>
          <Typography variant="h6" component="h2">
            ELIMINAR DIRECCION?
          </Typography>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} md={6} textAlign="center">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleAddressDelete}
                disabled={isLoading}
              >
                ELIMINAR
              </Button>
            </Grid>
            <Grid item xs={6} md={6} textAlign="center">
              <Button
                color="error"
                variant="contained"
                onClick={handleClose}
                disabled={isLoading}
              >
                CANCELAR
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <SnackBar
        stateSnk={stateSnk}
        vertical={vertical}
        horizontal={horizontal}
        duration={5000}
        handleClose={handleSnkClose}
        severity={severity}
        messageSnk={messageSnk}
      />
    </main>
  );
};

export default RoleList;
