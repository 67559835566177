import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { boxPaperStyle } from "./HeaderProfileTabStyle";
import { deleteToken } from "../../../helpers/token/refreshToken";
import { useTranslation } from "react-i18next";

export default function HeaderProfileTab({
  fullName,
  subrole,
  commertialName,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box>
        <Tooltip title="Settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{fullName.charAt(0)}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={boxPaperStyle}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <MenuItem>
            <Avatar />
            <Box>
              <Typography variant="h6">{fullName}</Typography>
              <Divider />
              <Typography variant="caption">{subrole}</Typography>
            </Box>
          </MenuItem>
        </Box>
        <MenuItem>
          <Box>
            <Typography variant="h6">{commertialName}</Typography>
          </Box>
        </MenuItem>
        <Divider />

        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("Profile")}
        </MenuItem>
        {subrole === "ADMIN" && (
          <MenuItem onClick={() => navigate("/business")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {t("Panel_Admin")}
          </MenuItem>
        )}
        <MenuItem onClick={() => navigate("/history-enterprise")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("History")}
        </MenuItem>
        <MenuItem onClick={() => navigate("/history")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {t("History_Total")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteToken("etknre");
            deleteToken("dttkn");
            navigate(0);
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </>
  );
}
