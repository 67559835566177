import { get, ref } from "firebase/database";
import { db } from "../../../../firebase";
import { sendPushNotificationByToken } from "../../../../api/fcm/PushNotification";

export const SendNotificationDriver = async (idDriver) => {
  try {
    const tokenPath = `Token/Driver/${idDriver}`;
    const dbTkn = ref(db, tokenPath);
    const snapshot = await get(dbTkn);
    const resp = snapshot.val();
    if (resp != null) {
      const token = resp.token;
      const title = "PEDIDO ACEPTADO";
      const body = "LA OFERTA FUE ACEPTADA POR EL CLIENTE";
      await sendPushNotificationByToken(token, title, body);
    }
    // const dbRef = ref(db, dbPath);
    // const snapshot = await get(dbRef); // 'get' obtiene los datos una vez
  } catch (error) {
    console.log(error);
  }
};
