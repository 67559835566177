import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#424949";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    borderRightColor: borderColor,
    borderRightWidth: 1,
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    height: 50,
    fontStyle: "bold",
  },
  index: {
    width: "7%",
    height: "100%",
    textAlign: "center",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    // paddingLeft: 1,
    fontSize: 7,
  },
  fulldate: {
    width: "15%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  fullTime: {
    width: "7%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  origin: {
    width: "63%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  destination: {
    width: "32%",
    height: "100%",
    textAlign: "left",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: 8,
    marginLeft: 2,
    fontSize: 7,
  },
  toll: {
    width: "6%",
    height: "100%",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 4,
    fontSize: 8,
  },
  price: {
    width: "9%",
    height: "100%",
    // borderRightColor: borderColor,
    // borderRightWidth: 1,
    textAlign: "right",
    paddingRight: 6,
    fontSize: 8,
  },
});

const TableHourRow = ({ items }) => {
  const Br = () => "\n";
  const rows = items.map((item) => (
    <View style={styles.row} key={item.uuid.toString()}>
      <Text style={styles.index}>{item.counterBooking}</Text>
      <Text style={styles.fulldate}>
        {item.date} {item.time}
        <Br />
        {item.dateEnd} {item.timeEnd}
      </Text>
      <Text style={styles.origin}>
        ORIGEN: {item.fullorigin}
        <Br />
        {item.note}
        <Br />
        DESTINO: {item.fulldestination}
      </Text>
      {/* <Text style={styles.destination}>{item.fulldestination}</Text> */}
      <Text style={styles.toll}>{item.toll}</Text>
      <Text style={styles.price}>{item.total}</Text>
      {/* <Text style={styles.rate}>{item.rate}</Text>
      <Text style={styles.amount}>{(item.qty * item.rate).toFixed(2)}</Text> */}
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableHourRow;
