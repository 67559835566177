import { baseBackend } from "../../../../../api/base/baseUrl";

const URL_DASHBOARD = "api/v2/dashboard";
const URL_ENTERPRISE = "api/v2/enterprise";
const URL_INVOICE = "api/v2/invoice";

export const getDashboardInfo = async (enterpriseId) => {
  return await baseBackend.get(`${URL_DASHBOARD}/info/${enterpriseId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getHistoryGlobal = async (enterpriseId) => {
  return await baseBackend.get(`${URL_ENTERPRISE}/history/id/${enterpriseId}`, {
    headers: { "Content-Type": "application/json" },
  });
};

export const getEnterpriseInvoice = async (enterpriseId) => {
  return await baseBackend.get(`${URL_INVOICE}/enterprise/id/${enterpriseId}`, {
    headers: { "Content-Type": "application/json" },
  });
};
