import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "react-pro-sidebar";
import { Box, Typography, useTheme } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { tokens } from "../../theme";
import "react-pro-sidebar/dist/css/styles.css";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const MenuAdmin = ({ paddingLeft }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState("Dashboard");
  const colors = tokens(theme.palette.mode);
  
  return (
    <Box paddingLeft={paddingLeft}>
      <Item
        title="Dashboard"
        to="/administrator"
        icon={<HomeOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      <Typography
        variant="h6"
        color={colors.grey[300]}
        sx={{ m: "15px 0 5px 20px" }}
      >
        Users
      </Typography>
      <Item
        title="Clients"
        to="/main/team"
        icon={<PeopleOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        title="Drivers"
        to="/administrator/add-driver"
        icon={<PeopleOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        title="Employees"
        to="/main/team"
        icon={<PeopleOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

      <Typography
        variant="h6"
        color={colors.grey[300]}
        sx={{ m: "15px 0 5px 20px" }}
      >
        Booking
      </Typography>
      <Item
        title="BookNow"
        to="/administrator/list-book-now"
        icon={<LibraryBooksOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
      <Item
        title="BookLater"
        to="/main/faq"
        icon={<MenuBookOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />

      <Typography
        variant="h6"
        color={colors.grey[300]}
        sx={{ m: "15px 0 5px 20px" }}
      >
        Vehicles
      </Typography>
      <Item
        title="Vehicles"
        to="/administrator/add-vehicle"
        icon={<DirectionsCarFilledOutlinedIcon />}
        selected={selected}
        setSelected={setSelected}
      />
    </Box>
  );
};

export default MenuAdmin;
