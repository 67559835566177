import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as yup from "yup";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Autocomplete,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { tokens } from "../../../theme";
import { countries } from "../../../helpers/country/country";

import SnackBar from "../../snackbar/SnackBar";
import { getSunatDni } from "../../../api/sunat/dni";
import { employeeInitial, employeeSchema } from "./EmployeeValues";
import { getSubroles } from "../../../api/admin/subrole";
import { decodeDataToken, getToken } from "../../../helpers/token/refreshToken";
import { createdEmployee } from "../../../api/employee/employee";
import { ReadCostCenter } from "../../../views/users/admin/costcenter/usecases/ReadCostCenter";
import { ReadEnterpriseSede } from "../../../views/users/admin/costcenter/usecases/ReadEnterpriseSede";

const EmployeeForm = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState(null);

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const [phoneCode, setPhoneCode] = useState(null);
  const checkoutSchema = yup.object().shape(employeeSchema(yup));
  const initialValues = employeeInitial;

  useEffect(() => {
    const getSubRoles = async () => {
      try {
        const token = getToken("etknre");
        const dataToken = getToken("dttkn");
        const { enterprise_id } = decodeDataToken(dataToken);
        const response = await getSubroles(token, enterprise_id);
        setRoles([]);

        Object.values(response?.data).map((role, index) => {
          role.index = index + 1;
          setRoles((oldData) => [...oldData, role]);
        });
        const listSede = await ReadEnterpriseSede(enterprise_id);
        const listCostCenter = await ReadCostCenter(enterprise_id);
        if (listSede.message == "success") setSedes(listSede.sede);
        if (listCostCenter.message == "success")
          setCostCenters(listCostCenter.costcenter);
      } catch (error) {
        console.log(error);
      }
    };
    getSubRoles();
  }, []);

  const handleFormSubmit = async (values) => {
    setIsLoading(true);
    if (!role.id) return snackBarDialog("DEBE SELECCIONAR EL ROL", "error");
    try {
      const token = getToken("etknre");
      const dataToken = getToken("dttkn");
      const { enterprise_id } = decodeDataToken(dataToken);
      const employee = {
        dni: values.dni,
        email: values.email,
        name: values.name.toUpperCase(),
        lastname: values.lastname.toUpperCase(),
        phone: values.phone,
        enterprise_id,
        sub_role_id: role.id,
        sede_id: sede,
        costCenter: {
          costCenterOne,
          costCenterTwo,
        },
      };
      await createdEmployee(token, employee);
      snackBarDialog("REGISTRO EXITOSO");
      values.dni = "";
      values.email = "";
      values.name = "";
      values.lastname = "";
      setIsLoading(false);
    } catch (error) {
      snackBarDialog("Error al Registrar Usuario", "error");
      setIsLoading(false);
    }
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const handleSnkClose = () => {
    setStateSnk(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // (e) => {
  //   setDni(e.target.value);
  //   values.numberLicense = e.target.value;
  // }

  const [sedes, setSedes] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [sede, setSede] = useState(0);
  const [newGrid, setNewGrid] = useState(false);
  const [costCenterOne, setCostCenterOne] = useState(0);
  const [costCenterTwo, setCostCenterTwo] = useState(0);
  const createCostGrid = (e) => {
    e.preventDefault();
    setNewGrid(true);
  };
  const deleteCostGrid = (e) => {
    e.preventDefault();
    setCostCenterTwo(0);
    setNewGrid(false);
  };

  return (
    <>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box backgroundColor={colors.primary[400]} p={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      DNI
                    </InputLabel>
                    <OutlinedInput
                      value={values.dni}
                      name="dni"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="control-dni"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            onClick={async (e) => {
                              if (values.dni.length === 8) {
                                try {
                                  const res = await getSunatDni(values.dni);
                                  console.log(res);
                                  values.name = res?.data?.nombres;
                                  values.lastname = `${res?.data?.apellidoPaterno} ${res.data?.apellidoMaterno}`;
                                  snackBarDialog("Usuario Encontrado");
                                } catch (error) {
                                  snackBarDialog(
                                    "Usuario No Encontrado",
                                    "error"
                                  );
                                }
                              } else {
                                snackBarDialog("DNI DE 8 DIGITOS", "error");
                              }
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="DNI"
                      aria-describedby="outlined-helper-text"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.email}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("email")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.name}
                    fullWidth
                    variant="outlined"
                    type="text"
                    label={t("name")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="name"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    color="success"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    value={values.lastname}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="text"
                    label={t("lastname")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="lastname"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={!!touched.lastname && !!errors.lastname}
                    helperText={touched.lastname && errors.lastname}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id="country-phone"
                    options={countries}
                    defaultValue={countries[172]}
                    autoHighlight
                    getOptionLabel={(option) =>
                      `(${option.phone}) ${option.label}`
                    }
                    onChange={(e) => {
                      const cad = e.target.innerText;
                      const words = cad.split("+");
                      setPhoneCode(`+${words[1]}`);
                      values.phone = `+${words[1]} `;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code}) {option.phone}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    value={values.phone}
                    fullWidth
                    variant="outlined"
                    color="success"
                    type="tel"
                    label={t("phone")}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 1" }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    value={role}
                    disablePortal
                    name="role"
                    id="combo-box-role"
                    onChange={(e, newValue) => {
                      setRole(newValue);
                    }}
                    options={roles}
                    getOptionLabel={(option) =>
                      `${option.index}: ${option.name}`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("role")} />
                    )}
                  />
                </Grid>

                {sedes.length > 0 && (
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel>SEDES</InputLabel>
                      <Select
                        id="sede"
                        value={sede}
                        name="sede"
                        label="SEDES"
                        onChange={(e) => setSede(e.target.value)}
                      >
                        {sedes.map((sede, idx) => (
                          <MenuItem key={idx} value={sede.id}>
                            {sede.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {costCenters.length > 0 && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    alignContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <FormControl sx={{ width: "85%" }}>
                      <InputLabel>CENTRO DE COSTOS</InputLabel>
                      <Select
                        id="sub_role_id"
                        value={costCenterOne}
                        name="costCenterOne"
                        label="CENTRO DE COSTOS"
                        onChange={(e) => setCostCenterOne(e.target.value)}
                      >
                        {costCenters.map((costCenter, idx) => (
                          <MenuItem key={idx} value={costCenter.id}>
                            {costCenter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        width: "10%",
                      }}
                      onClick={createCostGrid}
                      disabled={newGrid}
                    >
                      +
                    </Button>
                  </Grid>
                )}

                {newGrid && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    alignContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <FormControl sx={{ width: "85%" }}>
                      <InputLabel>CENTRO DE COSTOS</InputLabel>
                      <Select
                        id="two"
                        value={costCenterTwo}
                        name="costCenterTwo"
                        label="CENTRO DE COSTOS"
                        onChange={(e) => setCostCenterTwo(e.target.value)}
                      >
                        {costCenters.map((costCenter, idx) => (
                          <MenuItem key={idx} value={costCenter.id}>
                            {costCenter.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        color: "white",
                        fontWeight: "bold",
                        width: "10%",
                      }}
                      onClick={deleteCostGrid}
                    >
                      -
                    </Button>
                  </Grid>
                )}

                <Grid item xs={12} sm={12}>
                  <Button
                    fullWidth
                    type="submit"
                    color="secondary"
                    variant="contained"
                    disabled={isLoading}
                    sx={{ height: 45 }}
                  >
                    {t("CREAR EMPLEADO")}
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={4000}
              handleClose={handleSnkClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </form>
        )}
      </Formik>
    </>
  );
};

export default EmployeeForm;
