import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import TableHeader from "../table/TableHeader";
import TableRow from "../table/TableRow";
import TableTotal from "../table/TableTotal";

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
  },
});

const Table = ({ invoice }) => (
  <View style={styles.tableContainer}>
    <TableHeader />
    <TableRow items={invoice.items} />
    <TableTotal invoice={invoice} />
  </View>
);

export default Table;
