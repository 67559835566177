import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
  IconButton,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Header from "../../../components/header/Header";
import { Formik } from "formik";
import * as yup from "yup";
import { getSunatRuc } from "../../../api/sunat/ruc";
import { getSunatDni } from "../../../api/sunat/dni";
import SnackBar from "../../../components/snackbar/SnackBar";
import { initial, rules } from "./Values";
import { createdEnterpriseAndEmployee } from "../../../api/employee/employee";
import { countries } from "../../../helpers/country/country";
import TopMenu from "../../../components/topbar/TopMenu";

const steps = ["REGISTRO DE EMPRESA", "REGISTRO DE ADMINISTRADOR"];

export default function RegisterStepper() {
  const theme = createTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [phoneEnt, setPhoneEnt] = useState("");
  const [phoneEmp, setPhoneEnp] = useState("");
  const [codeCountry, setCodeCountry] = useState(countries[172].phone);
  const [ccEmp, setCCEmp] = useState(countries[172].phone);

  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [messageSnk, setMessageSnk] = useState("");
  const [stateSnk, setStateSnk] = useState(false);
  const [severity, setSeverity] = useState("success");
  const vertical = "top";
  const horizontal = "center";

  const handleClose = () => setStateSnk(false);

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
    );
  };

  function validarCadena(cadena) {
    const regex = /\d{11}/; // Busca 11 dígitos consecutivos
    return regex.test(cadena); // Retorna true si tiene 11 dígitos consecutivos
  }

  const validateDataEnterprise = (values) => {
    console.log(values);
    if (values.business_ruc === "")
      return snackBarDialog("INGRESE RUC", "error");

    if (values.commertial_name === "")
      return snackBarDialog("INGRESE EL NOMBRE COMERCIAL", "error");

    if (validarCadena(values.commertial_name)) {
      return snackBarDialog("EL RUC NO VA EN EL NOMBRE COMERCIAL", "error");
    }

    if (values.business_name === "")
      return snackBarDialog("INGRESE RAZON SOCIAL", "error");

    if (values.business_address === "")
      return snackBarDialog("INGRESE DIRECCION", "error");

    if (!validateEmail(values.business_email))
      return snackBarDialog("INGRESE EMAIL VALIDO", "error");

    if (phoneEnt.length < 1) return snackBarDialog("INGRESE TELEFONO", "error");

    if (values.business_frequency === "")
      return snackBarDialog("INGRESE FRECUENCIA DE SERVICIOS", "error");

    return true;
  };

  const validateDataEmployee = (values) => {
    if (values.employee_dni === "")
      return snackBarDialog("INGRESE DNI", "error");

    if (values.employee_name === "")
      return snackBarDialog("INGRESE SU NOMBRE", "error");

    if (values.employee_lastName === "")
      return snackBarDialog("INGRESE APELLIDO", "error");

    if (values.employee_password === "")
      return snackBarDialog("INGRESE SU PASSWORD", "error");

    if (phoneEmp.length < 1)
      return snackBarDialog("INGRESE SU TELEFONO", "error");

    return true;
  };

  const handleFirstNext = (values) => {
    if (validateDataEnterprise(values)) {
      let newSkipped = skipped;
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  // const handleNext = () => {
  //   let newSkipped = skipped;
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const checkoutSchema = yup.object().shape(rules(yup));
  const initialValues = initial;

  const validatePhone = () => {
    if (phoneEmp === "")
      return snackBarDialog(
        "Ingrese el numero telefonico de Administrador",
        "error"
      );
    if (phoneEnt === "")
      return snackBarDialog("Ingrese el numero telefonico de Empresa", "error");

    return true;
  };

  const handleFormSubmit = async (values) => {
    try {
      const validate = validatePhone();
      if (validateDataEmployee(values)) {
        if (validate) {
          setIsLoading(true);
          const enterprise = {
            commertial: values.commertial_name.toUpperCase(),
            business_name: values.business_name.toUpperCase(),
            ruc: values.business_ruc.toString().trim(),
            address: values.business_address.toUpperCase(),
            email: values.business_email,
            phone: `${codeCountry} ${phoneEnt}`,
            category: values.business_category,
            category_other: values.business_category_other.toUpperCase(),
            budget: values.business_budget,
            frequency: values.business_frequency.toUpperCase(),
          };
          const employee = {
            dni: values.employee_dni,
            name: values.employee_name.toUpperCase(),
            lastname: values.employee_lastName.toUpperCase(),
            email: values.business_email,
            password: values.employee_password,
            phone: `${ccEmp} ${phoneEmp}`,
            genres_id: values.employee_genre,
            company_id: 1,
            role: 4,
            sub_role: 1,
          };
          const response = await createdEnterpriseAndEmployee(
            enterprise,
            employee
          );

          setIsLoading(false);
          snackBarDialog("Registro Exitoso, revise su email para validar");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      }
    } catch (err) {
      console.log(err);
      const errMsg = err.response.data.error;
      snackBarDialog(listErrors[errMsg], "error");
      setIsLoading(false);
    }
  };

  const listErrors = {
    EMAIL_IS_ALREADY_REGISTERED: "EL EMAIL YA ESTA REGISTRADO",
    FAILED_REGISTER_EMPLOYEE: "ERROR AL CREAR AL EMPLEADO",
    RUC_IS_ALREADY_REGISTERED: "EL RUC YA ESTA REGISTRADO",
    DNI_IS_ALREADY_REGISTERED: "DNI YA ESTA REGISTRADO",
    INTERNAL_SERVER_ERROR: "ERROR DEL SISTEMA. INFORMAR AL ADMINISTRADOR",
  };

  const handleMouseDownPassword = (event) => event.preventDefault();

  const searchRUC = async (values) => {
    if (values.business_ruc.length === 11) {
      try {
        const res = await getSunatRuc(values.business_ruc);
        console.log(res);
        values.business_name = res?.data?.nombre;
        values.business_address = `${res?.data?.direccion} ${res.data?.distrito} ${res.data?.provincia} ${res.data?.departamento}`;
        snackBarDialog("Busqueda Exitosa");
      } catch (error) {
        snackBarDialog("No se encontro empresa con este RUC", "error");
        console.log(error);
      }
    } else {
      snackBarDialog("RUC de 11 digitos", "error");
    }
  };

  const searchDNI = async (values) => {
    if (values.employee_dni.length === 8) {
      try {
        const res = await getSunatDni(values.employee_dni);
        values.employee_name = res?.data?.nombres;
        values.employee_lastName = `${res?.data?.apellidoPaterno} ${res.data?.apellidoMaterno}`;
        snackBarDialog("Usuario Encontrado");
      } catch (error) {
        snackBarDialog("DNI no encontrado", "error");
      }
    } else {
      snackBarDialog("DNI de 8 digitos", "error");
    }
  };

  const snackBarDialog = (message, severity = "success") => {
    setMessageSnk(message);
    setSeverity(severity);
    setStateSnk(true);
  };

  const formatPhoneNumber = (input) => {
    // const phoneNumber = input.replace(/\s+|[a-zA-Z]+/g, "");
    const phoneNumber = input.replace(/[^\d]+/g, "");
    let formattedPhoneNumber = "";

    for (let i = 0; i < phoneNumber.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedPhoneNumber += " ";
      }
      formattedPhoneNumber += phoneNumber[i];
    }

    return formattedPhoneNumber;
  };

  return (
    <>
      <TopMenu />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box m="10px">
            <Header title="REGISTRO" subtitle="" />
            <Box pb={2} sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Box gap="30px" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                          <Grid container spacing={1}>
                            {activeStep === 0 ? (
                              <>
                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-ruc">
                                      RUC
                                    </InputLabel>
                                    <OutlinedInput
                                      value={values.business_ruc}
                                      name="business_ruc"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      id="control-ruc"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                            onClick={(e) => {
                                              searchRUC(values);
                                            }}
                                          >
                                            <SendIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="RUC"
                                    />
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    value={values.commertial_name}
                                    fullWidth
                                    type="text"
                                    label={t("NOMBRE COMERCIAL")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="commertial_name"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      !!touched.commertial_name &&
                                      !!errors.commertial_name
                                    }
                                    helperText={
                                      touched.commertial_name &&
                                      errors.commertial_name
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                  <TextField
                                    value={values.business_name}
                                    fullWidth
                                    type="text"
                                    label={t("RAZON SOCIAL")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="business_name"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      !!touched.business_name &&
                                      !!errors.business_name
                                    }
                                    helperText={
                                      touched.business_name &&
                                      errors.business_name
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    value={values.business_address}
                                    fullWidth
                                    type="text"
                                    label={t("address").toUpperCase()}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="business_address"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      !!touched.business_address &&
                                      !!errors.business_address
                                    }
                                    helperText={
                                      touched.business_address &&
                                      errors.business_address
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12}>
                                  <TextField
                                    value={values.business_email}
                                    fullWidth
                                    type="text"
                                    label={"email".toUpperCase()}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="business_email"
                                    error={
                                      !!touched.business_email &&
                                      !!errors.business_email
                                    }
                                    helperText={
                                      touched.business_email &&
                                      errors.business_email
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                  <Autocomplete
                                    id="country-phone"
                                    options={countries}
                                    defaultValue={countries[172]}
                                    autoHighlight
                                    getOptionLabel={(opt) =>
                                      `(${opt.phone}) ${opt.label}`
                                    }
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        const code = newValue.phone;
                                        setCodeCountry(code);
                                      }
                                    }}
                                    renderOption={(props, opt) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        <img
                                          loading="lazy"
                                          width="20"
                                          src={`https://flagcdn.com/w20/${opt.code.toLowerCase()}.png`}
                                          srcSet={`https://flagcdn.com/w40/${opt.code.toLowerCase()}.png 2x`}
                                          alt=""
                                        />
                                        {opt.label} ({opt.code}) {opt.phone}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                  <TextField
                                    value={phoneEnt}
                                    fullWidth
                                    type="tel"
                                    label={t("phone").toUpperCase()}
                                    onChange={(event) => {
                                      const { value } = event.target;
                                      setPhoneEnt(formatPhoneNumber(value));
                                    }}
                                    name="phoneEnt"
                                    inputProps={{ maxLength: 18 }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              color: "black",
                                            }}
                                          >
                                            {codeCountry}
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                  <FormControl fullWidth>
                                    <InputLabel>{t("RUBRO")}</InputLabel>
                                    <Select
                                      id="business_category"
                                      value={values.business_category}
                                      name="business_category"
                                      label={t("RUBRO")}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={1}>BANCA</MenuItem>
                                      <MenuItem value={2}>COMERCIO</MenuItem>
                                      <MenuItem value={3}>CONTRUCCION</MenuItem>
                                      <MenuItem value={4}>EDUCACION</MenuItem>
                                      <MenuItem value={5}>
                                        HIDROCARBUROS
                                      </MenuItem>
                                      <MenuItem value={6}>HOTELES</MenuItem>
                                      <MenuItem value={7}>MANUFACTURA</MenuItem>
                                      <MenuItem value={8}>MINERIA</MenuItem>
                                      <MenuItem value={9}>PESCA</MenuItem>
                                      <MenuItem value={10}>OTROS</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>

                                {values.business_category === 10 ? (
                                  <Grid item xs={12} sm={6}>
                                    <TextField
                                      value={values.business_category_other}
                                      fullWidth
                                      type="text"
                                      label={t("INGRESE SU RUBRO")}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      name="business_category_other"
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}

                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth>
                                    <InputLabel>
                                      {"PRESUPUESTO MENSUAL"}
                                    </InputLabel>
                                    <Select
                                      id="business_budget"
                                      value={values.business_budget}
                                      name="business_budget"
                                      label={t("PRESUPUESTO MENSUAL")}
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={1}>1000-5000</MenuItem>
                                      <MenuItem value={2}>5000-10000</MenuItem>
                                      <MenuItem value={3}>10000-15000</MenuItem>
                                      <MenuItem value={4}>15000-mas</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    value={values.business_frequency}
                                    fullWidth
                                    type="text"
                                    label={t("FRECUENCIA DE SERVICIOS")}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="business_frequency"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      !!touched.business_frequency &&
                                      !!errors.business_frequency
                                    }
                                    helperText={
                                      touched.business_frequency &&
                                      errors.business_frequency
                                    }
                                  />
                                </Grid>

                                <Grid item xs={6} sm={6}></Grid>
                                <Grid item xs={6} sm={6} textAlign={"end"}>
                                  <Button
                                    variant="contained"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleFirstNext(values);
                                    }}
                                  >
                                    Next
                                  </Button>
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} sm={12}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-dni">
                                      DNI
                                    </InputLabel>
                                    <OutlinedInput
                                      value={values.employee_dni}
                                      name="employee_dni"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      id="control-dni"
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                            onClick={(e) => {
                                              searchDNI(values);
                                            }}
                                          >
                                            <SendIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="DNI"
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    value={values.employee_name}
                                    fullWidth
                                    type="text"
                                    label={t("nombre").toUpperCase()}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="employee_name"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      !!touched.employee_name &&
                                      !!errors.employee_name
                                    }
                                    helperText={
                                      touched.employee_name &&
                                      errors.employee_name
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    value={values.employee_lastName}
                                    fullWidth
                                    type="text"
                                    label={t("apellidos").toUpperCase()}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="employee_lastName"
                                    inputProps={{
                                      style: { textTransform: "uppercase" },
                                    }}
                                    error={
                                      !!touched.employee_lastName &&
                                      !!errors.employee_lastName
                                    }
                                    helperText={
                                      touched.employee_lastName &&
                                      errors.employee_lastName
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <TextField
                                    value={values.business_email}
                                    fullWidth
                                    type="email"
                                    label={t("email").toUpperCase()}
                                    name="business_email"
                                    onChange={values.business_email}
                                    disabled
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel>PASSWORD</InputLabel>
                                    <OutlinedInput
                                      id="outlined-adornment-password"
                                      type={showPassword ? "text" : "password"}
                                      value={values.employee_password}
                                      name="employee_password"
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={
                                              handleMouseDownPassword
                                            }
                                            edge="end"
                                          >
                                            {showPassword ? (
                                              <VisibilityOff />
                                            ) : (
                                              <Visibility />
                                            )}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                      label="PASSWORD"
                                      error={
                                        !!touched.employee_password &&
                                        !!errors.employee_password
                                      }
                                    />
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                  <Autocomplete
                                    id="country-phone-employee"
                                    options={countries}
                                    defaultValue={countries[172]}
                                    autoHighlight
                                    getOptionLabel={(option) =>
                                      `(${option.phone}) ${option.label}`
                                    }
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        const code = newValue.phone;
                                        setCCEmp(code);
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        <img
                                          loading="lazy"
                                          width="20"
                                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                          alt=""
                                        />
                                        {option.label} ({option.code}){" "}
                                        {option.phone}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                  <TextField
                                    value={phoneEmp}
                                    fullWidth
                                    type="tel"
                                    label={t("telefono/whatsapp").toUpperCase()}
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      setPhoneEnp(formatPhoneNumber(value));
                                    }}
                                    name="phoneEmp"
                                    inputProps={{ maxLength: 18 }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <span
                                            style={{
                                              fontWeight: "bold",
                                              color: "black",
                                            }}
                                          >
                                            {ccEmp}
                                          </span>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Button
                                    color="inherit"
                                    // disabled={activeStep === 0}
                                    onClick={handleBack}
                                    variant="contained"
                                    sx={{ mr: 1 }}
                                  >
                                    Back
                                  </Button>
                                </Grid>
                                <Grid item xs={6} sm={6} textAlign={"end"}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={isLoading}
                                  >
                                    Register
                                  </Button>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      </form>
                    )}
                  </Formik>
                </React.Fragment>
              )}
            </Box>
            <SnackBar
              stateSnk={stateSnk}
              vertical={vertical}
              horizontal={horizontal}
              duration={5000}
              handleClose={handleClose}
              severity={severity}
              messageSnk={messageSnk}
            />
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
