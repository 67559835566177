import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, CssBaseline } from "@mui/material";

import Topbar from "../../../components/topbar/TopBar";
import Sidebar from "../../../components/sidebar/AdminSidebar";
import Header from "../../../components/header/Header";
import FormBooking from "./form/FormBooking";

const AddBooking = () => {
  const { t } = useTranslation();

  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div>
      <CssBaseline />
      <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
          <Topbar setIsSidebar={setIsSidebar} />
          <Box m="20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Header title={t("asignBookingDriver")} subtitle="" />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              gridAutoRows="120px"
              gap="10px"
            >
              <Box gridColumn="span 12" gridRow="span 2">
                <FormBooking />
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
};

export default AddBooking;
